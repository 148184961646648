import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Carousel, { consts } from "react-elastic-carousel";
import { SERVER_URL } from "api/ApiConstants";
import { useHistory } from "react-router-dom";
import { server } from "variables/general";

const TopDestinations = (props) => {
  const history = useHistory();

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  function HotelDetail(name, packageType, index, advertise, packageId) {
    if (advertise === true) {
      history.push("/packages/packageinfo", { data: packageId });
    } else {
      history.push({
        pathname: "/packages/",
        state: { name, packageType, index },
      });
    }
    // setSecLogo(true);
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  }

  const { height, width } = useWindowDimensions();

  const PackageDestination = ({ pack }) => {
    const [hoverTopDestination, setHoverTopDestination] = useState(false);
    const [hoverTopDestinationTitle, setHoverTopDestinationTitle] = useState(
      false
    );

    return (
      <div
        className="carousel"
        onClick={() =>
          HotelDetail(
            pack.packageCategoryId,
            pack.packageCategoryType,
            1,
            true,
            pack.packageId
          )
        }
      >
        <div className="carousel-subContainer">
          <img
            //  src={topDestinationPlace}
            src={
              SERVER_URL +
              // "http://oari.co:8080/" +
              "packageadmin/ImageReaderArticle?path=/" +
              pack?.packageCategoryType +
              "/" +
              pack?.packageCategoryId +
              "/" +
              pack?.packageId +
              "/" +
              pack?.packageCategoryImage
            }
            className="carouselImage"
          />
          <div className="carouselDescription">
            <div
              onMouseEnter={() => setHoverTopDestinationTitle(true)}
              onMouseLeave={() => setHoverTopDestinationTitle(false)}
              className="carouselHeader"
            >
              {pack.packageCategoryName && pack.packageCategoryName.slice(0, 18)}
              {pack.packageCategoryName && pack.packageCategoryName.length > 18 && "..."}
            </div>
            {hoverTopDestinationTitle && (
              <div className="packageDestinationHoverTitle">
                {pack?.packageCategoryName && pack.packageCategoryName.replaceAll("&#45;", ", ")}
              </div>
            )}

            <div
              onMouseEnter={() => setHoverTopDestination(true)}
              onMouseLeave={() => setHoverTopDestination(false)}
              className="packageDestinationContainer"
            >
              <div className="carouselSubHeader">
                {pack.packageDestinations && pack.packageDestinations.replaceAll("&#45;", ", ")}{" "}
                {pack.packageDestinations && pack.packageDestinations.length > 50 && "..."}
              </div>
              {hoverTopDestination && (
                <div className="packageDestinationHover">
                  {pack.packageDestinations && pack.packageDestinations.replaceAll("&#45;", ", ")}
                </div>
              )}
            </div>

            <div className="priceContainer">
              <span className="priceText">
                ₹ {pack.packageCategoryStartingPrice && pack.packageCategoryStartingPrice.toLocaleString("en-IN")}
              </span>{" "}
              per person
            </div>
          </div>
        </div>
      </div>
    );
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className="topHeader">
        We Provide Top{" "}
        <span className="hightlight">Destination Especially</span> For you
      </div>
      <div className="carouselHeaderText">
        Some of the top travel destinations are historic favorites; others are
        genuinely surprising. All are world-class and beloved by tourists.
      </div>
      <div className="carouselContainer">
        <Carousel
          itemsToShow={width > 1350 ? 3 : width < 650 ? 1 : 1}
          // itemsToShow={3}
          itemPadding={[10, 10, 10, 10]}
          // pagination={false}

          // renderArrow={CustomArrow}
        >
          {props?.data?.packagesCategories?.map((pack) => (
            <PackageDestination pack={pack} />
          ))}
        </Carousel>
      </div>
      <div className="exploreMoreContainer">
        <div
          onClick={() => {
            history.push({
              pathname: "/packages/",
              // state: { name, packageType, index },
            });
          }}
          className="exploreMore"
        >
          Explore More
        </div>
      </div>
    </div>
  );
};

export default TopDestinations;

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 50,
    [theme.breakpoints.down(960)]: {
      padding: "0px 20px",
      paddingBottom: 15,
    },
    [theme.breakpoints.down(750)]: {
      padding: "0px 20px",
      paddingBottom: 15,
    },
    [theme.breakpoints.down(500)]: {
      padding: "0px 5px",
      paddingBottom: 15,
    },
    "& .rec-dot": {
      backgroundColor: "#ff0000",
      boxShadow: "none",
      opacity: "30%",
    },
    "& .exploreMore": {
      textAlign: "center",
      background: `linear-gradient(90.13deg, #9B1111 0.09%, #FF5959 99.86%)`,
      width: 150,
      height: 40,
      borderRadius: 10,
      color: "#fff",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      fontSize: 17,
      fontWeight: 500,
      fontFamily: "TimesNewRoman",
    },
    "& .rec-dot_active": {
      boxShadow: "0 0 1px 3px #ff0000",
      opacity: "100%",
    },
    "& .rec-arrow": {
      display: "none",
    },
    margin: "0 auto",
    // height: 430,
    backgroundColor: "#fff",

    width: "1350px",
    borderRadius: 15,
    display: "flex",
    flexDirection: "column",
    padding: 50,
    [theme.breakpoints.down(1350)]: {
      width: "90%",
    },
    "& .priceText": {
      color: "#ff0000",
      fontSize: 20,
      fontWeight: 700,
      marginRight: 5,
    },
    // alignItems: "center",
    "& .carousel": {
      // width: "26%",
      display: "flex",
      justifyContent: "center",
      position: "relative",
      cursor: "pointer",
      "& .carousel-subContainer": {
        [theme.breakpoints.down(1150)]: {
          width: 270,
        },
        [theme.breakpoints.down(1050)]: {
          width: 250,
        },
      },
    },
    "& .carouselContainer": {
      marginTop: 30,
      display: "flex",
      justifyContent: "space-between",
      //   maxWidth: 180,
      //   width: "90%",
      [theme.breakpoints.down(1150)]: {
        // width: "90%",
      },
      marginBottom: 20,
    },
    "& .carouselDescription": {
      backgroundColor: "#E9E9E9",
      padding: 20,
      borderBottomRightRadius: 10,
      borderBottomLeftRadius: 10,
    },
    "& .carouselImage": {
      height: 320,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      borderRadius: 10,
      [theme.breakpoints.down(1150)]: {
        height: 220,
      },
      //   width: 330,
    },
    "& .carouselHeaderText": {
      //   marginTop: 20,
      paddingLeft: 28,
      [theme.breakpoints.down(500)]: {
        textAlign: "center",
      },
    },
    "& .carouselHeader": {
      fontSize: 20,
      fontWeight: 700,
      textTransform: "uppercase",
      [theme.breakpoints.down(1150)]: {
        fontSize: 19,
      },
      [theme.breakpoints.down(1050)]: {
        fontSize: 16,
      },

      //   fontFamily: "TimesNewRoman",
      //   marginTop: 10,
      // marginBottom: 30,
    },
    "& .packageDestinationHoverTitle": {
      position: "absolute",
      backgroundColor: theme.palette.primary.lightText,
      fontSize: 12,
      padding: 6,
      borderRadius: 5,
      border: `1px solid ${theme.palette.primary.contrastText}`,
      zIndex: 1000,
      bottom: 30,
      left: 25,
      // boxShadow: "0 0 1px 3px #ff0000",

      // height: "100%",
    },
    "& .carouselSubHeader": {
      //   fontSize: 20,
      // width: "80%",
      fontWeight: 500,
      textTransform: "lowercase",
      cursor: "pointer",
      overflow: "hidden",
      height: 40,
      width: 260,
      //   fontFamily: "TimesNewRoman",
      //   marginTop: 10,
      // marginBottom: 30,
    },
    "& .exploreMoreContainer": {
      margin: "0 auto",
    },
    "& .priceContainer": {
      marginTop: 10,
      display: "flex",
      alignItem: "center",
    },
    "& .topHeader": {
      fontSize: 45,
      fontWeight: 700,
      fontFamily: "TimesNewRoman",
      marginBottom: 20,
      paddingLeft: 20,
      [theme.breakpoints.down(1100)]: {
        fontSize: 40,
        lineHeight: 0.8,
      },
      [theme.breakpoints.down(960)]: {
        paddingTop: 20,
      },
      [theme.breakpoints.down(500)]: {
        fontSize: 30,
        textAlign: "center",
      },
    },
    "& .hightlight": {
      color: "#FF0000",
      marginRight: 10,
    },
    "& .packageDestinationContainer": {
      "& .packageDestinationHover": {
        position: "absolute",
        backgroundColor: theme.palette.primary.lightText,
        fontSize: 12,
        padding: 6,
        borderRadius: 5,
        border: `1px solid ${theme.palette.primary.contrastText}`,
        zIndex: 1000,
        bottom: 20,
        left: 25,
        // boxShadow: "0 0 1px 3px #ff0000",

        // height: "100%",
      },
    },
  },
}));
