import React from "react";
import {
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { AppConstant } from "appConstant";
import { useHistory } from "react-router-dom";

const PrivacyPolicy = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const history = useHistory();

  const goToHomePage = () => {
    history.push("/");
  };

  return (
    <div
      className={classes.root}
      style={{
        marginBottom: 60,
        paddingLeft: isMobile ? 10 : "6%",
        paddingRight: isMobile && 10,
      }}
    >
      <Grid
        container
        spacing={isMobile ? 0 : 3}
        style={{ width: "100%" }}
        justifyContent="center"
      >
        {/* <Grid item xs={12} md={10} style={{ paddingTop: "50px" }}>
                    <a onClick={goToHomePage} className={classes.breadcrumbLink}>
                        <i className="fa fa-home"></i> Home
                    </a>
                </Grid> */}

        <Grid item xs={12} md={10}>
          <Grid
            container
            spacing={1}
            className={`${classes.firstCard} ${classes.Grid}`}
          >
            <Grid item md={12}>
              <Typography
                variant="h3"
                className={classes.sectionHeader}
                gutterBottom
              >
                PRIVACY POLICY:
              </Typography>
              <hr style={{ width: "100%" }} />
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                TripBouquet Privacy Policy
              </Typography>

              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                TripBouquet respects every individual’s privacy and therefore we
                maintain the confidentiality of all customers’ information in
                our user interface. We have the best data controller and data
                processor that helps in securing user(s) personal information.
              </Typography>
              <Gap10 />
              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                The privacy policy is not only applicable for users who purchase
                from our website, but it is also for those who enquire about any
                products/services available at TripBouquet. Whether a user is
                exploring the TripBouquet website, mobile site, or offline
                channels like call centers and offices, we ensure that all
                relevant information about our user is handled securely.
              </Typography>
              <Gap10 />
              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                Our privacy policy doesn’t apply to third-party websites, mobile
                sites, and apps. Even if the third-party website is linked to
                our website we are not responsible for them. User(s) should note
                that any information of TripBouquet which is shared on business
                partners, sponsors, other sites, or advertisers with hyperlinks,
                has different policies and has got nothing to do with our
                privacy policy.
              </Typography>
              <Gap10 />
              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                The privacy policy of TripBouquet works for only those user(s)
                who are using our website for any reason. The privacy policy and
                User Agreement is between the user and TripBouquet directly.
              </Typography>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                USERS OUTSIDE THE GEOGRAPHICAL LIMITS OF INDIA
              </Typography>

              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                Any data shared with TripBouquet will be majorly processed in
                India. For any other jurisdiction, where a third party is
                involved with TripBouquet, the processing of data would be done
                on TripBouquet’s behalf by the third party. The data protection
                regulation in India is different from other jurisdictions and
                therefore user(s) residing in another country may have different
                data protection. Agreeing to this policy you are giving
                TripBouquet consent to use and process your personal information
                for the motive explained in the policy.
              </Typography>

              <Gap10 />

              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                If you have concerns or disagree with the data processing system
                then you may write to us at the email ID
                support@tripbouquet.com. On the contrary, we may not be able to
                confirm your booking once we receive your withdrawal of consent,
                if we need the above-mentioned data processing system to provide
                service to you.
              </Typography>

              <Gap10 />

              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                For example, before booking an international holiday package we
                may need contact details, dietary preferences, choice of room,
                medical condition details, etc. to serve you better. This
                information is provided to the hotel and Tour manager for better
                service and guidance during travel. Upon signing withdrawal of
                consent by the user(s) we will be unable to serve the user(s)
                properly because of incomplete information and therefore we may
                not be able to continue booking further.
              </Typography>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                TYPES OF INFORMATION COLLECTED BY TRIPBOUQUET AND ITS LEGALITY
              </Typography>

              <ul className={classes.listText}>
                <li>
                  We gather relevant information which will include the personal
                  information of users also so that we can provide better
                  services chosen by the user(s). We also have certain legal
                  obligations towards third parties that we have to fulfill
                  based on our user agreement.
                </li>
                <li>
                  Personal information is provided by the user(s) while
                  subscribing or registering on the website. This includes name,
                  marital status, gender, age, religion, nationality, contact
                  details, email address, postal address, etc. We also request
                  banking details, card information, as the user moves ahead on
                  the website
                </li>
                <li>
                  We also keep information like banking information, payment
                  history, and other information related to your lifestyle, trip
                  plans, search history, and bookings.
                </li>
                <li>
                  Transactional histories about buying behavior and e-commerce
                  activities but not banking details related to third-party
                  websites linked with us.
                </li>
                <li>
                  Data created by you or a third party which is stored on our
                  server in the form of images, files, and documents,
                </li>
                <li>
                  Data received from social media channels that are linked to
                  our websites like name, friend’s list, profile picture, and
                  any other information. (This information is stored only when
                  you permit us to receive data that can be changed in account
                  settings.)
                </li>
                <li>
                  Information related to other travelers for whom you have made
                  a booking through your registered TripBouquet account. Before
                  keeping the information we confirm from the user to agree with
                  our user agreement to share and disclose other traveler’s
                  information with us and other concerned service providers.
                </li>
                <li>
                  If you request visa-related services through TripBouquet, we
                  will also require relevant documents like passport, bank
                  statements, visa application form, photographs, and other
                  relevant information to process your visa application in the
                  respective Embassy.
                </li>
                <li>
                  According to the Liberalized Remittance Scheme (LRS) of RBI,
                  users booking international reservations are required to
                  provide details like PAN information, passport details, Aadhar
                  card details, etc. In case the user doesn't want to provide
                  such information then if you request visa-related services
                  through TripBouquet will not be able to process the booking.{" "}
                </li>
                <li>
                  If you want contactless check-in hotels then TripBouquet would
                  need soft copies of government ID proofs like driving license,
                  Aadhar card, voter ID card, self-declaration form, date of
                  birth, etc. This information will be forwarded to the
                  concerned hotel for reservation.
                </li>
                <li>
                  Recently, we have also added the COVID-19 vaccination status
                  and certificate-related information which will be required
                  during the latter stage of traveling. However, TripBouquet
                  will not process the beneficiary ID and other details provided
                  in the vaccination certificate.
                </li>
              </ul>
              <Gap10 />
              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                Before processing any information, the user should know that
                TripBouquet will be sharing some of the relevant information
                with the end service provider or any other third party for easy
                and seamless booking. However, TripBouquet will keep sensitive
                and confidential information secure.
              </Typography>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                HOW TRIPBOUQUET UTILIZES THE USER(S) PERSONAL INFORMATION
              </Typography>

              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                While the booking process, users provide their details. We may
                use personal information which will include payment details,
                bank details, and other relevant details to confirm that
                booking.
              </Typography>
              <Gap10 />
              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                We also use personal information for –
              </Typography>
              <Gap10 />
              <ul className={classes.listText}>
                <li>Making reservations.</li>
                <li>Keeping you informed about the transaction status.</li>
                <li>Sending booking confirmation via WhatsApp or SMS. </li>
                <li>Sending updates or changes related to the booking.</li>
                <li>Send verification messages and emails.</li>
                <li>Contact you on your birthday/anniversary.</li>
                <li>
                  Request you to participate in the surveys which will be sent
                  through emails, WhatsApp, SMS, and other social channels.
                </li>
              </ul>
              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                We may also use your personal information to notify you about
                various marketing promotions, research, and programs that will
                help us develop and improve our system. Through user(s) personal
                information, we can notify contest winners and as a registered
                user, you will be frequently receiving updates from us related
                to fair price drop or raise in your area, special offers, new
                TripBouquet services, and other travel benefits.
              </Typography>
              <Gap10 />
              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                Please see our Cookie Policy for more information
              </Typography>
            </Grid>
            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                COOKIES AND SESSION DATA
              </Typography>

              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                Cookies are a little piece of information that is stored on your
                device’s hard drive by your browser which helps other websites
                to serve you better and efficiently. Cookies let you work on any
                website easily. For example, if you have logged into a website
                and canceled the website without logging out, the next time when
                you enter the website it automatically takes you to the
                previously logged-in account.
              </Typography>
              <Gap10 />
              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                TripBouquet uses cookies to give you a personal and customized
                experience on the website and also provides relevant
                advertisements that may be useful to you. However, we respect
                your privacy and support your right to block any unwanted
                Internet activity by going to the account settings and changing
                your preferences. Still, TripBouquet would like to tell you, if
                you block TripBouquet cookies, it may disable certain relevant
                features on the website which can block your seamless experience
                on our website.
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                SESSION DATA
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                Anytime when the user logs in, we track all data like IP
                address, type of browser software used, operating system, and
                relevant activities conducted by the user while exploring the
                website. This information helps us in understanding user(s)
                choices and browsing patterns to provide them better options in
                the future.
              </Typography>
              <Gap10 />
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                WHERE IS YOUR PERSONAL INFORMATION SHARED?
              </Typography>

              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                Your information is shared with all the service providers like
                airlines, hotels, bus service providers, Railways, cab rentals,
                and other services who are included in your service booking.
                However, TripBouquet does not authorize other service providers
                to use your information for any other purpose as long as it is
                not related to their service. TripBouquet does not sell or rent
                user(s) information or customers’ names with third parties that
                are not connected with our business at all.
              </Typography>
              <ul className={classes.listText}>
                <li>
                  TripBouquet may also use your personal information with
                  affiliate entities to provide information about relevant
                  products and services which are travel-related.
                </li>
                <li>
                  We may also share some of the relevant personal information
                  with business partners and third-party vendors who can provide
                  products or services to make the user(s) travel experience
                  better.
                </li>
                <li>
                  If you use services of any of our business partners or
                  third-party vendors, the user will then follow their privacy
                  policy and not TripBouquet
                </li>
              </ul>
              <Gap10 />
              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                We may also disclose users personal information if it is
                required –
              </Typography>
              <ul className={classes.listText}>
                <li>
                  By law for investigation by Court order or any legal process
                </li>
                <li>For internal compliance and Audit.</li>
                <li>To secure our system.</li>
                <li>
                  To protect our rights of TripBouquet and affiliate businesses
                  when we feel that some other third-party business is
                  interfering with our rights and properties and disclosing your
                  information is necessary to identify these harmful activities
                  to bring legal actions.
                </li>
              </ul>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                YOUR RIGHTS
              </Typography>

              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                You can access your personal information on your user's account
                settings and make relevant changes if required. For your
                convenience, we have also provided the facility to delete such
                information if you feel unsafe. However, mandatory information
                cannot be deleted so you will have to delete the complete
                account.
              </Typography>

              <Gap10 />
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                ELIGIBILITY CRITERIA TO USE TRIPBOUQUET
              </Typography>

              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                Since there is various personal information being shared
                including payment details, email address, and other relevant
                information therefore anybody who is accessing TripBouquet
                should be above 18 years of age.
              </Typography>
            </Grid>

            <Grid item md={12} className={classes.sectionTextGrid}>
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                CHANGES TO PRIVACY POLICY
              </Typography>

              <Gap10 />

              <Typography
                variant="body2"
                gutterBottom
                className={classes.sectionText}
              >
                We follow the regulations provided in the privacy policy section
                diligently. However, these legal procedures and requirements may
                change from time to time. If there are any changes in the
                privacy policy we will notify the users through SMS, WhatsApp,
                and email or by our customer service. For any queries, you can
                contact us at support@tripbouquet.com or call ask at
                +91-7303193848, 0124-4111584.
              </Typography>
            </Grid>

            {/* <Grid
                            item
                            md={12}
                            className={classes.sectionTextGrid}
                            style={{ textAlign: "center" }}
                        >
                            <a onClick={goToHomePage} className={classes.breadcrumbLink}>
                                <i className="fa fa-home"></i> Home
                            </a>
                        </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default PrivacyPolicy;

const Gap10 = () => {
  return <div className="gap-10" style={{ margin: 10, width: "100%" }}></div>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& .highlight": {
      color: theme.palette.primary.darkOrange,
    },
  },
  Grid: {
    background: theme.palette.background.default,
    marginTop: "20px",
    boxShadow: "0px 53px 99px -81px rgba(0,0,0,0.75)",
    padding: 30,
    borderRadius: 20,
  },
  sectionHeader: {
    color: `${theme.palette.primary.darkFadedText} !important`,
    fontWeight: 500,
    margin: "5px 0 30px",
  },
  sectionSubhead: {
    color: `${theme.palette.primary.defaultText} !important`,
    fontWeight: 400,
  },
  sectionText: {
    color: `${theme.palette.primary.davyGray} !important`,
    fontWeight: 400,
    lineHeight: "27px",
  },
  sectionTextGrid: {
    margin: "15px 0",
    flexDirection: "column",
  },
  listText: {
    "& li": {
      color: theme.palette.primary.davyGray,
      fontWeight: 400,
      lineHeight: "27px",
    },
  },
  sectionTextGrid: {
    margin: "15px 0",
    flexDirection: "column",
  },
  listText: {
    "& li": {
      color: theme.palette.primary.darkFadedText,
      fontWeight: 400,
      lineHeight: "27px",
    },
  },
  breadcrumbLink: {
    color: `${theme.palette.primary.active} !important`,
    cursor: "pointer",
    "&:hover": {
      color: `${theme.palette.primary.pomegranate} !important`,
    },
  },
}));
