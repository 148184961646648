import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import duration from "assets/TripBouquet/images/icons/duration.png";
import location from "assets/TripBouquet/images/icons/location.png";
import Carousel, { consts } from "react-elastic-carousel";
import { SERVER_URL } from "api/ApiConstants";
import { useHistory } from "react-router-dom";

const BestTourPackages = ({ data }) => {
  const classes = useStyles();
  const width = window.innerWidth;
  const history = useHistory();

  function HotelDetail(name, packageType, index, advertise, packageId) {
    if (advertise === true) {
      history.push("/packages/packageinfo", { data: packageId });
    } else {
      history.push({
        pathname: "/packages/",
        state: { name, packageType, index },
      });
    }
    // setSecLogo(true);
  }

  const CarouselContainer = ({ pack }) => {
    // console.log(pack);
    const [hoverDescription, setHoverDescription] = useState(false);
    const [hoverTitle, setHoverTitle] = useState(false);

    return (
      <div
        className="carousel"
        onClick={() =>
          HotelDetail(
            pack?.packageCategoryId,
            pack?.packageCategoryType,
            1,
            true,
            pack?.packageId
          )
        }
      >
        <div className="carousel-subContainer">
          <div className="priceContainer">
            ₹ {pack.packageCategoryStartingPrice && pack.packageCategoryStartingPrice.toLocaleString("en-IN")}
          </div>
          <img
            //  src={topDestinationPlace}
            src={
              SERVER_URL +
              // "http://oari.co:8080/" +
              "packageadmin/ImageReaderArticle?path=/" +
              pack?.packageCategoryType +
              "/" +
              pack?.packageCategoryId +
              "/" +
              pack?.packageId +
              "/" +
              pack?.packageCategoryImage
            }
            className="carouselImage"
          />
          <div className="carouselHeader">{pack?.packageCategoryName}</div>
          <div className="packageInfo">
            <div className="packageDuration">
              <img src={duration} className="packageIcon" />{" "}
              {pack.packageInfo && pack.packageInfo.slice(0, 30)}
              {/* {hoverTitle && <div>{pack.packageInfo}</div>} */}
            </div>
            <div className="packageLocation">
              <img src={location} className="packageIcon" />
              India
            </div>
          </div>
          <div
            onMouseEnter={() => setHoverDescription(true)}
            onMouseLeave={() => setHoverDescription(false)}
            className="carouselDesc"
          >
            {pack.packageDestinations && pack.packageDestinations.toLowerCase()}
          </div>
          {hoverDescription && (
            <div className="hoverDescription">{pack?.packageDestinations}</div>
          )}
          <div className="bookNowBtn">Book now</div>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div className="carouselHeaderText">Top pick</div>
      <div className="topHeader">
        Best Tour <span className="hightlight"> Packages</span>
      </div>

      <div className="carouselContainer">
        <Carousel
          itemsToShow={width > 1350 ? 3 : width < 650 ? 1 : 2}
          itemPadding={[0, 10, 0, 0]}
        // pagination={false}

        // renderArrow={CustomArrow}
        >
          {data?.packagesCategories
            ?.slice(0, 3)
            .map((pack) => <CarouselContainer pack={pack} />)}
          {/* <div className="carousel">
            <div className="carousel-subContainer">
              <div className="priceContainer">₹ 1,900</div>
              <img src={tourPlace} className="carouselImage" />
              <div className="carouselHeader">Famous Monasteries in Bhutan</div>
              <div className="packageInfo">
                <div className="packageDuration">
                  <img src={duration} className="packageIcon" /> 7D/6N
                </div>
                <div className="packageLocation">
                  <img src={location} className="packageIcon" />
                  India
                </div>
              </div>
              <div className="carouselDesc">
                Bhutan is the most serene and beautiful tourist destination. The
                Himalayas and for housing spectacular Buddhist{" "}
              </div>
              <div className="bookNowBtn">Book now</div>
            </div>
          </div> */}
        </Carousel>
      </div>
    </div>
  );
};

export default BestTourPackages;

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 50,
    marginBottom: 20,
    "& .rec-dot": {
      backgroundColor: "#ff0000",
      boxShadow: "none",
      opacity: "30%",
      display: "none",
    },
    "& .rec-dot_active": {
      boxShadow: "0 0 1px 3px #ff0000",
      opacity: "100%",
      display: "none",
    },
    "& .rec-arrow": {
      display: "none",
    },
    // height: 430,
    backgroundColor: "#e9e9e9",
    width: "100%",
    [theme.breakpoints.down(1350)]: {
      width: "90%",
    },
    [theme.breakpoints.down(950)]: {
      width: "100%",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .carousel": {
      width: "80%",
      display: "flex",
      justifyContent: "center",
      cursor: "pointer",
      maxWidth: "330px",
      [theme.breakpoints.down(1250)]: {
        width: 250,
      },
      "& .carousel-subContainer": {
        [theme.breakpoints.down(1250)]: {
          width: 250,
        },
      },
    },
    "& .carouselContainer": {
      marginTop: 30,
      display: "flex",
      justifyContent: "space-between",
      maxWidth: 1280,
      width: "100%",
      marginBottom: 40,
    },
    "& .carouselImage": {
      height: 240,
      width: 330,
      borderRadius: 10,
      [theme.breakpoints.down(1250)]: {
        width: 250,
      },
    },
    "& .carouselHeaderText": {
      marginTop: 20,
      marginBottom: 20,
      fontSize: 18,
      fontWeight: 700,
      fontFamily: "TimesNewRoman",
      textTransform: "uppercase",
    },
    "& .carouselHeader": {
      fontSize: 20,
      fontWeight: 700,
      fontFamily: "TimesNewRoman",
      marginTop: 10,
      height: 50,
      // marginBottom: 30,
    },
    "& .topHeader": {
      fontSize: 45,
      fontWeight: 700,
      fontFamily: "TimesNewRoman",
      marginBottom: 20,
      [theme.breakpoints.down(500)]: {
        fontSize: 35,
      },
    },
    "& .hightlight": {
      color: "#FF0000",
      marginRight: 10,
    },
    "& .bookNowBtn": {
      background: `linear-gradient(90.13deg, #9B1111 0.09%, #FF5959 99.86%)`,
      color: "#fff",
      fontWeight: 700,
      fontFamily: "TimesNewRoman",
      fontSize: 17,
      width: 130,
      height: 40,
      borderRadius: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: 10,
    },
    "& .carouselDesc": {
      width: "80%",
      color: "#8D8D8D",
      marginTop: 10,
      fontFamily: "TimesNewRoman",
      height: 40,
      width: 350,
      overflow: "hidden",
    },
    "& .packageInfo": {
      display: "flex",
      color: "#8D8D8D",
      fontFamily: "TimesNewRoman",
      marginTop: 15,
      "& .packageIcon": {
        height: 15,
        width: 13,
        marginRight: 10,
      },
    },
    "& .packageDuration": {
      display: "flex",
      alignItems: "center",
      marginRight: 10,
    },
    "& .packageLocation": {
      display: "flex",
      alignItems: "center",
      marginLeft: 15,
      marginRight: 10,
    },
    "& .priceContainer": {
      position: "absolute",
      right: 15,
      top: 15,
      backgroundColor: "#fff",
      fontSize: 16,
      fontWeight: 700,
      border: "1px solid #FF0000",
      padding: "5px 15px",
      borderRadius: 10,
      borderBottomLeftRadius: 0,
    },
    "& .hoverDescription": {
      position: "absolute",
      backgroundColor: theme.palette.primary.lightText,
      fontSize: 12,
      padding: 6,
      borderRadius: 5,
      border: `1px solid ${theme.palette.primary.contrastText}`,
      zIndex: 1000,
      bottom: 20,
      left: 25,
    },
  },
}));
