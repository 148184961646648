import React from "react";
import styled from "styled-components";
import MobileBusSection from "components/LandingPage/for-mobile/MobileBusSection";
import BusSection from "../forms/BusSection";
import bgImage1 from "assets/TripBouquet/images/home/carousels/bus/bus-1.jpg";
import bgImage2 from "assets/TripBouquet/images/home/carousels/bus/bus-2.jpg";
import bgImage3 from "assets/TripBouquet/images/home/carousels/bus/bus-3.jpg";
import bgImage4 from "assets/TripBouquet/images/home/carousels/bus/bus-4.jpg";
import { useTheme } from "@material-ui/core";

const BusSearch = props => {
    const theme = useTheme();
    const [currentBgImage, setCurrentBgImage] = React.useState(0);

    React.useEffect(() => {
        setCurrentBgImage(
            carouselImages[Math.floor(Math.random() * 4)]
        );
    }, []);

    return(
        <SearchSection theme={theme} bgImage={currentBgImage}>
            <div className="img-overlay"></div>
            <div className="section-content">
                <div className="banner-text">
                    Take Your Next Trip with TripBouquet
                </div>
                <div className="search__box">
                    {props.width < props.breakpoint ? <MobileBusSection /> : <BusSection />}
                </div>
            </div>
        </SearchSection>
    );
};

export default BusSearch;

const carouselImages = [bgImage1, bgImage2, bgImage3, bgImage4]

const SearchSection = styled.div`
width: 100%;
position: relative;
display: flex;
align-items: flex-start;
justify-content: center;
padding-top: 70px;
min-height: 500px;
background-image: url(${prop => prop.bgImage});
background-size: cover;
background-position: center;
background-repeat: no-repeat;
.img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.3);
    z-index: 0;
}
.section-content {
    width: 1200px;
    z-index: 1;
}
.header-container {
    display: flex;
    justify-content: center;
}
.header-hotel-container {
    display: flex;
    justify-content: center;
}
.banner-text {
    font-size: 50px;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    margin-bottom: 55px;
    font-family: TimesNewRoman;
    line-height: 1;
    margin: 0 32px;
}
h3 {
    text-align: center;
    color: #665757;
    font-weight: 400;
    margin-bottom: 0;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    & span {
        display: inline-block;
        margin: 0 5px 5px;
        & .MuiSvgIcon-root {
            font-size: 30px;
        }
    }
}
.search__box {
    min-height: 130px;
    padding: 0px 0;
}
@media all and (max-width: 1220px) {
    .section-content {
        width: 98%;
    }
}
@media all and (max-width: 1026px) {
    .search__box {
        width: 100%;
    }
}
@media all and (max-width: 980px) {
    .search__box {
        width: 100%;
    }
}
`;
