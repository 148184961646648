import { makeStyles } from "@material-ui/core";
import React from "react";
import phone from "assets/TripBouquet/images/icons/phone.png";
import card from "assets/TripBouquet/images/icons/card.png";
import ticket from "assets/TripBouquet/images/icons/ticket.png";
import map from "assets/TripBouquet/images/map.png";
import leftFootsteps from "assets/TripBouquet/images/leftfootstep.png";
import rightFootsteps from "assets/TripBouquet/images/rightfootstep.png";

const RoadMap = () => {
  const width = window.innerWidth;
  console.log(width);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className="roadMapTitle">
        How can we take you to the <span className="hightlight"> journey</span>
      </div>
      <div className="titleDescr">
        Best Tour Lorem ipsum dolor sit amet consectetur. Sagittis etiam quam
        lacus leo. Pellentesque leo eu arcu commodo pharetra. Sed velit quis
        odio consequat. Senectus donec ullamcorper commodo commodo augue.
      </div>
      <div className="stepsContainer">
        <img src={map} className="stepsMap" />
        <div className="stepContainer">
          <img src={phone} className="stepContainerIcon" />
          <div className="stepContainerTitle">
            Find Your <span className="hightlight"> Travel</span> Destination
          </div>
          <div className="stepDescription">
            Lorem ipsum dolor sit amet consectetur. Sagittis etiam quam lacus
            leo. Pellentesque leo eu arcu commodo pharetra. Sed velit quis odio
            consequat.
          </div>
        </div>
        <div className="stepContainer">
          <img src={card} className="stepContainerIcon" />
          <div className="stepContainerTitle">
            Pay and Go To <span className="hightlight"> Destination</span>
          </div>
          <div className="stepDescription">
            Lorem ipsum dolor sit amet consectetur. Sagittis etiam quam lacus
            leo. Pellentesque leo eu arcu commodo pharetra. Sed velit quis odio
            consequat.
          </div>
        </div>
      </div>
      <div className="botttomStepContainer">
        <img src={leftFootsteps} className="footSteps" />
        <div className="stepContainer">
          <img src={ticket} className="stepContainerIcon" />
          <div className="stepContainerTitle">
            Book a <span className="hightlight"> Ticket</span>
          </div>
          <div className="stepDescription">
            Lorem ipsum dolor sit amet consectetur. Sagittis etiam quam lacus
            leo. Pellentesque leo eu arcu commodo pharetra. Sed velit quis odio
            consequat.
          </div>
        </div>
        <img className="footSteps" src={rightFootsteps} />
      </div>
    </div>
  );
};

export default RoadMap;

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 50,
    paddingBottom: 100,
    marginBottom: 20,
    "& .rec-dot": {
      backgroundColor: "#ff0000",
      boxShadow: "none",
      opacity: "30%",
      display: "none",
    },
    "& .rec-dot_active": {
      boxShadow: "0 0 1px 3px #ff0000",
      opacity: "100%",
      display: "none",
    },
    "& .rec-arrow": {
      display: "none",
    },
    // height: 430,
    backgroundColor: "#e9e9e9",
    width: "100%",
    maxWidth: 1350,
    [theme.breakpoints.down(1350)]: {
      width: "90%",
    },
    [theme.breakpoints.down(960)]: {
      width: "100%",
      padding: "0 20px",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .roadMapTitle": {
      fontSize: 55,
      width: 550,

      fontWeight: 700,
      fontFamily: "TimesNewRoman",
      marginBottom: 20,
      lineHeight: 0.9,
      textAlign: "center",
      [theme.breakpoints.down(550)]: {
        width: "80%",
        fontSize: 40,
      },
    },
    "& .hightlight": {
      color: "#FF0000",
      marginRight: 10,
    },
    "& .bookNowBtn": {
      background: `linear-gradient(90.13deg, #9B1111 0.09%, #FF5959 99.86%)`,
      color: "#fff",
      fontWeight: 700,
      fontFamily: "TimesNewRoman",
      fontSize: 17,
      width: 130,
      height: 40,
      borderRadius: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: 10,
    },
    "& .carouselDesc": {
      width: "80%",
      color: "#8D8D8D",
      marginTop: 10,
      fontFamily: "TimesNewRoman",
    },
    "& .titleDescr": {
      width: 800,
      textAlign: "center",
      [theme.breakpoints.down(960)]: {
        width: "80%",
      },
    },
    "& .stepsContainer": {
      display: "flex",
      justifyContent: "space-between",
      width: "80%",
      position: "relative",
      height: 300,
      [theme.breakpoints.down(1200)]: {
        width: "100%",
      },
      [theme.breakpoints.down(900)]: {
        flexDirection: "column",
        height: "unset",
        alignItems: "center",
      },
    },
    "& .stepContainer": {
      width: 500,
      textAlign: "center",
      marginTop: 60,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.down(700)]: {
        width: "100%",
      },
    },
    "& .stepContainerTitle": {
      fontWeight: 700,
      fontFamily: "TimesNewRoman",
      fontSize: 40,
      width: 400,
      lineHeight: 1,
      marginBottom: 15,
      [theme.breakpoints.down(550)]: {
        width: "80%",
        fontSize: 30,
      },
    },
    "& .stepContainerIcon": {
      height: 40,
      width: 40,
      marginBottom: 10,
    },
    "& .stepsMap": {
      position: "absolute",
      left: 0,
      top: 60,
      width: "100%",
    },
    "& .botttomStepContainer": {
      display: "flex",
      alignItems: "center",
    },
    "& .stepDescription": {
      width: 400,
      [theme.breakpoints.down(500)]: {
        width: "100%",
      },
    },
    "& .footSteps": {
      height: 200,
      [theme.breakpoints.down(960)]: {
        display: "none",
      },
      // margin: 20,
    },
  },
}));
