import React, { useContext, useState } from "react";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import PanApplicationReport from "pages/report/pan/PanApplicationReport";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
// import PostLoginNavBar from "components/Navbars/PostLoginNavBar.js";
import PostLoginNavBar from "components/Navbars/PostLoginNavBar.TripBouquet";

// core components
import Sidebar from "components/Sidebar/Sidebar.js";

import { AppStateContext } from "layouts/AppStateProvider";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import Agent from "pages/agent/Agent";
import AgentChangePassword from "pages/agent/AgentChangePassword";

import Ledger from "pages/report/ledger/Ledger";

import MasterBalanceManager from "pages/management/balance/master/MasterBalanceManager";
import B2bBalanceManager from "pages/management/balance/b2b/B2bBalanceManager";
import Voucher from "pages/voucher/Voucher";
import { Flight as FlightReport } from "pages/report/flight/Flight";
import FlightTicket from "pages/report/flight/FlightTicket";

import ChangePassord from "pages/admin/ChangePassword";
import EditProfilePSA from "pages/admin/EditProfilePSA";
import AddBankAccount from "pages/management/bank-account-details/AddBankAccount.js";
import { MoneyTransfer as MoneyTransferReport } from "pages/report/moneytransfer/MoneyTransfer";
import GstDetails from "pages/management/gst/GstDetails";
import CommissionAgent from "pages/management/commission-and-markup/flight/CommissionAgent";
import CommissionAgentEdit from "pages/management/commission-and-markup/flight/CommissionAgentEdit";
//import sideBarImage from "assets/img/sidebar-1.jpg";
import GroupBooking from "pages/groupBooking/GroupBooking";
import Reschedule from "pages/reschedule/Reschedule";
import { localforageGetItem } from "oautils/oaForageUtils";

import BusTicket from "pages/module/bus/BusTicket";
import BusCommissionAndCharge from "pages/module/bus/commission/CommissionAndCharge";
import HotelCommissionAndCharge from "pages/module/hotel/commission/CommissionAndCharge";
import RechargeCommissionAndCharge from "pages/module/recharge/commission/CommissionAndCharge";
import PanCommissionAndCharge from "pages/module/pan/commission/CommissionAndCharge";
import DirectionsBusIcon from "@material-ui/icons/DirectionsBus";
import HotelIcon from "@material-ui/icons/Hotel";
import FlightMarkup from "pages/management/commission-and-markup/flight/Markup";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import { MoneyTransfer } from "pages/report/moneytransfer/MoneyTransfer";

import { RechargeReport } from "pages/report/recharge/RechargeReport";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import { BusReport } from "pages/report/bus/BusReport";
import { HotelReport } from "pages/report/hotel/HotelReport";
import PublishIcon from "@material-ui/icons/Publish";
import AttachMoneyOutlinedIcon from "@material-ui/icons/AttachMoneyOutlined";
import HotelVoucher from "pages/module/hotel/HotelVoucher";
import CompanyLogo from "assets/TripBouquet/images/company_logo.png";
import { Security } from "@material-ui/icons";
import InsuranceCommissionAndCharge from "pages/module/insurance/commission/CommissionAndCharge";
import InsuranceReport from "pages/report/insurance/InsuranceReport";
import InsuranceTicket from "pages/report/insurance/InsuranceTicket";
import { CreditCard } from "@material-ui/icons";
import MasterPaymentOptions from "pages/management/balance/master/MasterPaymentOptions";
import FingerPrint from "@material-ui/icons/Fingerprint";
import AadhaarpayReport from "pages/report/aadhaarpay/AadhaarpayReport";
import AepsReport from "pages/report/aeps/AepsReport";
import AadhaarpayCommissionAndCharge from "pages/module/aadhaarpay/commission/CommissionAndCharge";
import AepsCommissionAndCharge from "pages/module/aeps/commission/CommissionAndCharge";
import { useMediaQuery } from "@material-ui/core";
import FlightReportDetails from "pages/report/flight/FlightReportDetails";
import BusReportDetails from "pages/report/bus/BusReportDetails";
import HotelReportDetails from "pages/report/hotel/HotelReportDetails";

var ps;

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);

  //const [image, setImage] = React.useState(require("assets/img/sidebar-1.jpg"));
  const [color, setColor] = React.useState("blue");
  const [bgColor, setBgColor] = React.useState("black");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [logo, setLogo] = React.useState(
    require("assets/TripBouquet/images/logo/logo.png")
  );

  const { setSecLogo, routes, setRoutes } = useContext(AppStateContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(992));
  const [collapseWidth, setCollapseWidth] = React.useState(false);

  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]: false,
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    localforageGetItem(
      "user-details",
      function(err, value) {
        if (value) {
          var isCorporate = false;
          if (value.corporateType === "corporate") {
            isCorporate = true;
          }
          const tempRoutes = !isCorporate
            ? [...routes]
            : [
                ...routes.filter((rt) => {
                  return rt.name != "Markup";
                }),
              ];
          tempRoutes[1].views = [];
          value.serviceOption.map((options) => {
            switch (options.serviceValue) {
              case "flights": {
                tempRoutes[1].views.push({
                  path: "/booking-report/flight",
                  name: "Flight",
                  icon: PublishIcon,
                  component: FlightReport,
                  layout: "/admin",
                });
                !isCorporate &&
                  tempRoutes[6].views.push({
                    path: "/markup/flight",
                    name: "Flight Markup",
                    icon: MoneyOffIcon,
                    component: FlightMarkup,
                    layout: "/admin",
                  });
                break;
              }
              case "hotels": {
                tempRoutes[1].views.push({
                  path: "/booking-report/hotel",
                  name: "Hotel ",
                  icon: HotelIcon,
                  component: HotelReport,
                  layout: "/admin",
                });
                !isCorporate &&
                  tempRoutes[6].views.push({
                    path: "/commission/hotel",
                    name: "Hotel Markup",
                    icon: HotelIcon,
                    component: HotelCommissionAndCharge,
                    layout: "/admin",
                  });
                break;
              }
              case "buses": {
                tempRoutes[1].views.push({
                  path: "/booking-report/bus",
                  name: "Bus",
                  icon: DirectionsBusIcon,
                  component: BusReport,
                  layout: "/admin",
                });
                !isCorporate &&
                  tempRoutes[6].views.push({
                    path: "/commission/bus",
                    name: "Bus Markup",
                    icon: DirectionsBusIcon,
                    component: BusCommissionAndCharge,
                    layout: "/admin",
                  });
                break;
              }
              case "moneytransfer": {
                tempRoutes[1].views.push({
                  path: "/booking-report/moneytransfer",
                  name: "DMT",
                  icon: AttachMoneyOutlinedIcon,
                  component: MoneyTransfer,
                  layout: "/admin",
                });
                break;
              }
              case "recharge": {
                tempRoutes[1].views.push({
                  path: "/booking-report/recharge",
                  name: "Recharge",
                  icon: PhoneAndroidIcon,
                  component: RechargeReport,
                  layout: "/admin",
                });
                !isCorporate &&
                  tempRoutes[6].views.push({
                    path: "/commission/recharge",
                    name: "Recharge Markup",
                    icon: PhoneAndroidIcon,
                    component: RechargeCommissionAndCharge,
                    layout: "/admin",
                  });
                break;
              }
              case "insurance": {
                tempRoutes[1].views.push({
                  path: "/booking-report/insurance",
                  name: "Insurance",
                  icon: Security,
                  component: InsuranceReport,
                  layout: "/admin",
                });
                !isCorporate &&
                  tempRoutes[6].views.push({
                    path: "/commission/insurance",
                    name: "Insurance Markup",
                    icon: Security,
                    component: InsuranceCommissionAndCharge,
                    layout: "/admin",
                  });
                break;
              }
              case "pan": {
                tempRoutes[1].views.push({
                  path: "/transaction-report/pan",
                  name: "NSDL PAN ",
                  icon: CreditCard,
                  component: PanApplicationReport,
                  layout: "/admin",
                });
                !isCorporate &&
                  tempRoutes[6].views.push({
                    path: "/commission/pan",
                    name: "NSDL PAN Markup",
                    icon: HotelIcon,
                    component: PanCommissionAndCharge,
                    layout: "/admin",
                  });
                break;
              }
              case "aadhaarpay": {
                tempRoutes[1].views.push({
                  path: "/transaction-report/aadhaarpay",
                  name: "Aadhaarpay ",
                  icon: FingerPrint,
                  component: AadhaarpayReport,
                  layout: "/admin",
                });
                !isCorporate &&
                  tempRoutes[6].views.push({
                    path: "/commission/aadhaarpay",
                    name: "Aadhaarpay Markup",
                    icon: FingerPrint,
                    component: AadhaarpayCommissionAndCharge,
                    layout: "/admin",
                  });
                break;
              }
              case "aeps": {
                tempRoutes[1].views.push({
                  path: "/transaction-report/aeps",
                  name: "AEPS ",
                  icon: FingerPrint,
                  component: AepsReport,
                  layout: "/admin",
                });
                !isCorporate &&
                  tempRoutes[6].views.push({
                    path: "/commission/aeps",
                    name: "AEPS Markup",
                    icon: FingerPrint,
                    component: AepsCommissionAndCharge,
                    layout: "/admin",
                  });
                break;
              }
            }
          });
          setRoutes(tempRoutes);
        } else {
          console.log("Error getting values from localforage", err);
        }
      },
      []
    );
    setSecLogo(true);
    // if (false) {
    //   ps = new PerfectScrollbar(mainPanel.current, {
    //     suppressScrollX: true,
    //     suppressScrollY: false,
    //   });
    //   document.body.style.overflow = "hidden";
    // }
    // window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    // return function cleanup() {
    //   if (false) {
    //     ps.destroy();
    //   }
    //   window.removeEventListener("resize", resizeFunction);
    //   const tempRoutes = [...routes];
    //   tempRoutes[1].views = [];
    //   tempRoutes[6].views = [];
    //   setRoutes(tempRoutes);
    //   setSecLogo(false);
    // };
  }, []);
  // functions for changeing the states from components
  /*const handleImageClick = (image) => {
    setImage(image);
  };*/
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleBgColorClick = (bgColor) => {
    switch (bgColor) {
      case "white":
        setLogo(require("assets/img/logo.svg"));
        break;
      default:
        setLogo(require("assets/img/logo-white.svg"));
        break;
    }
    setBgColor(bgColor);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    //setMobileOpen(!mobileOpen);
  };

  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  return (
    <div className={classes.wrapper}>
      <div
        className={mainPanelClasses}
        ref={mainPanel}
        id="mainContainer"
        style={{
          width: collapseWidth
            ? "100%"
            : !isMobile
            ? "calc(100% - 260px)"
            : "100%",
        }}
      >
        <Sidebar
          routes={routes}
          logoText={""}
          logo={CompanyLogo}
          //image={sideBarImage}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={color}
          bgColor={bgColor}
          miniActive={miniActive}
          isMobile={isMobile}
          collapseWidth={collapseWidth}
          setCollapseWidth={setCollapseWidth}
          theme={theme}
          {...rest}
        />
        {
          <PostLoginNavBar
            sidebarMinimize={sidebarMinimize.bind(this)}
            miniActive={miniActive}
            brandText={getActiveRoute(routes)}
            handleDrawerToggle={handleDrawerToggle}
            handleLogoutClick={handleLogout}
            hidelogo={true}
            {...rest}
          />
        }
        {getRoute() ? (
          <div className={classes.container} style={{ paddingTop: 40 }}>
            <Switch>
              <Route
                path="/admin/user/change-password"
                exact
                component={ChangePassord}
              />
              <Route
                path="/admin/user/edit-profile"
                exact
                component={EditProfilePSA}
              />
              <Route
                path="/admin/management/gst-details"
                exact
                component={GstDetails}
              />
              <Route
                path="/admin/management/gst-details/:agentId"
                exact
                component={GstDetails}
              />

              <Route
                path="/admin/user/agents/change-password/:agencyId"
                exact
                component={AgentChangePassword}
              />
              <Route
                path="/admin/user/agents/:priTab/:agencyId"
                exact
                component={Agent}
              />
              <Route
                path="/admin/user/agents/:priTab"
                exact
                component={Agent}
              />

              <Route
                path="/admin/report/moneytransfer/:agencyId"
                exact
                component={MoneyTransferReport}
              />
              <Route path="/admin/ledger/:agencyId" exact component={Ledger} />
              <Route path="/admin/ledger/" exact component={Ledger} />

              <Route
                path="/admin/management/balance/master/:priTab/:secTab"
                exact
                component={MasterBalanceManager}
              />
              {/* <Route
                path="/admin/management/balance/master/payment"
                exact
                component={MasterPaymentOptions}
              /> */}
              <Route
                path="/admin/management/balance/master/:priTab/:secTab"
                exact
                component={MasterBalanceManager}
              />
              <Route
                path="/admin/management/balance/master/:priTab"
                exact
                component={MasterBalanceManager}
              />

              <Route
                path="/admin/management/balance/b2b/:priTab/:agencyId"
                exact
                component={B2bBalanceManager}
              />
              <Route
                path="/admin/management/balance/b2b/:priTab"
                exact
                component={B2bBalanceManager}
              />

              <Route
                path="/admin/booking-report/flight/ticket/:ticketId"
                exact
                component={FlightTicket}
              />
              <Route
                path="/admin/report/flight/:agencyId"
                exact
                component={FlightReport}
              />

              <Route
                path="/admin/report/moneytransfer/:agencyId"
                exact
                component={MoneyTransferReport}
              />

              <Route path="/admin/voucher/:type/:ref" component={Voucher} />
              <Route
                path="/admin/management/add-bank-account/:priTab/:bankId"
                exact
                component={AddBankAccount}
              />
              <Route
                path="/admin/management/add-bank-account/:priTab"
                exact
                component={AddBankAccount}
              />
              <Route
                path="/admin/management/add-bank-account"
                exact
                component={AddBankAccount}
              />

              <Route
                path="/admin/management/commission/agent/flight/:priTab/:agencyId"
                exact
                component={CommissionAgent}
              />
              <Route
                path="/admin/management/commission/agent/flight/:priTab"
                exact
                component={CommissionAgent}
              />
              <Route
                path="/admin/management/commission/agent/flight"
                exact
                component={CommissionAgent}
              />

              <Route
                path="/admin/management/commission/edit/flight/:priTab/:agencyId"
                exact
                component={CommissionAgentEdit}
              />
              <Route
                path="/admin/groupbooking"
                exact
                component={GroupBooking}
              />
              <Route path="/admin/reschedule" exact component={Reschedule} />

              {/* <Route
                path="/admin/moneytransfer/add-beneficiary"
                exact
                component={AddBeneficiary}
              />
              <Route
                path="/admin/moneytransfer/add-sender"
                exact
                component={AddSender}
              />
              <Route
                path="/admin/moneytransfer/beneficiary-list"
                exact
                component={BeneficiaryList}
              />
              <Route
                path="/admin/moneytransfer/verify-otp"
                exact
                component={VerifyOtp}
              />
              <Route
                path="/admin/moneytransfer/transaction"
                exact
                component={Transaction}
              />
              <Route
                path="/admin/moneytransfer/commission"
                exact
                component={CommissionManager}
              /> */}

              <Route
                path="/admin/booking-report/flight/details/:ticketId"
                exact
                component={FlightReportDetails}
              />
              <Route
                path="/admin/booking-report/bus/details/:ticketId"
                exact
                component={BusReportDetails}
              />
              <Route
                path="/admin/booking-report/hotel/details/:ticketId"
                exact
                component={HotelReportDetails}
              />
              <Route
                path="/admin/booking-report/bus/ticket/:ticketId"
                exact
                component={BusTicket}
              />
              <Route
                path="/admin/booking-report/insurance/ticket/:ticketId"
                exact
                component={InsuranceTicket}
              />

              <Route
                path="/admin/booking-report/hotel/ticket/:ticketId"
                exact
                component={HotelVoucher}
              />

              {getRoutes(routes)}
              <Redirect from="/admin" to="/admin/dashboard" />
            </Switch>
          </div>
        ) : (
          <div className={classes.map}>
            <Switch>
              {getRoutes(routes)}
              <Redirect from="/admin" to="/admin/dashboard" />
            </Switch>
          </div>
        )}
      </div>
      <style>{`
      .oa-navbar-container {
        padding: 0 !important;
      }
      `}</style>
    </div>
  );
}
