import React, { useEffect, useState } from "react";
import {
  AppBar,
  makeStyles,
  Tab,
  Tabs,
  Box,
  Typography,
} from "@material-ui/core";
// import promoImg1 from "../../../assets/TripBouquet/images/offer.jpg";
// import promoImg2 from "../../../assets/TripBouquet/images/offer2.jpg";
// import promoImg3 from "../../../assets/TripBouquet/images/offer3.png";
// import promoImg4 from "../../../assets/TripBouquet/images/offer4.png";
import Carousel from "react-elastic-carousel";
import { BASE_URL_IMAGE_VIEW } from "api/ApiConstants";
import PropTypes from "prop-types";
import { Switch, Route, Link } from "react-router-dom";

// for mui tab
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0} style={{ width: "100%", height: "100%" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// for mui tab /

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "1200px",
    // width: '100%',
    margin: " 10px auto",
    position: "relative",

    [theme.breakpoints.down(980)]: {
      padding: "0 20px",
      width: "100%",
    },
    [theme.breakpoints.down(1026)]: {
      width: "100%",
    },
    "& .info__section": {
      display: "flex",
      flexWrap: "wrap",
      gap: "1rem",
      justifyContent: "center",
      [theme.breakpoints.down(980)]: {
        flexDirection: "column",
      },
      "& > div": {
        margin: "12px auto",
        display: "table",
        padding: "14px 15px",
        width: " 49%",
        borderRadius: "4px",
        [theme.breakpoints.down(980)]: {
          width: "100%",
        },
      },
      "& .info__left": {
        float: "left",
        color: "#856404",
        backgroundColor: " #fff3cd",
        border: "1px solid #f1dfab",
        paddingLeft: "3rem",
        textAlign: "center",
        fontSize: "14px",
        [theme.breakpoints.down(980)]: {
          paddingLeft: "1rem",
          textAlign: "left",
        },
        "& a": {
          color: "#856404",
          textDecoration: "underline",
        },
        "& strong": {
          textAlign: 'left'
        }
      },
      "& .info__right": {
        float: "right",
        color: "#31708f",
        textAlign: "center",
        backgroundColor: "#d9edf7",
        border: "1px solid #bce8f1",
        "& strong": {
          textAlign: 'left'
        },
        paddingLeft: "5rem",
        [theme.breakpoints.down(980)]: {
          paddingLeft: "1rem",
          textAlign: "left",
        },
        fontSize: "14px",
        "& a": {
          color: "#31708f",
          textDecoration: "underline",
        },
      },
    },

    "& .info-bottom": {
      color: "#155724",
      fontSize: "13px",
      backgroundColor: "#d4edda",
      border: "1px solid #c3e6cb",
      padding: "13px 2rem",
      borderRadius: "4px",
      textAlign: "center",
      width: "100%",
      margin: "13px auto",
    },

    "& .offer__section": {
      position: "relative",
      width: "100%",
      "& .offers__header": {
        display: "flex",
        [theme.breakpoints.down(980)]: {
          flexDirection: "column",
        },
        "& .title span": {
          fontSize: "28px",
          color: "#000",
        },
        // "& .offer__filters-options span": {
        //   marginRight: "20px",
        //   cursor: "pointer",
        //   display: "inline-block",
        //   textTransform: "uppercase",
        //   fontWeight: "600",
        //   transition: "all 0.3s",
        //   padding: "10px 0px",
        //   "&.activeBtn": {
        //     borderBottom: "3px solid #ef6614",
        //     color: "#ef6614",
        //   },
        //   "&:hover": {
        //     borderBottom: "3px solid #ef6614",
        //     color: "#ef6614",
        //   },
        // },
        justifyContent: "space-between",
        "& > div": {
          display: "flex",
          gap: "2rem",
          alignItems: "center",
        },
        "& .view-all": {
          textDecoration: "underline",
          color: theme.palette.primary.info,
        },
      },

      "& .offers": {
        position: "relative",
        height: "auto",
        marginTop: "2rem",
        display: "flex",

        gap: "0.5rem",
        "& .offer__card": {
          width: "343px",
          height: "163px",
          backgroundColor: "#fff",
          boxShadow: "0 0 7px rgb(0 0 0 / 20%)",
          borderRadius: "4px",
          padding: "10px",
          justifyContent: "start",
          "& .promo-content": {
            "& > img": {
              width: "106px",
              height: "106px",
              objectFit: "cover",
              borderRadius: "4px",
            },
            display: "flex",
            gap: "0.5rem",
            "& .offer__desc ": {
              "& h4": {
                fontSize: "15px",
                fontWeight: "700",
                color: " #000",
                margin: 0,
                marginBottom: "5px",
              },
              "& p": {
                fontSize: "12px",
                fontWeight: "400",
                color: "#000",
                lineHeight: 1,
              },

              "& .promoCode": {
                position: "relative",
                marginTop: "1.3rem",
                "& .promo-tag": {
                  position: "absolute",
                  display: "inline-block",
                  padding: "1px 5px",
                  borderRadius: "20px",
                  backgroundColor: "green",
                  color: "#fff",
                  top: "-16px",
                  left: "7px",
                  fontSize: "10px",
                  textTransform: "uppercase",
                },
                "& .p-code": {
                  padding: "6px 8px",
                  color: "#000",
                  fontSize: "13px",
                  textTransform: "uppercase",
                  fontWeight: "600",
                  display: "inline-block",
                  border: "1.3px dashed #A7A7A7",
                  "& span": {
                    // borderLeft:'1.3px dashed #000',
                    display: "inline-block",
                    // margin:'0 0.3rem',
                    marginLeft: "0.3rem",
                    // padding:"6px 8px",
                    cursor: "pointer",
                    // fontSize: "13px",
                  },
                  "&:hover,:focus": {
                    "& .tooltiptext": {
                      visibility: "visible",
                    },
                  },
                  /* Tooltip text */
                  "& .tooltiptext": {
                    visibility: "hidden",
                    // top:"-16px",
                    // width:" 90px",
                    textTransform: "lowercase",
                    backgroundColor: "#A7A7A7",
                    color: " #fff",
                    marginTop: 10,
                    textAlign: "center",
                    padding: "0 6px",
                    borderRadius: "4px",
                    /* Position the tooltip text - see examples below! */
                    position: " absolute",
                    zIndex: 1,
                  },
                  "&:hover,:focus": {
                    "& .tooltiptext": {
                      visibility: "visible",
                    },
                  },
                  /* Tooltip text */
                  "& .tooltiptext": {
                    visibility: "hidden",
                    // top:"-16px",
                    // width:" 90px",
                    textTransform: "lowercase",
                    backgroundColor: "#A7A7A7",
                    color: " #fff",
                    marginTop: 10,
                    textAlign: "center",
                    padding: "0 6px",
                    borderRadius: "4px",
                    /* Position the tooltip text - see examples below! */
                    position: " absolute",
                    zIndex: 1,
                  },
                },
              },
            },
          },
        },

        "& .promo-valid": {
          textAlign: "right",
          margin: "0.5rem 0",
        },
        "& .glqWOv": {
          display: "none",
        },
        "& .kwUGKV": {
          display: "none",
        },
        "&.active": {
          "& .kwUGKV": { display: "block" },
        },
        "& .kyQlHw": {
          height: "23.33vh",
        },
      },
    },

    "& .tab-panel": {
      width: "100%",
      height: "100%",
    },
    "& .PrivateTabIndicator": {
      color: theme.palette.secondary.main,
      // backgroundColor:theme.palette..
    },

    "& .MuiTab-root": {
      [theme.breakpoints.up("sm")]: {
        minWidth: "0px",
      },
    },

    "& .MuiTab-textColorInherit.Mui-selected": {
      color: theme.palette.secondary.main,
    },
  },
}));

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 850, itemsToShow: 3 },
  // { width: 1150, itemsToShow: 3},
  // { width: 1450, itemsToShow: 5 },
  // { width: 1750, itemsToShow: 6 },
];

const OffersSection = (props) => {
  const [copyText, setCopyText] = useState("");
  const [Tabvalue, setTabValue] = useState(0);

  const handleCopy = (e, val) => {
    setCopyText(val);
    navigator.clipboard.writeText(copyText);
  };

  const offers = props.offers;

  // useEffect(() => {
  //   WebApi.getExclusiveOffer(
  //     {
  //       callFrom: "web",
  //     },
  //     (resp) => {
  //       if (resp != null && resp.success) {
  //         // console.log(resp.data, "get pop");
  //         setOffers(resp.data);
  //         // setDataIs(true);
  //       } else {
  //         console.log("error resp null", resp);
  //       }
  //     }
  //   );
  // }, []);
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className="">
        {/* <div className="info__section">
          <div className="info__left">
            <strong>Important Info:</strong> 
            <br />
            <a href="#" target="_blank">
              {" "}
              click here
            </a>
          </div>

          <div className="info__right">
            <strong>Travel Guide:</strong>
            <a href="#" target="_blank">
            click here
            </a>
          </div>
        </div> */}
        <div className="info-bottom" style={{ display: "none" }}>
          <div>
            <strong>FREE Full Refund due to Medical Reasons:</strong>TripBouquets is with you in this difficult time! <br />
            If you cancel the booking for any medical reasons, you will be given a full refund on the domestic ticket. No additional charges will be deducted from you for this.
          </div>
        </div>
      </div>

      {offers && offers.length > 0 && (
        <div className="offer__section">
          <div className="offers__header">
            <div>
              <div className="title">
                <span>Exclusive Offers</span>
              </div>
              <div className="offer__filters-options">
                {/* <span className="activeBtn">Best Offers</span> */}
                <AppBar
                  position="static"
                  style={{
                    backgroundColor: "#fff",
                    margin: "1rem 0",
                    boxShadow: "none",
                  }}
                >
                  <Tabs
                    value={Tabvalue}
                    onChange={handleChangeTab}
                    aria-label="simple tabs example"
                  // TabIndicatorProps={{style: {height:'6px',marginTop:'10px'}}}
                  >
                    {offers &&
                      offers.map((elm, idx) => (
                        <Tab label={elm.categoryName} {...a11yProps(idx)} key={idx} />
                      ))}
                  </Tabs>
                </AppBar>
              </div>
            </div>
            <div>
              <Link
                to="/exclusive-offers/view-all"
                target="_blank"
                className="view-all"
              >
                View All Offers
              </Link>

              {/* {promoData.length < 5 && (
              <div className="arrow__btns">
                <IconButton>
                  <i className="fas fa-chevron-left"></i>
                </IconButton>
                <IconButton>
                  <i className="fas fa-chevron-right"></i>
                </IconButton>
              </div>
            )} */}
            </div>
          </div>

          <div className={`offers`}>
            {offers &&
              offers.map((category, cateIndex) => {
                return (
                  <TabPanel
                    value={Tabvalue}
                    index={cateIndex}
                    key={cateIndex}
                    className="tab-panel"
                  >
                    <Box>
                      {
                        <Carousel
                          itemsToShow={3}
                          pagination={false}
                          breakPoints={breakPoints}
                          showEmptySlots
                        >
                          {category?.exclusiveOffers?.map((val, index) => (
                            <div className="offer__card" key={index}>
                              <div className="promo-content">
                                <img
                                  src={
                                    BASE_URL_IMAGE_VIEW +
                                    `agency/view-file?fileName=${val.promoImage}&type=PROMO_IMAGES`
                                  }
                                />
                                <div className="offer__desc">
                                  <h4>{val.promoHeading}</h4>
                                  <p>{val.description}</p>
                                  <div className="promoCode">
                                    <span className="promo-tag">promocode</span>
                                    <div className="p-code">
                                      {val.promoCode}
                                      <span
                                        onClick={(e) =>
                                          handleCopy(e, val.promoCode)
                                        }
                                      >
                                        <i className="far fa-copy"></i>
                                      </span>
                                      <span className="tooltiptext">copy</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="promo-valid">
                                valid till: {val.validTo}
                              </div>
                            </div>
                          ))}
                        </Carousel>
                      }
                    </Box>
                  </TabPanel>
                );
              })}
            {/* <Carousel
              itemsToShow={3}
              pagination={false}
              breakPoints={breakPoints}
              showEmptySlots
            >
              {offers &&
                offers.map((val, index) => (
                  <div className="offer__card" key={index}>
                    <div className="promo-content">
                      <img
                        src={
                          BASE_URL_IMAGE_VIEW +
                          `agency/view-file?fileName=${val.promoImage}&type=PROMO_IMAGES`
                        }
                      />
                      <div className="offer__desc">
                        <h4>{val.promoHeading}</h4>
                        <p>{val.description}</p>
                        <div className="promoCode">
                          <span className="promo-tag">promocode</span>
                          <div className="p-code">
                            {val.promoCode}
                            <span onClick={(e) => handleCopy(e, val.promoCode)}>
                              <i className="far fa-copy"></i>
                            </span>
                            <span className="tooltiptext">copy</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="promo-valid">valid till: {val.validTo}</div>
                  </div>
                ))}
            </Carousel> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default OffersSection;
