import React from "react";
import styled from "styled-components";
import MobileHotelSection from "components/LandingPage/for-mobile/MobileHotelSection";
import HotelSection from "../forms/HotelSection";
import bgImage1 from "assets/TripBouquet/images/home/carousels/hotel/hotel-1.jpg";
import bgImage2 from "assets/TripBouquet/images/home/carousels/hotel/hotel-2.jpg";
import bgImage3 from "assets/TripBouquet/images/home/carousels/hotel/hotel-3.jpg";
import { useTheme } from "@material-ui/core";

const HotelSearch = props => {
    const theme = useTheme();
    const [currentBgImage, setCurrentBgImage] = React.useState(0);

    React.useEffect(() => {
        setCurrentBgImage(
            carouselImages[Math.floor(Math.random() * 3)]
        );
    }, []);

    return(
        <SearchSection theme={theme} bgImage={currentBgImage}>
            {/* <div className="carousel-div">
                <Carousel
                    ref={carouselRef}
                    itemsToShow={1}
                    showArrows={true}
                    pagination={true}
                    enableAutoPlay={true}
                    autoPlaySpeed={autoPlaySpeed}
                    onNextEnd={({ index }) => {
                        if (index + 1 === carouselImages.length) {
                           setTimeout(() => {
                              carouselRef.current.goTo(0)
                          }, autoPlaySpeed);
                        }
                    }}
                >
                    {carouselImages && carouselImages.map((src, ind) => 
                        <div className="img-container">
                            <img key={ind} src={src}/>
                            <div className="img-overlay"></div>
                        </div>
                    )}
                </Carousel>
            </div> */}
            <div className="img-overlay"></div>
            <div className="section-content">
                <div className="banner-text">
                    Take Your Next Trip with TripBouquet
                </div>
                <div className="search__box">
                    {props.width < props.breakpoint ? <MobileHotelSection /> : <HotelSection />}
                </div>
            </div>
        </SearchSection>
    );
};

const carouselImages = [bgImage1, bgImage2, bgImage3]

export default HotelSearch;

const SearchSection = styled.div`
width: 100%;
position: relative;
display: flex;
align-items: flex-start;
justify-content: center;
padding-top: 70px;
min-height: 500px;
background-image: url(${prop => prop.bgImage});
background-size: cover;
background-position: center;
background-repeat: no-repeat;
.img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.3);
    z-index: 0;
}
.carousel-div {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    & .rec-carousel-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
    }
    & .rec-pagination {
        position: absolute;
        bottom: 4px;
        & button {
            height: 6px;
            width: 6px;
            box-shadow: 0 0 1px 1px rgba(200,200,200,0.4);
            background-color: rgba(200,200,200,.4);
            &.rec-dot_active {
                background-color: rgba(240,240,240,0.8);
                box-shadow: 0 0 1px 2px rgba(240,240,240,0.8);
            }
        }
    }
    & .rec-carousel {
        width: 100%;
        height: 100% !important;
        position: relative;
        & .rec.rec-arrow {
            position: absolute;
            top: 42%;
            z-index: 1;
            background: none !important;
            color: #fff;
            box-shadow: unset;
            &:hover {
                background: none !important;
            }
            &.rec-arrow-left {
                left: 0;
            }
            &.rec-arrow-right {
                right: 0;
            }
        }
    }
    & .rec-slider-container {
        margin: 0;
    }
    & .rec-slider {
        height: 100%;
        & .rec-item-wrapper {
            height: 100%;
            & .img-container {
                width: 100%;
                height: 100%;
                position: relative;
                & .img-overlay {
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    background-color: rgba(0,0,0,0.2);
                }
            }
            & img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
.section-content {
    width: 1200px;
    z-index: 1;
}
.header-container {
    display: flex;
    justify-content: center;
}
.header-hotel-container {
    display: flex;
    justify-content: center;
}
.banner-text {
    font-size: 50px;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    margin-bottom: 55px;
    font-family: TimesNewRoman;
}
h3 {
    text-align: center;
    color: #665757;
    font-weight: 400;
    margin-bottom: 0;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    & span {
        display: inline-block;
        margin: 0 5px 5px;
        & .MuiSvgIcon-root {
            font-size: 30px;
        }
    }
}
.search__box {
    min-height: 130px;
    padding: 0px 0;
}
@media all and (max-width: 1220px) {
    .section-content {
        width: 98%;
    }
}
@media all and (max-width: 1026px) {
    .search__box {
        width: 100%;
    }
}
@media all and (max-width: 980px) {
    .search__box {
        width: 100%;
    }
}
`;
