import React from "react";
import logo from "assets/TripBouquet/images/logo/logo.png";
import facebook from "assets/TripBouquet/images/icons/facebook.png";
import twitter from "assets/TripBouquet/images/icons/twitter.png";
import instagram from "assets/TripBouquet/images/icons/instagram.png";
import travlogy_logo from "assets/travlogy/images/travlogy_logo.png";
import linked_in from "assets/TripBouquet/images/icons/linked-in.png";
import youtube from "assets/TripBouquet/images/icons/youtube-dark.png";


import secureBy from "assets/TripBouquet/images/sectigo-logo.png";

// import TAAFI from "assets/TripBouquet/images/taafi_logo.png";

import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { AppConstant } from "appConstant";
import { useHistory } from "react-router-dom";

export const FooterSection = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down(760));

  return (
    <div className={classes.root}>
      <div className="footerContainer">
        <img src={logo} className="footerLogo" />

        <div className="footerLinksContainer">
          <div className="footerWrapper">
            <div className="footerLinkTitle">Products</div>
            <div>
              <a href="" onClick={() => history.push("/flights")}
                className="footerLinkItem">
                Flights
              </a>
            </div>
            <div>
              <a href="" onClick={() => history.push("/hotels")}
                className="footerLinkItem">
                Hotels
              </a>
            </div>
            <div>
              <a href="" onClick={() => history.push("/buses")}
                className="footerLinkItem">
                Buses
              </a>
            </div>
          </div>
          <div className="footerWrapper">
            <div className="footerLinkTitle">Info</div>
            <div>
              <a href="" className="footerLinkItem"
                onClick={() => history.push("/termsandconditions")}
              >
                Terms & condition
              </a>
            </div>
            <div>
              <a href=""
                className="footerLinkItem"
                onClick={() => history.push("/privacypolicy")}
              >
                Privacy Policy
              </a>
            </div>
            <div>
              <a href=""
                className="footerLinkItem"
                onClick={() => history.push("/aboutus")}
              >
                About us
              </a>
            </div>
          </div>

          <div className="footerWrapper">
            <div className="footerLinkTitle">Contact Info</div>
            <div >
              <a
                className="footerLinkItem"
                href={`mailto:${AppConstant.supportEmail}`}
              >
                {AppConstant.supportEmail}
                {/* support@tripbouquet.com< */}
              </a>
            </div>
            <div>
              <a
                className="footerLinkItem"
                href={`tel:${AppConstant.countryDialCode}${AppConstant.supportContact}`}
              >
                +{AppConstant.countryDialCode} {AppConstant.supportContactView}
              </a>
            </div>

            <div> <a href={`tel:${AppConstant.infoContactView}`} className="footerLinkItem">{AppConstant.infoContactView}</a></div>
          </div>

          <div className="footerWrapper">
            <div className="footerLinkTitle">Follow us</div>
            <div className="footerLinkSocialMedia">
              <a href="https://facebook.com/TripBouquet" target="_blank">
                <img src={facebook} className="socialMediaIcon" />
              </a>
              <a href="https://www.instagram.com/tripbouquet" target="_blank">
                <img src={instagram} className="socialMediaIcon" />
              </a>
              <a href="https://www.linkedin.com/company/trip-bouquet/" target="_blank">
                <img src={linked_in} className="socialMediaIcon" />
              </a>
              <a href="https://www.twitter.com/" target="_blank">
                <img src={twitter} className="socialMediaIcon" />
              </a>
              <a href="https://www.youtube.com/" target="_blank">
                <img src={youtube} className="socialMediaIcon" />
              </a>
            </div>
            <div className="secureBy">
              <a href="https://www.sectigo.com/" target="_blank">
                <img src={secureBy} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footerCopyRight">
        <span
          style={{ width: "10%", fontSize: "9px" }}
          className="copyright-text"
        >
          Hand Crafted by{" "}
          <a href="http://travlogy.co" target="_blank">
            <img src={travlogy_logo} style={{ height: "14px" }} />
          </a>
        </span>
        <span style={{ width: "90%", margin: "10px 0 10px; !important" }}>
          ©2023 by TripBouquet - All Rights Reserved
        </span>
      </div>
    </div>
  );
};

export default FooterSection;

const footerLinks = [
  {
    linkHeader: "OUR PRODUCTS",
    links: [
      {
        title: "Book Flight",
        url: "/flights",
      },
      // {
      //     title: "Book Flight",
      //     url: "/flights",
      // },
      {
        title: "Search Hotels",
        url: "/hotels",
      },
      {
        title: "Search Buses",
        url: "/buses",
      },
      // {
      //     title: "Hotel",
      //     url: "/flights",
      // },
      // {
      //     title: "Holiday Packages",
      //     url: "",
      // },
    ],
  },
  {
    linkHeader: "LMT INFO",
    links: [
      {
        title: "Terms & Conditions",
        url: "/termsandconditions",
      },
      {
        title: "Privacy Policy",
        url: "/privacypolicy",
      },
      {
        title: "About Us",
        url: "/aboutus",
      },
      {
        title: "Contact Us",
        url: "/contactus",
      },
    ],
  },
  // {
  //     linkHeader: "Contact Info",
  //     links: [
  //         {
  //             title: 'info@triumphhtravel.comy',
  //             url: "",
  //         },
  //         {
  //             title: '+91 1141 611 366',
  //             url: "",
  //         },
  //         {
  //             title: '+91 1141 611 366',
  //             url: "",
  //         },
  //         {
  //             title: '609, Vishwa Sadan Building, Janakpuri',
  //             url: "",
  //         },
  //         {
  //             title: 'District Centre, New Delhi - 110058',
  //             url: "",
  //         },
  //     ]
  // },
];

const useStyles = makeStyles((theme) => ({
  root: {
    height: 200,
    [theme.breakpoints.down(500)]: {
      height: "100%",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "0px 0px 0px 1px rgba(232,232,232,232)",
    "& .contactInfoColumn": {
      [theme.breakpoints.down(700)]: {
        display: "none",
      },
    },
    "& .contactInfoRow": {
      display: "none",
      [theme.breakpoints.down(700)]: {
        display: "flex",
        padding: "10px 0px",
      },
      "& .footerLinkItem": {
        [theme.breakpoints.down(700)]: {
          padding: "10px 5px",
        },
      },
    },
    "& .footerContainer": {
      width: "100%",
      display: "flex",
      marginTop: 20,
      padding: "0px 20px",
      justifyContent: "space-between",
      [theme.breakpoints.down(950)]: {
        width: "100%",
        padding: "0px 20px",
      },
      "& .footerLinks": {
        width: "15%",
        [theme.breakpoints.down(950)]: {
          width: "15%",
        },
        [theme.breakpoints.down(700)]: {
          textAlign: "center",
        },
        [theme.breakpoints.down(500)]: {
          padding: "20px 0px",
          flexDirection: "column",
          width: "80%",
        },
      },
      [theme.breakpoints.down(700)]: {
        padding: 20,
        flexDirection: "column",
        marginTop: 10,
        alignItems: "center",
      },
      [theme.breakpoints.down(500)]: {
        padding: "20px 0px",
      },
    },
    "& .footerCopyRight": {
      background: `linear-gradient(90.13deg, #9B1111 0.09%, #FF5959 99.86%)`,
      width: "100%",
      color: "rgba(255,255,255, .5)",
      fontSize: 14,
      fontWeight: 500,
      textAlign: "center",
      height: 50,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& .copyRight": {
        width: "100%",
        justifyContent: "space-between",
        display: "flex",
        backgroundColor: theme.palette.primary.darkText,
        paddingLeft: "1rem",
        borderTop: `1px solid ${theme.palette.primary.lightGray}`,
        color: theme.palette.primary.lightText,
        "& .copyright-text": {
          fontSize: 9,
          "& img": {
            //height: 14,
            //margin: "0 0 0px 5px",
          },
        },
      },
      "& .copyright-text": {
        margin: "0 !important",
      },
    },
    "& .footerLogo": {
      //width: 400,
      height: 80,

      [theme.breakpoints.down(1300)]: {
        // width: 300,
        height: 70,
      },
      [theme.breakpoints.down(950)]: {
        // width: 250,
        height: 50,
      },
      [theme.breakpoints.down(500)]: {
        // width: 250,
        height: 50,
      },
    },
    "& .footerLinkTitle": {
      fontSize: 18,
      fontWeight: 700,
      marginBottom: 5,
    },
    "& .footerLinkItem": {
      fontSize: 14,
      fontWeight: 500,
      marginBottom: 3,
      //  textDecoration: "underline",
      cursor: "pointer",
      wordWrap: "break-word",
      color: theme.palette.primary.defaultText,
      [theme.breakpoints.down(500)]: {
        fontSize: 12,
      },
    },
    "& .footerLinkSocialMedia": {
      display: "flex",
      [theme.breakpoints.down(700)]: {
        justifyContent: "center",
      },
    },
    "& .secureBy": {
      marginTop: "20px",
    },

    "& .socialMediaIcon": {
      height: 20,
      width: 20,
      marginRight: 20,
      [theme.breakpoints.down(700)]: {
        margin: "0px 10px",
      },
    },
    "& .footerLinksContainer": {
      display: "flex",
      justifyContent: "space-between",
      width: "60%",
      flexDirection: "row",

      [theme.breakpoints.down(960)]: {
        width: 300,
        paddingTop: "20",

        textAlign: "center",
        flexDirection: "column",
      },
    },
    "& .footerWrapper": {
      width: "25% !important",
      [theme.breakpoints.down(960)]: {
        paddingTop: "20",
        width: "100% !important",
      },
    },
  },
}));
