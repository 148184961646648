import React, { useState, useEffect } from "react";
import {
  Button,
  Divider,
  Grid,
  Typography,
  TextField,
  Checkbox,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";

import CarrierImage from "assets/img/6E.png";
import { apiCall } from "oautils/oaDataUtils";
import WebApi from "api/ApiConstants";
import { connect } from "react-redux";
import { setB2CBusList } from "redux/action";
import moment from "moment";
import { withRouter } from "react-router-dom";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import PrintIcon from "@material-ui/icons/Print";
import DescriptionIcon from "@material-ui/icons/Description";
import CancelIcon from "@material-ui/icons/Cancel";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { TextFields } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import OaFormAlerts from "pages/components/OaFormAlerts";
import { basename } from "api/ApiConstants";
import { useParams, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    margin: "auto",
    //paddingTop: "40px",
    paddingBottom: "40px",
    "& .MuiBox-root": {
      padding: 0,
    },
  },
  grid: {
    background: "#fff",
    paddingBottom: "6px",
    textAlign: "center",
    // border: "1px solid #bfb6b6",
    padding: "20px",
    borderRadius: "5px",
    [theme.breakpoints.down("sm")]: {
      padding: 5,
    },
  },
  grid1: {
    background: "#e2e2e2",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  grid2: {
    borderRight: "1px solid #ababab",
    paddingRight: 25,
    paddingBottom: 25,
    [theme.breakpoints.down("sm")]: {
      paddingRight: 0,
      borderRight: "none",
    },
  },
  typo1: {
    fontSize: "11px",
    fontWeight: 500,
    lineHeight: "1.5",
    color: "#212529",
    marginLeft: 5,
    letterSpacing: "0.5px !important",
  },
  typo2: {
    fontSize: "13px",
    paddingRight: "10px",
    fontWeight: 700,
    color: "#212529",
    letterSpacing: "0.5px !important",
    borderRight: "1px solid",
    [theme.breakpoints.down("sm")]: {
      borderRight: "none",
    },
  },
  typo3: {
    fontSize: "13px",
    fontWeight: 700,
    color: "#212529",
    letterSpacing: "0.5px !important",
    paddingLeft: "10px",
  },
  typo4: {
    fontSize: 12,
    color: "#000",
    fontWeight: 500,
  },
  typo5: {
    fontSize: 11,
    color: "#000",
    fontWeight: 300,
    opacity: 0.7,
  },
  typo6: {
    fontSize: 16,
    color: "#000",
    fontWeight: 400,
  },
  typo7: {
    fontSize: 12,
    color: "grey",
    fontWeight: 400,
  },
  typo8: {
    fontSize: 11,
    fontWeight: 300,
  },
  typo9: {
    color: "#6f6f6f",
    fontSize: 12,
  },
  typo10: {
    fontSize: 12,
    color: "#828080",
    fontWeight: 500,
  },
  button: {
    background: "#2b5a85",
    color: "#fff",
    fontSize: 13,
    textAlign: "center",
    padding: 8,
    borderRadius: 10,
    textTransform: "capitalize",
    "&:hover": {
      background: "#2b5a85",
    },
  },
}));

const BusReportDetails = (props) => {
  const classes = useStyles();
  const history = useHistory();
  // const [hotelList,setHotelList]=useState([]);
  // console.log(props);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [passengers, setPassengers] = useState([]);
  const [open, setOpen] = useState(false);
  const [allSeatSelected, setAllSeatSelected] = useState(true);
  const [remarks, setRemarks] = useState("");
  const [cancellationPolicy, setCancellationPolicy] = useState([]);
  const [tid, setTid] = useState(0);
  const [cancellationChargePerSeat, setCancellationChargePerSeat] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [bookingAmount, setBookingAmount] = useState(0);
  const [busData, setBusData] = useState();
  const params = useParams();

  useEffect(() => {
    apiCall(
      WebApi.getBusSeatBookingDetails,
      { tid: params.ticketId },
      (response) => {
        console.log(response.data.GetBookingDetailResult, "RESPONSE---------");
        if (response.success === true) {
          setBusData(response.data.GetBookingDetailResult);
          //   clg;s
          // setIsSuccess(true);
          // setData(response.data);
          // if (response.data.GetBookingDetailResult && response.data.GetBookingDetailResult.agencyInfo) {
          // setMarkup(response.data.GetBookingDetailResult.agentMarkup)
          // setMarkupTemp(response.data.GetBookingDetailResult.agentMarkup)
          // }
          // setIsLoading(false);
        } else {
          setIsError(true);
          // setIsLoading(false);
        }
      }
    );
  }, []);

  const busCancellationHandler = () => {
    let _seatIds = [];
    let passenger = busData?.Itinerary?.Passenger;
    if (passenger) {
      passenger.map((value) => {
        // console.log(value?.Seat?.SeatName);
        if (value?.Seat?.SeatName) _seatIds.push(+value?.Seat?.SeatName);
        // _seatIds.push(1);
      });
    }

    console.log(_seatIds, "SEATS");
    if (remarks.length == 0) {
      alert("Please add remarks");
    } else if (_seatIds.length == 0) {
      alert("Please select atleast one seat");
    } else {
      setIsLoading(true);
      apiCall(
        WebApi.doBusSeatCancel,
        { tid: params.ticketId, seatIds: _seatIds, remarks: remarks },
        (response) => {
          setIsLoading(false);
          setOpen(false);
          if (response.success === true) {
            setInfoText(response.message);
            setIsSuccess(true);

            setTimeout(() => {
              props.target(props.searchParams);
            }, 5000);
          } else {
            setIsError(true);
            setInfoText(response.message);
          }
        }
      );
    }
  };

  const checkAllOnClick = () => {
    alert("Only full cancellation is allowed at this moment");
  };
  const checkOnClick = (SeatId) => {
    alert("Only full cancellation is allowed at this moment");
  };

  const handleClose = () => {
    setOpen(false);
    setPassengers([]);
  };

  const handleConfirmClose = () => {
    let _seatIds = [];
    let passenger = busData?.Itinerary?.Passenger;
    if (passenger) {
      passenger.map((value) => {
        // console.log(value?.Seat?.SeatName);
        if (value?.Seat?.SeatName) _seatIds.push(+value?.Seat?.SeatName);
        // _seatIds.push(1);
      });
    }

    if (remarks.length == 0) {
      alert("Please add remarks");
    } else if (_seatIds.length == 0) {
      alert("Please select atleast one seat");
    } else {
      setIsLoading(true);
      apiCall(
        WebApi.doBusSeatCancel,
        {
          tid: params.ticketId,
          seatIds: _seatIds,
          remarks: remarks,
          cancellationChargePerSeat: cancellationChargePerSeat,
        },
        (response) => {
          setIsLoading(false);
          setOpen(false);
          if (response.success) {
            setInfoText(
              response.message +
                " " +
                (response.SendChangeRequestResult
                  ? response.SendChangeRequestResult.NetRefund
                  : "")
            );
            setIsSuccess(true);

            setTimeout(() => {
              props.target(props.searchParams);
            }, 5000);
          } else {
            setIsError(true);
            setInfoText(response.message);
          }
        }
      );
    }
  };

  const cancelBtn = (e, data) => {
    let _passengers = [...data.passengerDetails];
    _passengers.map((value, key) => {
      value.checked = true;
    });
    setPassengers(_passengers);
    setCancellationPolicy(data.cancellationPolicy);
    setCancellationChargePerSeat(data.cancellationChargePerSeat);
    setTid(data.tid);
    setOpen(true);
    setBookingAmount(data.bookingAmount);
  };

  useEffect(() => {
    if (props.usertype === "G") {
      WebApi.getB2CBusList(
        { userType: props.usertype, refId: props.location.state.refId },
        (response) => {
          props.setB2CBusList(response.data);
        },
        (error) => {
          console.log("getB2CBusList error", error);
        }
      );
    } else {
      WebApi.getB2CBusList(
        { userType: props.usertype },
        (response) => {
          props.setB2CBusList(response.data);
        },
        (error) => {
          console.log("getB2CBusList error", error);
        }
      );
    }
  }, [props.usertype]);

  return (
    <>
      <Grid item md={12} xs={12} sm={12} className={classes.grid}>
        <Grid
          container
          //   key={index}
          item
          xs={12}
          md={12}
          style={{
            background: "#fff",
            // border: "1px solid #dcdcdc",
            borderRadius: "5px",
            boxShadow: "0px 0px 6px -1px #b3b3b3",
            marginBottom: 20,
          }}
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.grid1}
          >
            <Grid item container md={4} xs={6}>
              <Grid item>
                <i
                  className="fas fa-bus"
                  aria-hidden="true"
                  style={{ opacity: 0.5, fontSize: 22 }}
                ></i>
              </Grid>
              <Grid item>
                <Typography className={classes.typo1}>
                  Total Passenger(s):{" "}
                  {/* {bus.passengerDetails && bus.passengerDetails.length}{" "} */}
                  {busData?.Itinerary?.Passenger?.length}{" "}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container md={6} xs={6}>
              <Grid item>
                <Grid container>
                  <Typography className={classes.typo2}>
                    Departure Date:{" "}
                    {/* {bus.busDetails.deptDate &&
                          moment(bus.busDetails.deptDate).format("DD MMM'YY")} */}
                    {moment(busData?.Itinerary?.DepartureTime).format(
                      "DD MMM'YY"
                    )}
                  </Typography>
                  <Typography className={classes.typo3}>
                    PNR No:{" "}
                    {/* {bus.busDetails.pnrNumber && bus.busDetails.pnrNumber} */}
                    {busData?.Itinerary?.TravelOperatorPNR}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ padding: 15 }}>
            <Grid container item xs={12} md={8} className={classes.grid2}>
              <Grid
                container
                item
                xs={12}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={6}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    {/* <Grid
                          item
                          xs={6}
                          md={2}
                          style={{ textAlign: "center" }}
                        >
                          <img src={CarrierImage} width={28} />
                        </Grid> */}
                    <Grid item xs={12} md={12} style={{ textAlign: "left" }}>
                      <Typography className={classes.typo4}>
                        {busData?.Itinerary?.BusType}
                      </Typography>
                      {/* <Typography className={classes.typo5}>
                            Economy
                          </Typography> */}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={3} xs={6}>
                  <Typography className={classes.typo4}>
                    PNR : {busData?.Itinerary?.TravelOperatorPNR}
                  </Typography>
                  <Typography className={classes.typo5}>
                    {/* {bus.busDetails.isRefundable &&
                          bus.busDetails.isRefundable === "Y"
                            ? "Refundable"
                            : bus.busDetails.isRefundable === "N"
                            ? "Non Refundable"
                            : ""} */}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                xs={12}
                justifyContent="space-between"
                alignItems="center"
                style={{ marginTop: "15px" }}
              >
                <Grid item xs={5} md={3}>
                  <Typography className={classes.typo6}>
                    {/* {bus.busDetails.origin && bus.busDetails.origin} */}
                    {busData?.Itinerary?.Origin}
                  </Typography>
                  <Typography className={classes.typo7}>
                    {/* {bus.busDetails.deptDate &&
                            moment(bus.busDetails.deptDate).format("DD MMM'YY")} */}
                    {moment(busData?.Itinerary?.DepartureTime).format(
                      "DD MMM'YY"
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  {/* <i
                          className="fa fa-long-arrow-right"
                          aria-hidden="true"
                          style={{ fontSize: "35px", color: "#4e4c4c" }}
                        ></i> */}
                  <ArrowForwardIcon
                    style={{ fontSize: "35px", color: "#4e4c4c" }}
                  />
                </Grid>
                <Grid item xs={5} md={3}>
                  <Typography className={classes.typo6}>
                    {/* {bus.busDetails.destination &&
                            bus.busDetails.destination} */}
                    {busData?.Itinerary?.Destination}
                  </Typography>
                  <Typography className={classes.typo7}>
                    {/* {bus.busDetails.arrDate &&
                            moment(bus.busDetails.arrDate).format("DD MMM'YY")} */}
                    {moment(busData?.Itinerary?.ArrivalTime).format(
                      "DD MMM'YY"
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                xs={12}
                justifyContent="space-between"
                alignItems="center"
                style={{ marginTop: 15 }}
              >
                <Grid item md={3} xs={6}>
                  <Typography className={classes.typo8}>
                    {busData?.Itinerary?.Passenger[0].Title &&
                      busData?.Itinerary?.Passenger[0].FirstName +
                        " " +
                        busData?.Itinerary?.Passenger[0].LastName}
                  </Typography>
                </Grid>
                <Grid item md={3} xs={6}>
                  <Typography className={classes.typo8}>
                    Status
                    <span style={{ color: "#007bff" }}>
                      {/* {bus.status} */}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid continer item xs={12} md={4} style={{ paddingLeft: 15 }}>
              <Grid item xs={12}>
                <Button
                  className={classes.button}
                  style={{ width: "12rem" }}
                  // onClick={() => props.history.push("/b2c/faredetails")}
                >
                  Fare Details
                </Button>
              </Grid>

              {/* <Grid item xs={12} style={{ marginTop: 20 }}>
                    {hotel.roomDetails.details[0] &&
                    hotel.roomDetails.details[0].refundStatus ===
                      "Not Refunded" ? null : (
                      <Button
                        className={classes.button}
                        style={{ width: "12rem" }}
                      >
                        Check Refund Status
                      </Button>
                    )}
                  </Grid> */}
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{
              borderTop: "1px solid #9e9e9e",
              margin: "0px 15px",
              padding: "15px 0px",
            }}
          >
            <Grid
              item
              style={{ padding: "0px 10px", cursor: "pointer" }}
              onClick={() => {
                history.push(
                  "/admin/booking-report/bus/ticket/" + params.ticketId
                );
                // props.history.push("/print/busticket/" + bus.tid);
                // window.open(basename+"/print/busticket/" + bus.tid,  "_blank");
              }}
            >
              {/* <i
                      className="fa fa-ticket"
                      aria-hidden="true"
                      style={{
                        textAlign: "center",
                        fontSize: "19px",
                        paddingBottom: "7px",
                        color: "#6f6f6f",
                      }}
                    ></i> */}
              <PrintIcon
                style={{
                  textAlign: "center",
                  fontSize: "25px",
                  paddingBottom: "7px",
                  color: "#6f6f6f",
                }}
              />
              <Typography className={classes.typo9}>Print Ticket</Typography>
            </Grid>

            <Grid
              item
              style={{ padding: "0px 10px", cursor: "pointer" }}
              onClick={() => {
                // props.history.push("/print/busticket/" + bus.tid);
                // window.open(basename+"/print/busticket/" + bus.tid,  "_blank");
                history.push("/admin/voucher/bus-user/" + params.ticketId);
              }}
            >
              {/* <i
                      className="fa fa-download"
                      aria-hidden="true"
                      style={{
                        textAlign: "center",
                        fontSize: "19px",
                        paddingBottom: "7px",
                        color: "#6f6f6f",
                      }}
                    ></i> */}
              <ArrowDownwardIcon
                style={{
                  textAlign: "center",
                  fontSize: "25px",
                  paddingBottom: "7px",
                  color: "#6f6f6f",
                }}
              />
              <Typography className={classes.typo9}>Download Ticket</Typography>
            </Grid>
            <Grid
              item
              style={{ padding: "0px 10px", cursor: "pointer" }}
              onClick={() => {
                history.push("/admin/voucher/bus-agent/" + params.ticketId);
                // props.history.push("/print/voucher/bus-user/" + bus.tid);
                // window.open(basename+"/print/voucher/bus-user/" + bus.tid,  "_blank");
              }}
            >
              <DescriptionIcon
                style={{
                  textAlign: "center",
                  fontSize: "25px",
                  paddingBottom: "7px",
                  color: "#6f6f6f",
                }}
              />
              {/* <i
                      className="fa fa-sticky-note-o"
                      aria-hidden="true"
                      style={{
                        textAlign: "center",
                        fontSize: "19px",
                        paddingBottom: "7px",
                        color: "#6f6f6f",
                      }}
                    ></i> */}

              <Typography className={classes.typo9}>Print Invoice</Typography>
            </Grid>

            <Grid
              item
              style={{ padding: "0px 10px", cursor: "pointer" }}
              onClick={() => {
                setOpen(true);
              }}
              // onClick={(e)=>cancelBtn(e,bus)}
            >
              {/* <i
                      className="fas fa-ban"
                      aria-hidden="true"
                      style={{
                        textAlign: "center",
                        fontSize: "19px",
                        paddingBottom: "7px",
                        color: "#6f6f6f",
                      }}
                    ></i> */}
              <CancelIcon
                style={{
                  textAlign: "center",
                  fontSize: "25px",
                  paddingBottom: "7px",
                  color: "#6f6f6f",
                }}
              />
              <Typography className={classes.typo9}>Cancel</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogContent>
          <GridContainer>
            <GridItem md={5} style={{ display: "flex", alignItems: "center" }}>
              Passenger Name
            </GridItem>
            <GridItem md={2} style={{ display: "flex", alignItems: "center" }}>
              Seat No
            </GridItem>
            <GridItem md={2} style={{ display: "flex", alignItems: "center" }}>
              Price
            </GridItem>
            <GridItem
              md={3}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Checkbox
                color="primary"
                checked={allSeatSelected}
                onChange={checkAllOnClick}
              />
            </GridItem>
          </GridContainer>
          <Divider />
          {open &&
            busData?.Itinerary?.Passenger &&
            busData?.Itinerary?.Passenger.map((value, index) => {
              return (
                <GridContainer key={index}>
                  <GridItem
                    md={5}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {value.Title} {value.FirstName} {value.LastName}
                  </GridItem>
                  <GridItem
                    md={2}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {value.Seat?.SeatName}
                  </GridItem>
                  <GridItem
                    md={2}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    &#x20B9;{bookingAmount}
                  </GridItem>
                  <GridItem
                    md={3}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    {value.isCancelled ? (
                      <span>{"Cancelled"}</span>
                    ) : (
                      <Checkbox
                        color="primary"
                        checked={value.checked || false}
                        onClick={() => {
                          checkOnClick(value.SeatId);
                        }}
                      />
                    )}
                  </GridItem>
                </GridContainer>
              );
            })}
          <Divider />
          <GridContainer>
            <GridItem md={5} style={{ display: "flex", alignItems: "center" }}>
              Cancellation Remarks
            </GridItem>
            <GridItem md={6} style={{ display: "flex", alignItems: "center" }}>
              <TextField
                variant="outlined"
                placeholder={"Enter your remarks here"}
                onChange={(e) => setRemarks(e.target.value)}
                value={remarks}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem md={5} style={{ display: "flex", alignItems: "center" }}>
              Cancellation charge per seat
            </GridItem>
            <GridItem md={6} style={{ display: "flex", alignItems: "center" }}>
              <TextField
                variant="outlined"
                placeholder={"Enter your remarks here"}
                onChange={(e) => setCancellationChargePerSeat(e.target.value)}
                value={cancellationChargePerSeat}
              />
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 50,
                fontWeight: "bold",
              }}
            >
              Cancellation Policy
            </GridItem>
          </GridContainer>
          <Divider />
          <GridContainer>
            <GridItem md={2} style={{ display: "flex", alignItems: "center" }}>
              Charge
            </GridItem>
            <GridItem md={3} style={{ display: "flex", alignItems: "center" }}>
              Charge Type
            </GridItem>
            <GridItem md={7} style={{ display: "flex", alignItems: "center" }}>
              Policy
            </GridItem>
          </GridContainer>
          <Divider />
          {open &&
            cancellationPolicy &&
            cancellationPolicy.map((value, index) => {
              return (
                <GridContainer key={index}>
                  <GridItem
                    md={2}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {value.cancellationCharge}
                  </GridItem>
                  <GridItem
                    md={3}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {value.cancellationChargeTypeString}
                  </GridItem>
                  <GridItem
                    md={7}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {value.policyString} + &#8377;{cancellationChargePerSeat}{" "}
                    max handling charge
                  </GridItem>
                </GridContainer>
              );
            })}
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<CloseIcon />}
            onClick={handleClose}
            color="primary"
            disabled={isLoading}
          >
            Disagree
          </Button>
          <Button
            startIcon={
              isLoading ? <CircularProgress size="24px" /> : <DoneIcon />
            }
            onClick={handleConfirmClose}
            color="primary"
            autoFocus
            disabled={isLoading}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

function mapStateToProps(state, props) {
  return {
    busList: state.busList.busList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setB2CBusList: (busList) => dispatch(setB2CBusList(busList)),
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BusReportDetails)
);
