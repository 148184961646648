import React, { useState, useContext } from "react";
import {
  CircularProgress,
  Grid,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import WebApi from "api/ApiConstants";
import { useHistory, withRouter } from "react-router-dom";
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import {
  localforageSetItem,
  localforageClear,
  localforageGetItem,
  localforageSetDSAItem,
  localforageGetDSAItem,
  localforageClearDSA,
} from "oautils/oaForageUtils";
import user_icon from "assets/img/user_icon.png";
import email_icon from "assets/icons/envelope.svg";
import password_icon from "assets/icons/key.svg";
import OaFormTextField from "oahoc/OaStandardTextField";
import { Formik, Form, ErrorMessage } from "formik";
import company_logo from "assets/img/company_logo.png";
import { COLORS } from "assets/css/CssConstants";
import * as Yup from "yup";
import { apiCall } from "oautils/oaDataUtils";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { AppStateContext } from "layouts/AppStateProvider";
import OaFormAlerts from "pages/components/OaFormAlerts";
import AccountCircle from "@material-ui/icons/AccountCircle";

import { Flight as FlightReport } from "pages/report/flight/Flight";
import PublishIcon from "@material-ui/icons/Publish";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import AttachMoneyOutlinedIcon from "@material-ui/icons/AttachMoneyOutlined";
import FlightMarkup from "pages/management/commission-and-markup/flight/Markup";
import { MoneyTransfer } from "pages/report/moneytransfer/MoneyTransfer";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import { RechargeReport } from "pages/report/recharge/RechargeReport";
import RechargeCommissionAndCharge from "pages/module/recharge/commission/CommissionAndCharge";
import DirectionsBusIcon from "@material-ui/icons/DirectionsBus";
import { BusReport } from "pages/report/bus/BusReport";
import BusCommissionAndCharge from "pages/module/bus/commission/CommissionAndCharge";

import HotelIcon from "@material-ui/icons/Hotel";
import { HotelReport } from "pages/report/hotel/HotelReport";
import HotelCommissionAndCharge from "pages/module/hotel/commission/CommissionAndCharge";
import OaPopperAlert from "oahoc/OaPopperAlert";

import localforage from "localforage";
import OaPasswordToggleInput from "oahoc/OaPasswordToggleInput";

const LoginForm = (props) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const { routes, setRoutes } = useContext(AppStateContext);
  const [open, setOpen] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const classes = useStyles();
  const formikRef = React.useRef();

  const [severity, setSeverity] = React.useState({
    issuccess: false,
    iserror: false,
    iswarning: false,
    isinfo: false,
  });
  const [severitySnackbar, setSeveritySnackbar] = React.useState({
    issuccess: false,
    iserror: false,
    iswarning: false,
    isinfo: false,
  });

  const AgentLogin = (
    searchParams,
    { setSubmitting, resetForm, enableReinitialize }
  ) => {
    apiCall(WebApi.postLogin, searchParams, (response) => {
      setAlertTitle("Error logging");
      setSubmitting(false);
      setIsSaving(false);

      if (response != null && response.success === true) {
        setIsError(false);
        // setIsSuccess(true);
        setSeveritySnackbar({
          ...severitySnackbar,
          issuccess: true,
        });
        setAlertTitle("Success logging");
        setInfoText(response.message);

        localforageSetItem("user-id", response.data.userId);
        localforageSetItem("access-key", response.accesskey);
        localforageSetItem("user-details", response.data, () => {
          if (routes[1].views.length === 0) {
            const tempRoutes = [...routes];
            response.data.serviceOption.map((options) => {
              if (options.serviceValue === "flights") {
                tempRoutes[1].views.push({
                  path: "/booking-report/flight",
                  name: "Flight",
                  icon: PublishIcon,
                  component: FlightReport,
                  layout: "/admin",
                });
                tempRoutes[6].views.push({
                  path: "/markup/flight",
                  name: "Flight Markup",
                  icon: MoneyOffIcon,
                  component: FlightMarkup,
                  layout: "/admin",
                });
              } else if (options.serviceValue === "moneytransfer") {
                tempRoutes[1].views.push({
                  path: "/booking-report/moneytransfer",
                  name: "DMT",
                  icon: AttachMoneyOutlinedIcon,
                  component: MoneyTransfer,
                  layout: "/admin",
                });
              } else if (options.serviceValue === "recharge") {
                tempRoutes[1].views.push({
                  path: "/booking-report/recharge",
                  name: "Recharge",
                  icon: PhoneAndroidIcon,
                  component: RechargeReport,
                  layout: "/admin",
                });
                tempRoutes[6].views.push({
                  path: "/commission/recharge",
                  name: "Recharge Markup",
                  icon: PhoneAndroidIcon,
                  component: RechargeCommissionAndCharge,
                  layout: "/admin",
                });
              } else if (options.serviceValue === "buses") {
                tempRoutes[1].views.push({
                  path: "/booking-report/bus",
                  name: "Bus",
                  icon: DirectionsBusIcon,
                  component: BusReport,
                  layout: "/admin",
                });
                tempRoutes[6].views.push({
                  path: "/commission/bus",
                  name: "Bus Markup",
                  icon: DirectionsBusIcon,
                  component: BusCommissionAndCharge,
                  layout: "/admin",
                });
              } else if (options.serviceValue === "hotels") {
                tempRoutes[1].views.push({
                  path: "/booking-report/hotel",
                  name: "Hotel",
                  icon: HotelIcon,
                  component: HotelReport,
                  layout: "/admin",
                });
                tempRoutes[6].views.push({
                  path: "/commission/hotel",
                  name: "Hotel Markup",
                  icon: HotelIcon,
                  component: HotelCommissionAndCharge,
                  layout: "/admin",
                });
              }
            });
            setRoutes(tempRoutes);
          }
          props.history.push("/flights");
          window.location.href = "";
          // window.open("/flights");
        });
      } else if (response != null) {
        setAlertTitle("Error logging");
        setInfoText(response.message);
        // setIsError(true);
        setSeverity({
          ...severity,
          iserror: true,
        });
        setIsSuccess(false);
      }
    });
  };

  const DistributorLogin = (
    searchParams,
    { setSubmitting, resetForm, enableReinitialize }
  ) => {
    setIsSaving(true);
    apiCall(WebApi.postDSALogin, searchParams, (response) => {
      setAlertTitle("Error logging");
      setSubmitting(false);
      setIsSaving(false);

      if (response != null && response.success === true) {
        setIsError(false);
        setIsSuccess(true);
        setAlertTitle("Success logging");
        setInfoText(response.message);

        localforageSetDSAItem("user-id", response.data.userId);
        localforageSetDSAItem("access-key", response.accesskey);
        localforageSetDSAItem("user-details", response.data, () => {
          //server hosting
          //window.open("../dsa/admin","_self");
          //oari hosting
          window.open("../../dsa/admin", "_self");
        });
      } else if (response != null) {
        setAlertTitle("Error logging");
        setInfoText(response.message);
        setIsError(true);
        setIsSuccess(false);
      }
    });
  };

  const AdminLogin = (
    searchParams,
    { setSubmitting, resetForm, enableReinitialize }
  ) => {
    setIsSaving(true);
    apiCall(WebApi.postAdminLogin, searchParams, (response) => {
      setIsError(!response.success);
      setIsSuccess(response.success);
      setAlertTitle("Error logging");
      setSubmitting(false);
      setIsSaving(false);
      setInfoText(response.message);
      if (response.success === true) {
        setAlertTitle("Success logging");
        localforage.setItem("user-id", response.data.userId);
        localforage.setItem("access-key", response.accesskey);
        localforage.setItem("user-details", response.data).then(() => {
          //server hosting
          //window.open('../companyadmin/admin', "_self")
          //oari hosting
          window.open("../../companyadmin/admin", "_self");
        });
      }
    });
  };

  const onChangePassword = (event) => {
    if (formikRef.current) {
      formikRef.current.setFieldValue("password", event.target.value);
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{ username: "", password: "" }}
      validationSchema={Yup.object().shape({
        username: Yup.string().required("Username is required"),
        password: Yup.string().required("Password is required"),
      })}
      onSubmit={(
        searchParams,
        { setSubmitting, resetForm, enableReinitialize }
      ) => {
        setIsSaving(true);

        // console.log('current tab for value submit '+props.currentTab);
        if (props.currentTab === 0) {
          AgentLogin(searchParams, {
            setSubmitting,
            resetForm,
            enableReinitialize,
          });
        } else if (props.currentTab === 1) {
          DistributorLogin(searchParams, {
            setSubmitting,
            resetForm,
            enableReinitialize,
          });
        } else if (props.currentTab === 2) {
          AdminLogin(searchParams, {
            setSubmitting,
            resetForm,
            enableReinitialize,
          });
        }
      }}
    >
      {({ values, setFieldValue, enableReinitialize, resetForm }) => (
        <Form className={classes.loginFormRoot}>
          {/* <OaFormAlerts
                        isSaving={isSaving}
                        isSuccess={isSuccess}
                        isError={isError}
                        infoText={infoText}
                        setIsError={setIsError}
                        setIsSuccess={setIsSuccess}
                        alertTitle={alertTitle}
                    /> */}
          <OaPopperAlert
            alertmessage={infoText}
            severity={severity}
            setseverity={setSeverity}
          />

          <Grid item className="form-wrapper">
            <Grid container xs={12} className="login-form-row">
              <Grid className="login-form-input-wrap">
                <div className="input-icon">
                  <i className="fas fa-envelope"></i>
                </div>

                <div className="input-component">
                  <TextField
                    label="Email/Username/Mobile"
                    name="username"
                    fullWidth
                    value={values.username}
                    onChange={(event) =>
                      setFieldValue("username", event.target.value)
                    }
                  />
                </div>
              </Grid>

              <Grid className="login-input-error">
                <ErrorMessage
                  name="username"
                  component="div"
                  className="error"
                />
              </Grid>
            </Grid>

            <Grid container xs={12} className="login-form-row">
              <Grid className="login-form-input-wrap">
                <div className="input-icon">
                  <i className="fas fa-lock"></i>
                </div>

                <div className="input-component">
                  {/* <TextField
                                        label="Password"
                                        name="password"
                                        type="password"
                                        id="password"
                                        fullWidth
                                        value={values.password}
                                        onChange={event => setFieldValue("password", event.target.value)}
                                    /> */}
                  <OaPasswordToggleInput
                    name="password"
                    label="Password"
                    value={values.password}
                    onChange={onChangePassword}
                  />
                </div>
              </Grid>

              <Grid className="login-input-error">
                <ErrorMessage
                  name="password"
                  component="div"
                  className="error"
                />
              </Grid>
            </Grid>
            <Grid container xs={12} className={classes.loginHelp}>
              {/* <FormControlLabel
                                name="isPasswordVisible"
                                control={<Checkbox />}
                                label="Show Password"
                                onChange={(event) => {
                                    document.getElementById("password").type = event
                                        .target.checked
                                        ? "text"
                                        : "password";
                                }}
                                className={classes.showPasswordControl}
                            /> */}

              {props.currentTab != 2 && (
                <>
                  <Typography
                    onClick={() => setOpen(true)}
                    className="resend-password"
                  >
                    Resend Password?
                  </Typography>
                  <Dialog
                    fullWidth={true}
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setOpen(false)}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                  >
                    <DialogTitle id="alert-dialog-slide-title">
                      Forgot Password
                    </DialogTitle>
                    <DialogContent>
                      <Formik
                        initialValues={{ username: "" }}
                        validationSchema={Yup.object().shape({
                          username: Yup.string().required(
                            "Username is required"
                          ),
                        })}
                        onSubmit={(
                          searchParams,
                          { setSubmitting, resetForm, enableReinitialize }
                        ) => {
                          setIsSaving(true);
                          WebApi.doResendPassword(
                            searchParams,
                            (response) => {
                              setInfoText(response.message);
                              if (response.success) {
                                setSeverity({
                                  ...severity,
                                  issuccess: true,
                                });
                              } else {
                                setSeverity({
                                  ...severity,
                                  iserror: true,
                                });
                              }
                            },
                            (error) => {
                              console.log("resend password API error", error);
                              setInfoText("internal error!");
                              setSeverity({
                                ...severity,
                                iserror: true,
                              });
                            }
                          );
                        }}
                      >
                        {({
                          values,
                          setFieldValue,
                          enableReinitialize,
                          resetForm,
                        }) => (
                          <Form>
                            <OaFormTextField
                              style={{
                                marginTop: 20,
                                marginBottom: 10,
                              }}
                              label="Username"
                              name="username"
                              adornmentIcon={<></>}
                              adornmentDirection="right"
                              background={{
                                backgroundColor: "white",
                                boxShadow: "0px 2px 13px 0px rgb(0 0 0 / 7%)",
                                borderRadius: 10,
                              }}
                            />
                            <ErrorMessage
                              name="username"
                              component="div"
                              className="error"
                            />
                            <Button
                              type="submit"
                              style={{
                                backgroundColor:
                                  COLORS.HIGHLIGHT_COLOR_SECONDARY,
                                color: "white",
                              }}
                            >
                              Get New Password
                            </Button>
                          </Form>
                        )}
                      </Formik>
                    </DialogContent>
                  </Dialog>
                </>
              )}
            </Grid>
            <Grid container item xs={12} justifyContent="center">
              <Grid container item xs={12} className={classes.loginBtnGrid}>
                <Button type="submit" className={classes.loginButton}>
                  Login
                </Button>
              </Grid>

              {props.currentTab === 0 && (
                <Grid container item xs={12} className={classes.signupLinkWrap}>
                  <div className={classes.signupLink}>
                    New User?&nbsp;
                    <a href="/prelogin/registration">Sign Up</a>
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(LoginForm);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  loginFormRoot: {
    "& .form-wrapper": {
      padding: "0 25px",
    },
    "& .login-form-row": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      margin: "0 0 15px",
    },
    "& .login-form-input-wrap": {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      "& .input-icon": {
        width: "fit-content",
        padding: "20px 0 0",
        fontSize: 20,
        color: theme.palette.primary.darkFadedText,
        marginRight: 16,
      },
      "& .input-component": {
        flexGrow: 1,
      },
      "& .MuiInput-underline:before": {
        borderBottom: `solid 1px ${theme.palette.primary.disabled}`,
      },
      "& .MuiInput-underline:after": {
        borderBottom: `solid 2px ${theme.palette.secondary.main}`,
      },
      "& .MuiFormLabel-root": {
        fontWeight: 500,
        color: theme.palette.primary.darkGray,
      },
    },
  },
  showPasswordControl: {
    "& .MuiSvgIcon-root": {
      fontSize: "22px",
      color: theme.palette.primary.defaultText,
      [theme.breakpoints.down(446)]: {
        fontSize: 14,
      },
    },
    "& .MuiTypography-root": {
      fontSize: "14px",
      color: theme.palette.primary.defaultText,
      [theme.breakpoints.down(446)]: {
        fontSize: 11,
      },
    },
    "& .Mui-checked": {
      "& .MuiSvgIcon-root": {
        color: theme.palette.primary.warning,
      },
    },
  },
  loginHelp: {
    margin: "20px 0",
    "& .resend-password": {
      marginTop: 10,
      color: theme.palette.primary.warning,
      marginLeft: "auto",
      cursor: "pointer",
      fontSize: "14px",
      [theme.breakpoints.down(446)]: {
        fontSize: 11,
      },
    },
  },
  loginButton: {
    // backgroundColor: theme.palette.primary.main,
    // backgroundColor: "#ef6614",
    background: theme.palette.buttons.primary,
    color: COLORS.DEFAULT_TEXT_LIGHT,
    width: 140,
    [theme.breakpoints.down(1024)]: {
      marginLeft: "0",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.sub,
      boxShadow:
        "0 2px 2px 0 rgb(244 67 54 / 14%), 0 3px 1px -2px rgb(244 67 54 / 20%), 0 1px 5px 0 rgb(244 67 54 / 12%)",
    },
  },
  loginBtnGrid: {
    justifyContent: "center",
    marginBottom: 10,
  },
  signupLink: {
    textAlign: "center",
    whiteSpace: "nowrap",
    color: "#000000",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12.592px",
    lineHeight: "15px",
    "& a": {
      color: "#2174ed",
      cursor: "pointer",
    },
    [theme.breakpoints.down(1024)]: {
      margin: "35px 0 !important",
      width: "100%",
    },
  },
  signupLinkWrap: {
    justifyContent: "center",
    "& a": {
      cursor: "pointer",
      color: theme.palette.primary.warning,
      marginLeft: 13,
    },
  },
}));
