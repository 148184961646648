import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Box,
  makeStyles,
  Grid,
  TextField,
  Button,
  Fade,
  Backdrop,
  Modal,
  withWidth,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import OaOutlinedDatePicker from "oahoc/OaOutlinedDatepicker";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import HotelLocationCountryInput from "../Tickat/forms/formControls/HotelLocationCountryInput";
import HotelLocationCityInput from "../Tickat/forms/formControls/HotelLocationCityInput";
import HotelDateInput from "../Tickat/forms/formControls/HotelDateInput";

import RoomModal from "./RoomModal";

import OaFormAlerts from "pages/components/OaFormAlerts";
import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";

import * as Yup from "yup";
import { Formik, Form, ErrorMessage, FieldArray } from "formik";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  setHotelCountry,
  setHotelCity,
  setHotelSearch,
  setHotelBookingInitialTime,
} from "redux/action";
import _ from "lodash";
import differenceInDays from "date-fns/differenceInDays";
import dateFnsFormat from "date-fns/format";
import { AppStateContext } from "layouts/AppStateProvider";
import { COLORS, FONTS } from "assets/css/CssConstants";
import { localforageGetItem } from "oautils/oaForageUtils";

export class RoomObj {
  constructor() {
    this.noOfAdults = 1;
    this.noOfChild = 0;
    this.childAge = [];
  }
}

function MobileHotelSection(props) {
  const classes = useStyles();

  const locationAutocompleteRef = useRef(null);
  const [departureDateDialogOpen, setDepartureDateDialogOpen] = useState(false);
  const [arrivalDateDialogOpen, setArrivalDateDialogOpen] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [childError, setChildError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [roomModalOpen, setRoomModalOpen] = useState(false);
  const formikRef = React.useRef();
  const { setSecLogo } = useContext(AppStateContext);
  const [refreshLocation, setRefreshLocation] = useState(false);
  const [userType, setUserType] = useState(null);
  const [corporateType, setCorporateType] = useState(null);

  const history = useHistory();

  let baseUrl = "/";

  let optionsHotel = [
    {
      name: "searchHotel",
      label: <Typography className={classes.typo1}>Search Hotel</Typography>,
      url: baseUrl + "hotels",
      icon: <i className="fas fa-search"></i>,
    },
    {
      name: "hotelReport",
      label: <Typography className={classes.typo1}>Hotel Report</Typography>,
      url: "/admin/booking-report/hotel",
      icon: <i className="fas fa-dollar-sign"></i>,
    },
  ];

  const popularCities = [
    {
      cityName: "Delhi",
      countryCode: "IN",
      countryName: "India",
      destinationId: "130443",
      hotelName: null,
      stateProvince: "DL",
      type: null,
    },
    {
      cityName: "Navi Mumbai",
      countryCode: "IN",
      countryName: "India",
      destinationId: "128734",
      hotelName: null,
      stateProvince: "MH",
      type: null,
    },
    {
      cityName: "Bengaluru",
      countryCode: "IN",
      countryName: "India",
      destinationId: "111124",
      hotelName: null,
      stateProvince: "KA",
      type: null,
    },
    {
      cityName: "Goa",
      countryCode: "IN",
      countryName: "India",
      destinationId: "119805",
      hotelName: null,
      stateProvince: "GA",
      type: null,
    },
    {
      cityName: "Chennai",
      countryCode: "IN",
      countryName: "India",
      destinationId: "127343",
      hotelName: null,
      stateProvince: "TN",
      type: null,
    },
    {
      cityName: "Jaipur",
      countryCode: "IN",
      countryName: "India",
      destinationId: "122175",
      hotelName: null,
      stateProvince: "RJ",
      type: null,
    },
  ];

  useEffect(() => {
    localforageGetItem("user-details", function(err, details) {
      if (details != null) {
        setUserType(details.userType);
        setCorporateType(details.corporateType);
      }
    });
    props.setHotelCity(popularCities);
  }, []);

  /* useEffect(() => {
    if (isLoading) {
      // console.log(0);
      const length = props.hotelCountry
        ? Object.keys(props.hotelCountry).length
        : 0;

      const lengthCity = props.hotelCity
        ? Object.keys(props.hotelCity).length
        : 0;

      if (length > 0 && lengthCity > 0) {
        const countryCodeObj = {
          name: props.hotelCountry?.filter(
            (country) => country.code == props.hotelCity[0].countryCode
          )[0].name,
          code: props.hotelCity[0].countryCode,
        };

        formikRef.current.setFieldValue("countryCode", countryCodeObj);
        formikRef.current.setFieldValue("guestNationality", {
          name: "India",
          code: "IN",
        });

        setIsLoading(false);
      } else {
        // console.log(1);
        apiCall(WebApi.getHotelCountry, {}, (response) => {
          if (response.success === true) {
            setIsLoading(false);
            props.setHotelCountry(response.data.countryList.country);
            // setHotelCountry(response.data.countryList.country);
            formikRef.current.setFieldValue("countryCode", {
              name: "India",
              code: "IN",
            });
            formikRef.current.setFieldValue("guestNationality", {
              name: "India",
              code: "IN",
            });
            cityHotelChangeHandler(1, {
              name: "India",
              code: "IN",
            });
          } else {
            setIsError(!response.success);
            setInfoText(response.message);
          }
        });
      }
    }
  }, [isLoading]); */

  useEffect(() => {
    if (isLoading) {
      // console.log(0);
      const length = props.hotelCountry
        ? Object.keys(props.hotelCountry).length
        : 0;

      const lengthCity = props.hotelCity
        ? Object.keys(props.hotelCity).length
        : 0;

      if (length > 0 && lengthCity > 0) {
        const countryCodeObj = {
          name: props.hotelCountry?.filter(
            (country) => country.code == props.hotelCity[0].countryCode
          )[0].name,
          code: props.hotelCity[0].countryCode,
        };

        formikRef.current.setFieldValue("countryCode", countryCodeObj.code);
        formikRef.current.setFieldValue("countryCodeObj", countryCodeObj);
        formikRef.current.setFieldValue("guestNationality", "IN");
        formikRef.current.setFieldValue("guestNationalityObj", countryCodeObj);

        setIsLoading(false);
      } else {
        // console.log(1);
        WebApi.getHotelCountry(
          {},
          (response) => {
            if (response.success === true) {
              setIsLoading(false);
              props.setHotelCountry(response.data.countryList.country);

              cityHotelChangeHandler(1, {
                name: "India",
                code: response.data.countryList.country, //"IN",
              });
            } else {
              setIsError(!response.success);
              setInfoText(response.message);
            }
          },
          (error) => console.log("getHotelCountry error", error)
        );
      }
    }
  }, [isLoading]);

  const cityHotelChangeHandler = (searchType, obj) => {
    let countryCode = "";
    if (obj != null) {
      countryCode = obj.code;
    } else {
      const countryCodeObj = formikRef.current.values.countryCode;
      countryCode = countryCodeObj?.code;
    }
  };

  const fetchMatchingCities = (searchType, searchParam, countryCode) => {
    if (searchParam.length < 3) {
      return;
    }

    apiCall(
      WebApi.getMatchingHotelCity,
      {
        searchType: searchType,
        countryCode: countryCode.code || "IN",
        hotelCity: searchParam,
      },
      (response) => {
        if (response.success === true) {
          // console.log("hotel city search", searchParam);
          // console.log("matching cities response", response);
          props.setHotelCity(response.data.destinations);
        } else {
          setIsError(!response.success);
          setInfoText(response.message);
        }
      }
    );
  };

  const openQuickLink = (url) => {
    // console.log("quicklink", url);
    history.push({
      pathname: url,
    });
  };

  const validateChildAge = (rooms) => {
    return rooms.every((room) => {
      if (room.noOfChild > 0) {
        return room.childAge.every((age) => age > 0);
      } else return true;
    });
  };

  return (
    <Box
      className={classes.root}
      // style={{ overflow: roomModalOpen && "hidden" }}
    >
      <OaFormAlerts
        isSaving={isSaving}
        isSuccess={isSuccess}
        isError={isError}
        infoText={infoText}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
      />
      <Formik
        innerRef={formikRef}
        /* initialValues={{
          countryCode: {
            name: "India",
            code: "IN",
          },
          // cityId: "",
          countryCode: "IN",
          cityObj: popularCities[0],
          cityId: popularCities[0].destinationId,
          // checkInDate: new Date(),
          // checkOutDate: new Date(new Date().setDate(new Date().getDate() + 1)),
          checkInDate: dateFnsFormat(new Date(), "dd/MM/yyyy"),
          checkInDateDisplay: new Date(),
          checkOutDate: dateFnsFormat(
            new Date(new Date().setDate(new Date().getDate() + 1)),
            "dd/MM/yyyy"
          ),
          checkOutDateDisplay: new Date(
            new Date().setDate(new Date().getDate() + 1)
          ),
          preferredCurrency: "INR",
          roomGuests: [new RoomObj()],
          guestNationality: {
            name: "India",
            code: "IN",
          },
        }} */
        initialValues={{
          countryCodeObj: {
            name: "India",
            code: "IN",
          },
          countryCode: "IN",
          cityObj: popularCities[0],
          cityId: popularCities[0].destinationId,
          checkInDate: dateFnsFormat(new Date(), "dd/MM/yyyy"),
          checkInDateDisplay: new Date(),
          isIslandHopper: "false",
          checkOutDate: dateFnsFormat(
            new Date(new Date().setDate(new Date().getDate() + 1)),
            "dd/MM/yyyy"
          ),
          checkOutDateDisplay: new Date(
            new Date().setDate(new Date().getDate() + 1)
          ),

          preferredCurrency: "INR",
          roomGuests: [new RoomObj()],
          guestNationality: "IN",
          guestNationalityObj: {
            name: "India",
            code: "IN",
          },
        }}
        validationSchema={Yup.object().shape({
          countryCode: Yup.string().required("country Field is Required"),
          cityId: Yup.string().required("Location Field is Required"),
          checkInDate: Yup.string().required("Check In Date Field is Required"),
          checkOutDate: Yup.string().required(
            "Check Out Date Field is Required"
          ),
          // preferredCurrency: Yup.string().required(
          //   "Preferred Currency Field is Required"
          // ),
          guestNationality: Yup.string().required(
            "Guest Nationality Field is Required"
          ),
        })}
        onSubmit={(values) => {
          /* if (validateChildAge(values.roomGuests)) {
            setChildError(false);
            values.countryCode = values.countryCode.code;
            const cityObj = values.cityId;
            values.cityId = cityObj.destinationId;
            values.city = cityObj.cityName;
            values.guestNationality = values.guestNationality.code;

            values.noOfNights = differenceInDays(
              values.checkOutDate,
              values.checkInDate
            );
            values.noOfNights = values.noOfNights > 0 ? values.noOfNights : 1;
            // values.checkInDate = dateFnsFormat(
            //   values.checkInDate,
            //   "dd/MM/yyyy"
            // );
            // values.checkOutDate = dateFnsFormat(
            //   values.checkOutDate,
            //   "dd/MM/yyyy"
            // );
            values.noOfRooms = values.roomGuests.length;

            //  searchParams.resultCount = 10;
            values.maxRating = 5;
            values.minRating = 0;
            values.isTBOMapped = true;
            //  searchParams.isTBOMapped = 1;
            //  searchParams.isCompactdata = 1;

            props.setHotelSearch(values);
            setSecLogo(true);
            history.push("/hotel/search");
          } else {
            setChildError(true);
          } */

          if (validateChildAge(values.roomGuests)) {
            setChildError(false);
            console.log(values);
            values.city = values.cityObj.cityName;

            values.noOfNights = differenceInDays(
              values.checkOutDateDisplay,
              values.checkInDateDisplay
            );
            values.noOfNights = values.noOfNights > 0 ? values.noOfNights : 1;

            values.noOfRooms = values.roomGuests.length;

            values.maxRating = 5;
            values.minRating = 0;
            values.isTBOMapped = true;

            props.setHotelSearch(values);
            props.setHotelBookingInitialTime(null);
            window.sessionStorage.removeItem("service");

            history.replace("/hotel/search");
            window.location.reload();
          } else {
            setChildError(true);
          }
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form className={classes.root1}>
            <Grid
              container
              className={classes.FlightSectionContainer}
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
            >
              <Box className="">
                <Box className={classes.HotelSection_itinDet}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    // spacing={2}
                  >
                    <Grid
                      container
                      style={{
                        //padding: "0 16px",
                        margin: "16px 0",
                        justifyContent: "space-between",
                        position: "relative",
                      }}
                    >
                      <Grid item sm={6} className="location-input">
                        <label>Country</label>
                        {/*<Autocomplete
                          name={`countryCode`}
                          options={props.hotelCountry || []}
                          getOptionLabel={(a) => a.name}
                          value={values.countryCode}
                          getOptionSelected={(option, value) =>
                            option.value == value.code
                          }
                          onChange={
                            (_, newValue) => {
                              setFieldValue("countryCode", newValue);
                              cityHotelChangeHandler(1, newValue);
                              props.setHotelCity([]);
                              setFieldValue("cityId", "");
                            }
                            // setData((prevState) => ({
                            //   ...prevState,
                            //   country: newValue,
                            // }))
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              color="secondary"
                              // label="Country"
                            />
                          )}
                          disablePortal={true}
                          />*/}
                        {/* <Autocomplete
                          name={`countryCodeObj`}
                          options={props.hotelCountry || []}
                          getOptionLabel={(a) => a.name || ""}
                          value={values.countryCodeObj}
                          getOptionSelected={(option, value) =>
                            option.value == value.code
                          }
                          disableClearable
                          onChange={
                            (_, newValue) => {
                              setFieldValue("countryCodeObj", newValue);
                              setFieldValue("countryCode", newValue.code);
                              cityHotelChangeHandler(1, newValue);
                              props.setHotelCity([]);
                              setFieldValue("cityId", "");
                              setFieldValue("guestNationalityObj", newValue);
                              //  setFieldValue("guestNationality", newValue.code);

                              WebApi.getHotelCityListForCountry(
                                {
                                  clientId: "",
                                  countryCode: newValue.code,
                                  endUserIp: "",
                                  hotelCity: "",
                                  searchType: "",
                                  tokenId: "",
                                },

                                (response) => {
                                  if (response.success === true) {
                                    // console.log(response.data.destinations);
                                    props.setHotelCity(
                                      response?.data?.destinations ?? []
                                    );

                                    setFieldValue(
                                      "cityObj",
                                      response.data.destinations[0]
                                    );
                                    setFieldValue(
                                      "cityId",
                                      response.data.destinations[0]
                                        .destinationId
                                    );
                                    setIsLoading(false);
                                  } else {
                                  }
                                },
                                (error) =>
                                  console.log("getHotelCountry error", error)
                              );
                            }
                            // setData((prevState) => ({
                            //   ...prevState,
                            //   country: newValue,
                            // }))
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              color="secondary"
                              // label="Country"
                            />
                          )}
                          disablePortal={true}
                        /> */}
        <HotelLocationCountryInput setFieldValue={setFieldValue}  props={{props, values}} formikRef={formikRef} />


                        <ErrorMessage
                          name={`countryCode`}
                          component="div"
                          className="error"
                        />
                      </Grid>
                      <Grid item sm={6} className="location-input">
                        <label>Location</label>
                        {/* <Autocomplete
                          name={`cityId`}
                          options={props.hotelCity || []}
                          getOptionLabel={(a) => a && a.cityName}
                          value={values.cityId}
                          getOptionSelected={(option, value) =>
                            option.value == value.destinationId
                          }
                          onKeyUp={(event) =>
                            fetchMatchingCities(
                              1,
                              event.target.value,
                              values.countryCode
                            )
                          }
                          onChange={(_, newValue) => {
                            setFieldValue("cityId", newValue);
                            // setDepartureDateDialogOpen(true);
                            // setData((prevState) => ({
                            //   ...prevState,
                            //   location: newValue,
                            // }))
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              color="secondary"
                              // label="Location"
                              inputRef={locationAutocompleteRef}
                              placeholder="Enter City"
                              inputProps={{
                                ...params.inputProps,
                                autocomplete: "new-password",
                              }}
                            />
                          )}
                          openOnFocus
                          disablePortal={true}
                        /> */}
                        {/* <Autocomplete
                          name={`cityObj`}
                          options={props.hotelCity || []}
                          getOptionLabel={(a) => (a && a.cityName) || ""}
                          value={values.cityObj}
                          getOptionSelected={(option, value) =>
                            option.value == value.destinationId
                          }
                          onKeyUp={(event) =>
                            fetchMatchingCities(
                              1,
                              event.target.value,
                              values.countryCode
                            )
                          }
                          onChange={(_, newValue) => {
                            setFieldValue("cityObj", newValue);
                            setFieldValue("cityId", newValue.destinationId);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              color="secondary"
                              // label="Location"
                              inputRef={locationAutocompleteRef}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                              placeholder="Enter City"
                            />
                          )}
                        /> */}
                         <HotelLocationCityInput setFieldValue={setFieldValue}  props={{props, values}} formikRef={formikRef} />

                        <ErrorMessage
                          name={`cityId`}
                          component="div"
                          className="error"
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      style={{
                        // padding: "0 16px",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item sm={5} className="date-input check-in-date">
                        <label>Check In Date</label>
                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            name="checkInDate"
                            className={classes.checkinDatePicker}
                            fullWidth
                            disablePast
                            color="secondary"
                            disableToolbar
                            variant="inline"
                            format="dd-MM-yyyy"
                            margin="normal"
                            // label="Check In Date"
                            value={values.checkInDate}
                            onChange={(e, v) => {
                              setFieldValue("checkInDate", e);
                              setDepartureDateDialogOpen(false);
                              setArrivalDateDialogOpen(true);
                            }}
                            KeyboardButtonProps={{
                              onFocus: (e) => {
                                setDepartureDateDialogOpen(true);
                              },
                              style: { display: "none" },
                            }}
                            PopoverProps={{
                              disableRestoreFocus: true,
                              onClose: () => {
                                setDepartureDateDialogOpen(false);
                              },
                            }}
                            InputProps={{
                              onFocus: () => {
                                setDepartureDateDialogOpen(true);
                              },
                            }}
                            open={departureDateDialogOpen}
                            onClose={() => setDepartureDateDialogOpen(false)}
                            onOpen={() => setDepartureDateDialogOpen(true)}
                          />
                        </MuiPickersUtilsProvider> */}
                        {/* <OaOutlinedDatePicker
                          showDisabledMonthNavigation
                          name={`checkInDateDisplay`}
                          minDate={new Date()}
                          customInput={
                            <TextField
                              id="standard-basic"
                              variant="standard"
                              fullWidth
                            />
                          }
                          selected={values.checkInDateDisplay}
                          onChange={(e, v) => {
                            setFieldValue("checkInDateDisplay", e);
                            setFieldValue(
                              "checkInDate",
                              dateFnsFormat(e, "dd/MM/yyyy")
                            );
                            setFieldValue(
                              "checkOutDateDisplay",
                              new Date(e.getTime() + 86400000)
                            );
                            setFieldValue(
                              "checkOutDate",
                              dateFnsFormat(
                                new Date(e.getTime() + 86400000),
                                "dd/MM/yyyy"
                              )
                            );
                            setDepartureDateDialogOpen(false);
                            setArrivalDateDialogOpen(true);
                          }}
                          monthsShown={1}
                          dateFormat="dd-MM-yyyy"
                          type="button"
                        /> */}
                           <HotelDateInput type="checkIn" values={values} setFieldValue={setFieldValue} setDepartureDateDialogOpen={setDepartureDateDialogOpen} setArrivalDateDialogOpen={setArrivalDateDialogOpen} />

                        <ErrorMessage
                          name={`checkInDate`}
                          component="div"
                          className="error"
                        />
                      </Grid>
                      {/* <Grid item sm={1}></Grid> */}

                      <Grid item sm={5} className="date-input check-out-date">
                        <label>Check Out Date</label>
                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            name="checkOutDate"
                            className={classes.checkoutDatePicker}
                            fullWidth
                            minDate={
                              new Date(
                                new Date(values.checkInDate).getTime() +
                                  86400000
                              )
                            }
                            color="secondary"
                            disableToolbar
                            variant="inline"
                            format="dd-MM-yyyy"
                            margin="normal"
                            // label="Check Out Date"
                            value={values.checkOutDate}
                            onChange={(e, v) => {
                              setFieldValue("checkOutDate", e);
                              setArrivalDateDialogOpen(false);
                            }}
                            KeyboardButtonProps={{
                              onFocus: (e) => {
                                setArrivalDateDialogOpen(true);
                              },
                              style: { display: "none" },
                            }}
                            PopoverProps={{
                              disableRestoreFocus: true,
                              onClose: () => {
                                setArrivalDateDialogOpen(false);
                              },
                            }}
                            InputProps={{
                              onFocus: () => {
                                setArrivalDateDialogOpen(true);
                              },
                            }}
                            open={arrivalDateDialogOpen}
                            onClose={() => setArrivalDateDialogOpen(false)}
                            onOpen={() => setArrivalDateDialogOpen(true)}
                          />
                        </MuiPickersUtilsProvider> */}
                        {/* <OaOutlinedDatePicker
                          showDisabledMonthNavigation
                          name={`checkOutDateDisplay`}
                          minDate={values.checkInDateDisplay}
                          customInput={
                            <TextField
                              id="standard-basic"
                              variant="standard"
                              fullWidth
                            />
                          }
                          selected={values.checkOutDateDisplay}
                          onChange={(e, v) => {
                            setFieldValue("checkOutDateDisplay", e);
                            setFieldValue(
                              "checkOutDate",
                              dateFnsFormat(e, "dd/MM/yyyy")
                            );
                            setArrivalDateDialogOpen(false);
                          }}
                          monthsShown={1}
                          dateFormat="dd-MM-yyyy"
                        /> */}
                             <HotelDateInput type="checkOut" values={values} setFieldValue={setFieldValue} setDepartureDateDialogOpen={setDepartureDateDialogOpen} setArrivalDateDialogOpen={setArrivalDateDialogOpen} />

                        <ErrorMessage
                          name={`checkOutDate`}
                          component="div"
                          className="error"
                        />
                      </Grid>
                    </Grid>

                    <Grid item sm={12} className="room-input">
                      <label>Rooms</label>
                      <TextField
                        // label="Rooms"
                        color="secondary"
                        value={`${values.roomGuests.length} ${
                          values.roomGuests.length == 1 ? "Room" : "Rooms"
                        },  ${values.roomGuests.reduce(function(a, b) {
                          return (
                            Number(a) +
                            Number(b.noOfAdults) +
                            Number(b.noOfChild)
                          );
                        }, 0)} Guests`}
                        fullWidth
                        onClick={() => setRoomModalOpen(true)}
                      />

                      {roomModalOpen && (
                        <div className={classes.RoomModalOpenDev}>
                          <div className={classes.paper}>
                            <RoomModal
                              values={values}
                              setFieldValue={setFieldValue}
                              rooms={values.roomGuests}
                              childError={childError}
                              setChildError={setChildError}
                              closeModal={() => {
                                if (validateChildAge(values.roomGuests)) {
                                  setChildError(false);
                                  setRoomModalOpen(false);
                                } else {
                                  setChildError(true);
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </Grid>

                    <Grid
                      item
                      sm={12}
                      style={{ flexBasis: "100%", margin: "10px 0px" }}
                    >
                      <Box className={classes.HotelSection_actions}>
                        <Box className={classes.searchButtonBox}>
                          <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            <i className="fas fa-search"></i>
                            Search
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              {userType &&
                userType == "agent" &&
                corporateType &&
                corporateType != "corporate" && (
                  <Box className={classes.HotelSection_quicklinks}>
                    {optionsHotel &&
                      optionsHotel.map((val, ind) => (
                        <div
                          item
                          className="quick-link-item"
                          key={ind}
                          onClick={() => openQuickLink(val.url)}
                        >
                          <div className="qlink-icon-wrapper">{val.icon}</div>
                          <div className="qlink-label-wrapper">{val.label}</div>
                        </div>
                      ))}
                  </Box>
                )}
            </Grid>

            {/* <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.roomModal}
              open={roomModalOpen}
              onClose={() => setRoomModalOpen(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={roomModalOpen}>
                <div className={classes.paper}>
                  <RoomModal
                    values={values}
                    setFieldValue={setFieldValue}
                    rooms={values.roomGuests}
                    closeModal={() => setRoomModalOpen(false)}
                  />
                </div>
              </Fade>
            </Modal> */}

            {/* {console.log("values: ", values)}
            {errors && _.isString(errors) && touched && _.isArray(touched) && (
              <div className="field-error">{errors}</div>
            )}
            <div
              className={"row"}
              style={{ background: "white", zIndex: "200" }}
            >
              <div className={"col-12"}>
                <code>
                  <pre>Values: {JSON.stringify(values, null, 2)}</pre>
                </code>
              </div>
              <div className={"col-12"}>
                <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
              </div>
              <div className={"col-12"}>
                <pre>Touched: {JSON.stringify(touched, null, 2)}</pre>
              </div>
            </div> */}
          </Form>
        )}
      </Formik>
    </Box>
  );
}

function mapStateToProps(state, props) {
  return {
    hotelCountry: state.hotel.hotelCountry,
    hotelCity: state.hotel.hotelCity,
    hotelSearch: state.hotel.hotelSearch,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setHotelCountry: (hotelCountry) => dispatch(setHotelCountry(hotelCountry)),
    setHotelCity: (hotelCity) => dispatch(setHotelCity(hotelCity)),
    setHotelSearch: (hotelSearch) => dispatch(setHotelSearch(hotelSearch)),
    setHotelBookingInitialTime: (hotelBookingInitialTime) =>
      dispatch(setHotelBookingInitialTime(hotelBookingInitialTime)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withWidth()(MobileHotelSection));

const countryList = [
  { title: "India", value: "india" },
  { title: "Sweden", value: "sweden" },
  { title: "Japan", value: "japan" },
  { title: "Indonesia", value: "indonesia" },
];
const locationList = [
  { title: "Mumbai", value: "mumbai" },
  { title: "Chennai", value: "chennai" },
  { title: "Kolkata", value: "kolkata" },
  { title: "Delhi", value: "delhi" },
];
const nationalityList = [
  { title: "Indian", value: "indian" },
  { title: "American", value: "american" },
  { title: "European", value: "european" },
  { title: "African", value: "african" },
];
const DefChildCount = 6;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0",
    "& .hotel-citylocation-input": {
      background: "transparent",
      paddingLeft: 0,
      paddingTop: 2,
      paddingBottom: 2,
      height: 35,
    },

    "& .date-input-container": {
      background: "transparent",
      padding: 0,
      height: 35,
      "& .MuiInput-input": {
        fontWeight  : 400
      }
    },
    "& .adornment-icon": {
      width: 0,
    },
    "& .MuiFormLabel-root": {
      color: theme.palette.primary.defaultText,
    },
    "& .MuiInputBase-input": {
      color: theme.palette.primary.defaultText,
    },
    // "& .MuiSvgIcon-root": {
    //   color: theme.palette.primary.defaultText,
    // },

    "& .MuiGrid-item.MuiGrid-grid-xs-10": {
      margin: "auto",
    },
    "& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover, .MuiInput-underline:focus": {
      borderBottom: "none",
    },
    "& .MuiAutocomplete-popupIndicator": {
      display: "none",
    },
    // autocomplete popper style
    "& .MuiAutocomplete-popper": {
      position: "absolute",
      width: "calc(100vw - 72px) !important",
      left: 15,

      "& .MuiAutocomplete-paper": {
        "& .MuiAutocomplete-listbox": {
          width: "100% !important",
        },
      },
    },
    "& .MuiAutocomplete-inputRoot": {
      paddingRight: "0 !important",
    },
    "& .hotel-section-root-box": {
      "& label": {
        fontSize: 12,
        fontFamily: theme.palette.font.primary,
        color: theme.palette.primary.defaultText,
        textTransform: "uppercase",
        fontWeight: 500,
      },
      minWidth: 860,
      // padding:'10px 0',
      background: "#fff",
      borderRadius: "4px",
      // boxShadow: "4px 4px 4px #c2d87e",
      [theme.breakpoints.down(960)]: {
        minWidth: "100%",
      },
      "& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover, .MuiInput-underline:focus": {
        borderBottom: "none",
      },
    },
    "& .MuiGrid-item.MuiGrid-grid-xs-10": {
      [theme.breakpoints.down(440)]: {
        margin: 0,
        maxWidth: "100%",
        flexBasis: "100%",
      },
    },
  },
  root1: {
    // padding:'6px 16px',
  },

  roomModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.light,
    borderRadius: "8px",
  },

  HotelSection_actions: {
    width: "100%",
  },
  searchButtonBox: {
    width: "100%",
    height: "60px",
    "& .MuiButton-root": {
      width: "100%",
      //   marginLeft: "5px",
      height: "100%",
      borderRadius: "4px",
      backgroundColor: theme.palette.secondary.main,
      "& .MuiButton-label": {
        color: theme.palette.primary.lightText,
      },
    },
    "& i": {
      marginRight: "10px",
    },
  },
  HotelSection_itinDet: {
    "& .location-input": {
      // position: "relative",
      maxWidth: "48%",
      flexBasis: "48%",
      padding: "3px 10px",
      borderRadius: "4px",
      background: theme.palette.background.mobileSearch,
      border: `1px solid ${theme.palette.background.mobileSearchBorder}`,
    },
    "& .date-input": {
      maxWidth: "48%",
      flexBasis: "48%",
      padding: "3px 10px",
      borderRadius: "4px",
      background: theme.palette.background.mobileSearch,
      border: `1px solid ${theme.palette.background.mobileSearchBorder}`,
      "& .MuiSvgIcon-root": {
        // color:theme.palette.secondary.darkOrange,
        color: theme.palette.secondary.darkText,
      },
      "& .oa-form-element-card": {
        border: "none",
        paddingLeft: 0,
        paddingRight: 0,
      },
      "& .react-datepicker-popper": {
        width: "85%",
        // inset: "0 auto auto -10px !important",
        "& .react-datepicker": {
          width: "100%",
          "& .react-datepicker__day": {
            margin: "0.166rem 0.3rem",
          },
        },
      },
    },
    "& .check-in-date": {
      "& .react-datepicker-popper": { inset: "0 auto auto -10px !important" },
    },
    "& .check-out-date": {
      "& .react-datepicker-popper": { inset: "0 -10px auto auto !important" },
    },
    "& .room-input": {
      maxWidth: "100%",
      flexBasis: "100%",
      padding: "6px 10px",
      margin: "10px 0px",
      borderRadius: "4px",
      background: theme.palette.background.mobileSearch,
      border: `1px solid ${theme.palette.background.mobileSearchBorder}`,
    },
  },
  HotelSection_idenDet: {
    borderTop: `solid 0.5px ${theme.palette.primary.disabled}`,
    marginTop: 15,
    "& .location-input": {
      paddingRight: 15,
      paddingLeft: 15,
    },
  },
  HotelSection_quicklinks: {
    padding: "10px 0",
    marginTop: 10,
    display: "flex",
    justifyContent: "flex-start",
    [theme.breakpoints.down(600)]: {
      flexWrap: "wrap",
      justifyContent: "center",
    },
    "& .quick-link-item": {
      margin: 5,
      // boxShadow: "4px 4px 4px #c2d87e",
      padding: "3px 10px",
      color: theme.palette.primary.defaultText,
      borderRadius: 4,
      width: 90,
      cursor: "pointer",
      border: `solid 1px ${theme.palette.secondary.defaultText}`,
      "&:hover": {
        backgroundColor: theme.palette.secondary.darkOrange,
        color: theme.palette.primary.lightText,
        border: `solid 1px ${theme.palette.secondary.darkOrange}`,
      },
      "& .qlink-icon-wrapper": {
        textAlign: "center",
        "& i": {
          fontSize: 15,
        },
      },
      "& .qlink-label-wrapper p": {
        fontSize: 10,
      },
    },
  },
  FlightSectionFormContainer: {},

  FlightSectionContainer: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 10,
    padding: 10,
    border: "1px solid #b1b1b1",
  },
  RoomModalOpenDev: {
    position: "absolute",
    left: "10%",
    width: "303px",
    height: "100px",
    zIndex: 999,
  },
}));
