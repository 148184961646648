import ApiUtils from "./ApiUtils";
import axios from "axios";
import { localforageGetItem } from "oautils/oaForageUtils";

import React from "react";

//export const BASE_URL = "http://localhost:8084/https://www.geebaku.com/travlogy_ws/api/v1/";
//export const BASE_URL_UPLOAD = "http://localhost:8084/https://www.geebaku.com/travlogy_ws/ap/v1/";

let server = "";

// server = "https://www.ap.tours/";
// server = "https://www.triumphhtravel.com/";
//server = "https://www.lookmyticket.com/";
// server = "https://www.easytravelsonline.com/";
server = "https://www.tripbouquet.com/";
// server = "https://www.afatour.in/";
// server = "http://www.buzzholidayz.com/";
// server = "https://www.mydonetrip.com/";
// server = "https://www.tickat.com/";
// server = "http://oari.co:8080/";
// server = "http://localhost:8084/http://oari.co:8080/";
// server = "http://localhost:8080/";
// server = "http://localhost:8084/http://localhost:8080/";
// server = "http://localhost:8084/https://www.mydonetrip.com/";
// server = "http://localhost:8084/https://www.triumphhtravel.com/";
//server = "http://localhost:8084/https://www.tickat.com/";

export const basename = "";

export const SERVER_URL = server;
export const BASE_URL = server + "travlogy_ws/api/v1/";
export const BASE_URL_UPLOAD = server + "travlogy_ws/api/v1/";
export const BASE_URL_v2 = server + "travlogy_ws/api/v2/";
export const BASE_URL_IMAGE_VIEW = server + "travlogy_ws/api/v1/";
// export const BASE_URL_IMAGE_VIEW =
//   "http://oari.co:8080/" + "travlogy_ws/api/v1/";

export const POST_LOGIN = BASE_URL + "authenticate/agent";
export const POST_DSA_LOGIN = BASE_URL + "authenticate/distributor";
export const POST_ADMIN_LOGIN = BASE_URL + "authenticate/admin";
export const POST_LOGIN_B2C = BASE_URL + "authenticate/user";
export const POST_RESENDPASSWORD_B2C = BASE_URL + "b2c/resendpassword";
// export const DMT_BANK_BRANCH_DETAILS = 'https://ifsc.razorpay.com';
export const EXT_REDIRECT_SERVER = server;
// export const EXT_REDIRECT_SERVER = server + "tickat/";
//export const EXT_REDIRECT_SERVER = "http://localhost:3000/tickat/";

export const allAirlines =
  "6E,SG,G9,IX,AK,TR,FZ,TZ,G8,WE,9W,AI,LB,I5,UK,OP,2T,VA,ZO,S9,9I,QP,S5";
export const gdsAirlines = "9W,AI,UK";
export const lccAirlines =
  "6E,SG,G9,IX,AK,TR,FZ,TZ,G8,WE,LB,I5,OP,2T,VA,ZO,S9,9I,QP,S5";

/* REMOTE DEV */
const BASIC_ERROR = BASE_URL + "error";
const REFLECT = BASE_URL + "";
export const SEARCH_AGENTS = BASE_URL + "agency/detail/list/";
export const GET_LEDGER = BASE_URL + "agency/ledger/";
export const GET_AGENTS = BASE_URL + "agency/list/";
export const GET_FLIGHT_REPORT = BASE_URL + "agency/flight/list/";
export const GET_PARENT_BANK_LIST = BASE_URL + "agency/receiver-bank/list";

export const GET_OWN_BANK_LIST = BASE_URL + "agency/own-bank/list";
export const ADD_OWN_BANK = BASE_URL + "agency/own-bank/do-add";
export const UPDATE_OWN_BANK = BASE_URL + "agency/own-bank/do-update";

export const RECEIVE_BANK = BASE_URL + "agency/receiver-bank/list";

export const ADD_BALANCE_TRANSFER_REQUEST =
  BASE_URL + "agency/deposit-request/do-add";
export const DO_FILE_UPLOAD = BASE_URL + "agency/upload-file";
export const GET_FILE_UPLOAD = BASE_URL_UPLOAD + "agency/view-file";
export const ADD_B2B_BALANCE_TRANSFER_REQUEST =
  BASE_URL + "agency/balance-transfer/do-add";
export const GET_MASTER_RECEIVED_REQUEST_LIST =
  BASE_URL + "agency/deposit-request/received-request-list";
export const GET_MASTER_SENT_REQUEST_LIST =
  BASE_URL + "agency/deposit-request/sent-request-list";
export const GET_FLIGHT_BOOKING_STATUS_LIST =
  BASE_URL + "agency/booking/status";
export const DOWNLOAD_AGENT_PDF = BASE_URL + "agency/detail/list/download=PDF";
export const DOWNLOAD_AGENT_XLS = BASE_URL + "agency/detail/list/download=XLS";
export const DOWNLOAD_LEDGER_PDF = BASE_URL + "agency/ledger/download=PDF";
export const DOWNLOAD_LEDGER_XLS = BASE_URL + "agency/ledger/download=XLS";
export const PUT_AGENCY_PASSWORD = BASE_URL + "agency/password/do-update";
export const DO_RESEND_PASSWORD = BASE_URL + "agency/resend/password";

export const GET_TICKET_DATA = BASE_URL + "agency/getFlightDetails";
export const GET_FLIGHT_MARKUP_LIST = BASE_URL + "agency/markup/list";
// export const UPDATE_FLIGHT_MARKUP = BASE_URL + "agency/markup/do-update";
export const UPDATE_FLIGHT_MARKUP =
  BASE_URL + "agency/markup/do-update-with-faretype";
export const GET_BANK_BY_IFSC = BASE_URL + "agency/search/ifsc-code?ifsc=";

export const REGISTER_DIRECT_AGENT = BASE_URL + "agency/registerAgent";
export const REGISTER_ENQUIRY = BASE_URL + "agency/partner/enquiry";
export const REGISTER_CORPORATE_AGENT =
  BASE_URL + "agency/registerCorporateAgent";

export const UPDATE_DEPOSIT_REQUEST =
  BASE_URL + "agency/deposit-request/do-update";
export const GET_STATE_CITY = BASE_URL + "agency/stateCity/list";

export const GET_GST_DETAILS = BASE_URL + "agency/gst/do-get";
export const UPDATE_GST_DETAILS = BASE_URL + "agency/gst/do-update";
export const GET_DASHBOARD_DATA =
  BASE_URL + "agency/dashboard/get-dashboard-data";

export const GET_DASHBOARD_CALENDAR_DATA =
  BASE_URL + "agency/dashboard/get-dashboard-calendar-data";

export const DOWNLOAD_FLIGHT_PDF = BASE_URL + "agency/flight/download=PDF";
export const DOWNLOAD_FLIGHT_XLS = BASE_URL + "agency/flight/download=XLS";

export const DOWNLOAD_DMT_PDF = BASE_URL + "agency/dmt/download=PDF";
export const DOWNLOAD_DMT_XLS = BASE_URL + "agency/dmt/download=XLS";

export const GET_AGENT_DISCOUNT = BASE_URL + "agency/discount/list";
export const UPDATE_AGENT_DISCOUNT = BASE_URL + "agency/discount/do-update";
export const EMAIL_ETICKET = BASE_URL + "agency/email-inline";

export const VOUCHER_FLIGHT = BASE_URL + "agency/invoice/agent-invoice";
export const GET_DISTRIBUTOR_DETAILS = BASE_URL + "agency/details";
export const UPDATE_DISTRIBUTOR_DETAILS = BASE_URL + "agency/details/do-update";

export const GET_AGENT_DETAILS = BASE_URL + "agency/agent-details";
export const UPDATE_AGENT_DETAILS = BASE_URL + "agency/agent-details/do-update";

export const GET_FLIGHT_LEAD_LIST =
  BASE_URL + "company/flights-offline/lead-list";

export const GET_RAZORPAY_ORDER =
  BASE_URL + "agency/payment-gateway/razorpay/order-transact";
//razorpay/order

export const CREATE_WALLET_TRANSACTION =
  BASE_URL + "agency/wallet-topup/create-txn";

export const GET_RAZORPAY_ORDER_TRANSACT =
  BASE_URL + "agency/payment-gateway/razorpay/order-transact";

export const GET_RAZORPAY_SUCCESS =
  BASE_URL + "agency/payment-gateway/razorpay/success";

export const GET_RAZORPAY_SUCCESS_TRANSACT =
  BASE_URL + "agency/payment-gateway/razorpay/success-transact";

export const GET_RAZORPAY_FAILED =
  BASE_URL + "agency/payment-gateway/razorpay/failed";

export const GET_AGENT_EXTENDED_DETAILS =
  BASE_URL + "agency/agent-extended-details";

export const GET_AGENT_INFO = BASE_URL + "authenticate/agent/detail";
export const GET_AIRPORT_LIST = BASE_URL + "agency/flight/airport-and-carrier";
export const FETCH_AIRPORT_LIST = BASE_URL + "agency/flight/airport-list";

export const GET_COMMISSION_TYPE_LIST =
  BASE_URL + "agency/commission-type/list";

export const DMT_FETCH_SENDER = BASE_URL_v2 + "module/dmt/query-remitter";
export const DMT_ADD_SENDER = BASE_URL_v2 + "module/dmt/register-remitter";

export const DMT_FETCH_BENEFICIARY =
  BASE_URL_v2 + "module/dmt/fetch-beneficiary";

export const DMT_FETCH_BENEFICIARY_DETAILS =
  BASE_URL_v2 + "module/dmt/fetch-beneficiary-details";
export const DMT_ADD_BENEFICIARY =
  BASE_URL_v2 + "module/dmt/register-beneficiary";
export const DMT_DELETE_BENEFICIARY =
  BASE_URL_v2 + "module/dmt/delete-beneficiary";

export const DMT_SEND_OTP = BASE_URL_v2 + "module/dmt/resend-otp";
export const DMT_SEND_REFUND_OTP = BASE_URL_v2 + "module/dmt/refund/resend-otp";

export const DMT_BANK_LIST = BASE_URL_v2 + "module/dmt/bank-list";
export const DMT_ACCOUNT_VERIFY =
  BASE_URL_v2 + "module/dmt/account-verification";
export const DMT_TRANSACTION = BASE_URL_v2 + "module/dmt/do-transact"; // transact';
export const DMT_FETCH_TRANSACTION_LIST =
  BASE_URL_v2 + "module/dmt/fetch-transaction-list";
export const DMT_REFUND_TRANSACTION = BASE_URL_v2 + "module/dmt/refund";
export const DMT_QUERY_TRANSACTION = BASE_URL_v2 + "module/dmt/query-transact";
export const DMT_QUERY_TRANSACTION_SERIAL =
  BASE_URL_v2 + "module/dmt/query-transact-serial";

export const VOUCHER_DMT = BASE_URL + "agency/invoice/dmt-invoice";
export const GET_DMT_REPORT = BASE_URL + "agency/dmt/list/";

export const UPDATE_FLIGHT_TICKET_MARKUP =
  BASE_URL_v2 + "module/flight/airfare/markup/update";

export const RECHARGE_BALANCE = BASE_URL_v2 + "module/recharge/balance";
export const RECHARGE_TYPE = BASE_URL_v2 + "module/recharge/type";
export const RECHARGE_COMMISSION_CHARGE =
  BASE_URL_v2 + "module/recharge/commission-charge";
export const RECHARGE_TRANSACT = BASE_URL_v2 + "module/recharge/transact";
export const RECHARGE_STATUS_TRANSACT =
  BASE_URL_v2 + "module/recharge/status-transact";
export const RECHARGE_TRANSACTION_LIST =
  BASE_URL_v2 + "module/recharge/fetch-transaction-list";

export const GET_SUPPLIER = BASE_URL_v2 + "company/supplier";
export const GET_DISTRIBUTOR_TO_AGENT =
  BASE_URL_v2 + "company/get-agent-charge";
export const UPDATE_DISTRIBUTOR_TO_AGENT =
  BASE_URL_v2 + "company/update-agent-charge";

export const RECHARGE_REPORT = BASE_URL + "agency/recharge/list";
export const VOUCHER_RECHARGE = BASE_URL + "agency/invoice/recharge-invoice";
export const DOWNLOAD_RECHARGE_PDF = BASE_URL + "agency/recharge/download=PDF";
export const DOWNLOAD_RECHARGE_XLS = BASE_URL + "agency/recharge/download=XLS";

export const GET_BUS_CITY = BASE_URL_v2 + "module/bus/city-list";
export const FETCH_MATCHING_BUS_CITY = BASE_URL_v2 + "module/bus/city-lists";
export const GET_BUS_SEARCH_RESULT = BASE_URL_v2 + "module/bus/search";
export const GET_BUS_SEAT_LAYOUT = BASE_URL_v2 + "module/bus/seat-layout";
export const GET_BUS_BOARDING_POINT_DETAILS =
  BASE_URL_v2 + "module/bus/boarding-point-details";
export const GET_BUS_SEAT_BLOCK = BASE_URL_v2 + "module/bus/block";
export const GET_BUS_SEAT_BOOK = BASE_URL_v2 + "module/bus/book";
export const GET_BUS_TRANSACTION = BASE_URL_v2 + "module/bus/createBusTxn";

export const GET_BUS_SEAT_BOOKING_DETAILS =
  BASE_URL_v2 + "module/bus/booking-details";

export const GET_BUS_SEAT_CANCEL = BASE_URL_v2 + "module/bus/cancel";
export const GET_INSURANCE_CANCEL = BASE_URL_v2 + "module/insurance/cancel";
export const UPDATE_BUS_TICKET_MARKUP =
  BASE_URL_v2 + "module/bus/markup/update";

export const GET_BUS_TICKET_ROLLBACK = BASE_URL_v2 + "module/bus/rollback";

export const BUS_REPORT = BASE_URL + "agency/bus/list";
export const VOUCHER_BUS = BASE_URL + "agency/invoice/bus-invoice";
export const VOUCHER_INSURANCE = BASE_URL + "agency/invoice/insurance-invoice";
export const DOWNLOAD_BUS_PDF = BASE_URL + "agency/bus/download=PDF";
export const DOWNLOAD_BIS_XLS = BASE_URL + "agency/bus/download=XLS";

export const IRCTCPG_REPORT = BASE_URL + "agency/irctc-pg/list";
export const DOWNLOAD_IRCTCPG_PDF =
  BASE_URL + "agency/irctc-pg/list/download=PDF";
export const DOWNLOAD_IRCTCPG_XLS =
  BASE_URL + "agency/irctc-pg/list/download=XLS";

export const GET_FLIGHT_TRANSACTION = BASE_URL_v2 + "module/flight/booking"; //to be filled
export const GET_FLIGHT_SAVEDPAX =
  BASE_URL_v2 + "module/flight/saved-travelers"; //to be filled
export const GET_FLIGHT_GSTNO = BASE_URL_v2 + "module/flight/saved-gst"; //to be filled
// module/flight/saved-travelers

export const GET_HOTEL_COUNTRY = BASE_URL_v2 + "module/hotel/CountryList";
export const GET_HOTEL_CITY_LIST_COUNTRY =
  BASE_URL_v2 + "module/hotel/HotelCityListForCountry";

export const GET_HOTEL_CITY =
  BASE_URL_v2 + "module/hotel/DestinationSearchStaticData";
export const GET_MATCHING_HOTEL_CITY =
  BASE_URL_v2 + "module/hotel/HotelCityListForCountry";
export const HOTEL_REPORT = BASE_URL + "agency/hotel/list";
export const GET_HOTEL_SEARCH = BASE_URL_v2 + "module/hotel/HotelSearch";
export const GET_HOTEL_INFO = BASE_URL_v2 + "module/hotel/GetHotelInfo";
export const GET_HOTEL_ROOM = BASE_URL_v2 + "module/hotel/HotelRooms";
export const GET_HOTEL_BLOCK = BASE_URL_v2 + "module/hotel/BlockRoom";
export const GET_HOTEL_BOOK = BASE_URL_v2 + "module/hotel/HotelBook";
export const GET_HOTEL_TRANSACTION =
  BASE_URL_v2 + "module/hotel/CreateHotelTxn";

export const GET_HOTEL_BOOK_DETAILS =
  BASE_URL_v2 + "module/hotel/GetBookingDetail";
export const GET_HOTEL_STATIC_DATA =
  BASE_URL_v2 + "module/hotel/GetHotelStaticData";

export const GET_B2C_HOTEL_LIST = BASE_URL + "b2c/hotel/list";
export const GET_B2C_BUS_LIST = BASE_URL + "b2c/bus/list";
export const GET_CUSTOMER_QUERY = BASE_URL + "b2c/customerquery";

export const GET_B2C_INSURANCE_LIST = BASE_URL + "b2c/insurance/list";

export const B2C_REGISTER = BASE_URL + "b2c/register-user";
export const GET_B2C_FLIGHT_LIST = BASE_URL + "b2c/flight/list";
export const B2C_FLIGHT_CANCELLATION =
  BASE_URL + "b2c/flight/cancellation-request";
export const B2B_FLIGHT_DETAIL_FOR_CANCELLATION =
  BASE_URL + "agency/flight/detail-for-cancellation";
export const B2B_FLIGHT_CANCELLATION =
  BASE_URL + "agency/flight/cancellation-request";
export const USER_DETAILS = BASE_URL + "b2c/user/details";
export const UPDATE_USER_DETAILS = BASE_URL + "b2c/user/update-user-details";
export const UPDATE_B2C_GST_DETAILS = BASE_URL + "b2c/user/update-gst-details";
//export const GET_PRINT_ETICKET = BASE_URL + "b2c/print-eticket";
export const GET_PRINT_ETICKET = BASE_URL + "b2c/print-etickets";

export const B2C_CONTACTUS = BASE_URL + "b2c/contactus";
export const B2C_ADDVISAENQUIRY = BASE_URL + "b2c/visa-enquiry";
export const B2C_ADDTOURENQUIRY = BASE_URL + "b2c/tour-enquiry";

export const GET_PACKAGE_CATEGORY =
  BASE_URL_v2 + "module/tour/get-package-categories";
export const GET_PACKAGE_CATEGORY_DETAILS =
  BASE_URL_v2 + "module/tour/get-package-category";
export const GET_PACKAGE_DETAILS =
  BASE_URL_v2 + "module/tour/get-packages-details";
export const SET_PACKAGE_REQUEST =
  BASE_URL_v2 + "module/tour/set-package-request";

export const GET_SPECIAL_OFFERS =
  BASE_URL_v2 + "module/fixed_departure/get-special-offers";

export const GET_AVAILABLE_SECTORS =
  BASE_URL_v2 + "module/fixed_departure/get-available-sectors";

export const GET_SELECTED_AVAILABLE_SECTORS =
  BASE_URL_v2 + "module/fixed_departure/get-selected-sector";

export const GET_FIXED_DEPARTURE_AIRPORT_LIST =
  BASE_URL_v2 + "module/fixed_departure/carriers";

export const GET_SPECIAL_OFFER_DETAILS =
  BASE_URL_v2 + "module/fixed_departure/get-special-offer-details";

export const GET_FLIGHT_DETAILS =
  BASE_URL_v2 + "module/fixed_departure/get-flight-details";

export const INSURANCE_REPORT = BASE_URL + "agency/insurance/list";

export const GET_POPULAR_ROUTES =
  BASE_URL_v2 + "popular-routes/get-popular-routes";

export const GET_FXD_DEP_FLIGHT_BOOK_TICKET =
  BASE_URL_v2 + "module/fixed_departure/book-ticket";

export const GET_FXD_DEP_AGENT_MARKUP =
  BASE_URL_v2 + "module/fixed_departure/get-agent-markup";
export const SET_FXD_DEP_AGENT_MARKUP =
  BASE_URL_v2 + "module/fixed_departure/update-agent-markup";

export const FIXED_DEPARTURE_FLIGHT_CANCELLATION =
  BASE_URL_v2 + "module/fixed_departure/cancellation-request";
export const VOUCHER_HOTEL = BASE_URL + "agency/invoice/hotel-invoice";

export const GET_INSURANCE_SEARCH_DETAILS =
  BASE_URL_v2 + "module/insurance/search";

export const CREATE_INSURANCE_TRANSACTION =
  BASE_URL_v2 + "module/insurance/createTransaction";

export const GET_INSURANCE_BOOKING_DETAILS =
  BASE_URL_v2 + "module/insurance/booking-details";
// export const GET_PACKAGE_CATEGORY =
//   BASE_URL_v2 + "module/tour/get-package-categories";
// export const GET_PACKAGE_CATEGORY_DETAILS =
//   BASE_URL_v2 + "module/tour/get-package-category";
// export const GET_PACKAGE_DETAILS =
//   BASE_URL_v2 + "module/tour/get-packages-details";
// export const SET_PACKAGE_REQUEST =
//   BASE_URL_v2 + "module/tour/set-package-request";
export const GET_FLIGHT_CALENDAR = BASE_URL_v2 + "module/flight/calendar";

export const GET_HOTEL_BOOKING_CANCEL =
  BASE_URL_v2 + "module/hotel/SendCancellationRequest";
export const GET_HOTEL_BOOKING_ROLLBACK = BASE_URL_v2 + "module/hotel/rollback";
export const UPDATE_HOTEL_TICKET_MARKUP =
  BASE_URL_v2 + "module/hotel/markup/update";
export const GET_COMAPANY_PAYAMENT_GATEWAY_OPTIONS =
  BASE_URL + "company/pg-options/list";

export const SERVICE_CONFIG = BASE_URL + "agency/service/config";
// */
export const SEND_SUCCESS_PNR_CANCELLATION_REQUEST =
  BASE_URL + "company/send-success-pnr-cancellation-request";

export const SEARCH_FLIGHT = BASE_URL_v2 + "module/flight/search";
export const GET_FARE_RULES = BASE_URL_v2 + "module/flight/farerule";
export const GET_FLIGHT_REPRICING = BASE_URL_v2 + "module/flight/repricing";
export const GET_DOB_VALIDATION =
  BASE_URL_v2 + "module/flight/traveler-dob-validation";
export const GET_AVAIL_PRICE_RESPONSE =
  BASE_URL_v2 + "module/flight/back-to-result-page";

export const GET_DEAL_SECTORS =
  BASE_URL_v2 + "module/fixed_departure/all-supplier-sector-list";

export const VALIDATE_AUTH = BASE_URL + "authenticate/validate-auth";

/**Payment gateway */
export const PROCESS_PAYMENT_PAYGOAL =
  "https://uat.paygoal.in/order/v1/payment";
export const POST_PG_ORDER = BASE_URL + "agency/payment-gateway/pg-order";
export const POST_PAYGOAL_API_CALLBACK =
  BASE_URL + "agency/payment-gateway/pg-response";
export const POST_PHONEPE_API_CALLBACK =
  BASE_URL + "agency/payment-gateway/phonepay-response";
export const GET_PHONEPE_PAYMENT_STATUS =
  BASE_URL + "agency/payment-gateway/phonepe-payment-status";
export const GET_B2C_FLIGHT_CONVENIENCE_FEES =
  BASE_URL + "company/b2c-conveyance-fees";

// export const POST_PAYGOAL_API_CALLBACK =
//   "http://oari.co:8080/travlogy_ws/api/v1/" +
//   "agency/payment-gateway/pg-response";
/** Coupons gateway **/

export const GET_COUPONS =
  BASE_URL_v2 +
  "module/discountVoucher/get-discount-vouchers"; /*Local DEV 
  BASE_URL_v2 + "module/discountVoucher/get-discount-vouchers";
export const VALIDATE_AUTH = BASE_URL + "authenticate/validate-auth";
/*Local DEV 
const BASIC_ERROR = "http://localhost/response/http-error.php?e=500";
const REFLECT = 'http://localhost/response/reflect.php';
export const SEARCH_AGENTS = "http://localhost/response/agency_list.json";
export const GET_LEDGER = "http://localhost/response/transactions.json";
export const GET_BANK_LIST = "http://localhost/response/bank_list.json";
export const GET_FLIGHT_REPORT = BASE_URL + "agency/flight/list/";
export const ADD_BALANCE_TRANSFER_REQUEST = BASE_URL + "agency/master-balance/do-add";

// */
// export const GET_PACKAGE_CATEGORY=BASE_URL_v2 + "module/tour/get-package-categories";
// export const GET_PACKAGE_CATEGORY_DETAILS=BASE_URL_v2 + "module/tour/get-package-category";
// export const GET_PACKAGE_DETAILS=BASE_URL_v2 + "module/tour/get-packages-details";
// export const SET_PACKAGE_REQUEST=BASE_URL_v2 + "module/tour/set-package-request";

// exclusive offer
export const GET_EXCLUSIVE_OFFER =
  BASE_URL_v2 + "exclusive-offers/get-exclusive-offers";

export const GET_DMT_CALLBACK =
  BASE_URL_v2 + "module/aadhaarpay/onboardagentcallback";
export const AADHAARPAY_BANK_LIST = BASE_URL_v2 + "module/aadhaarpay/bank-list";
export const AADHAARPAY_TRANSACT = BASE_URL_v2 + "module/aadhaarpay/transact";
export const AADHAARPAY_AGENT_MERCHANTCODE =
  BASE_URL_v2 + "module/aadhaarpay/merchantCode";

export const AADHAARPAY_AGENT_ONBOARDAGENT =
  BASE_URL_v2 + "module/aadhaarpay/onboardagent";

export const AADHAARPAY_AGENT_ONBOARDAGENT_CALLBACK =
  BASE_URL_v2 + "module/aadhaarpay/onboardagentcallback";
export const AADHAARPAY_STATUS = BASE_URL_v2 + "module/aadhaarpay/status";

export const AADHAARPAY_REPORT = BASE_URL + "agency/aadhaarpay/list";
export const VOUCHER_AADHAARPAY =
  BASE_URL + "agency/invoice/aadhaarpay-invoice";
export const AEPS_BALANCE_ENQUIRY = BASE_URL_v2 + "module/aeps/balance-enquiry";

export const NSDL_NEW_PAN_REQUEST =
  "https://preprod.assisted-service.egov-nsdl.com/SpringBootFormHandling/newPanReq";
export const NSDL_PAN_STATUS_REQUEST =
  "https://preprod.assisted-service.egov-nsdl.com/SpringBootFormHandling/PanStatusReq";
export const NSDL_PAN_CR_REQUEST =
  "https://preprod.assisted-service.egov-nsdl.com/SpringBootFormHandling/crPanReq";
export const NSDL_SIGN_REQUEST = BASE_URL + "agency/nsdl/sign-request";
export const NSDL_PROCESS_RESPONSE = BASE_URL + "agency/nsdl/process-response";
export const NSDL_PAN_REQUEST_SAVE_DRAFT = BASE_URL + "agency/nsdl/save-draft";
export const NSDL_PAN_CREATE_TRANSACTION =
  BASE_URL + "agency/nsdl/create-transaction";
export const NSDL_FETCH_APPLICATION_DRAFT =
  BASE_URL + "agency/nsdl/fetch-application-draft";
export const NSDL_GET_TRANSACTION_CHARGES =
  BASE_URL + "agency/nsdl/get-transaction-charges";
export const NSDL_GET_APPLICATION_TRANSACTION =
  BASE_URL + "agency/nsdl/get-transaction";
export const NSDL_GET_APPLICATION_REPORT =
  BASE_URL + "company/pan-application/list";
export const NSDL_GET_PAN_STATUS_REQUEST =
  BASE_URL + "agency/nsdl/get-pan-status-request";

export const DOWNLOAD_FLIGHT_TICKET_PDF =
  BASE_URL + "reporting/flight/ticket/export=pdf";
export const DOWNLOAD_BUS_TICKET_PDF =
  BASE_URL + "reporting/bus/ticket/export=pdf";
export const DOWNLOAD_HOTEL_VOUCHER_PDF =
  BASE_URL + "reporting/hotel/voucher/export=pdf";
export const DOWNLOAD_INSURANCE_VOUCHER_PDF =
  BASE_URL + "reporting/insurance/voucher/export=pdf";
export const GET_OD_TRANSACTION_HISTORY =
  BASE_URL_v2 + "module/credit-wallet/temporary-od/txn-history";
export const GET_OD_HISTORY =
  BASE_URL_v2 + "module/credit-wallet/temporary-od/history";
export const OD_LIST_GET =
  BASE_URL_v2 + "module/credit-wallet/temporary-od/list";
export const GET_PAYPAL_CLIENT_TOKEN =
  BASE_URL + "agency/payment-gateway/paypal/getClientToken";
export const PAYPAL_ORDER_CHECKOUT =
  BASE_URL + "agency/payment-gateway/paypal/checkout";
export const PAYPAL_WEB_CHECKOUT =
  BASE_URL + "agency/payment-gateway/paypal/web/checkout";
export const PAYU_CARD_VALIDATION =
  BASE_URL + "agency/payment-gateway/payu/validate-card";
export const PAYU_CARD_CHECKOUT =
  BASE_URL + "agency/payment-gateway/payu/checkout-card";
export const FLIGHT_WS_INVOICE_PDF_DOWNLOAD =
  BASE_URL + "reporting/invoice/flight/export=PDF";
export const HOTEL_WS_INVOICE_PDF_DOWNLOAD =
  BASE_URL + "reporting/invoice/hotel/export=PDF";
export const BUS_WS_INVOICE_PDF_DOWNLOAD =
  BASE_URL + "reporting/invoice/bus/export=PDF";
export const AADHAARPAY_WS_INVOICE_PDF_DOWNLOAD =
  BASE_URL + "reporting/invoice/aadhaarpay/export=PDF";
export const DMT_WS_INVOICE_PDF_DOWNLOAD =
  BASE_URL + "reporting/invoice/dmt/export=PDF";
export const INSURANCE_WS_INVOICE_PDF_DOWNLOAD =
  BASE_URL + "reporting/invoice/insurance/export=PDF";
export const RECHARGE_WS_INVOICE_PDF_DOWNLOAD =
  BASE_URL + "reporting/invoice/recharge/export=PDF";

export const VALIDATE_DISCOUNT_VOUCHER_USAGE =
  BASE_URL_v2 + "module/discountVoucher/validate-voucher-usage";
export const GET_DISCOUNT_VOUCHER_SEARCH =
  BASE_URL_v2 + "module/discountVoucher/get-discount-voucher-search";
export const FOREX_QUERY_REQUEST = BASE_URL_v2 + "module/forex/query-request";
export const CANCELLATION_LIST =
  BASE_URL + "company/flight/cancellation-request/list";
export const CANCELLATION_LIST_NON_PNR =
  BASE_URL + "company/flight/non-pnr-cancellation-request/list";
export const GET_FLIGHT_CANCELLATION_REMARKS =
  BASE_URL + "company/flight/cancellation-remarks";
export const GET_FLIGHT_TICKET_HTML =
  BASE_URL + "reporting/flight/ticket/html-export";
export const DOWNLOAD_FLIGHT_TICKET_HTML =
  BASE_URL + "reporting/flight/ticket/export=html";

export const HOTEL_CANCELLATION_LIST =
  BASE_URL + "company/hotel/cancellation-request/list";
export const BUS_CANCELLATION_LIST =
  BASE_URL + "company/bus/cancellation-request/list";

export const CHECK_AEPS_MERCHANT_ONBOARDING_STATUS =
  BASE_URL_v2 + "module/aeps/onboarding-status";
export const AEPS_TWO_FACTOR_AUTHENTICATION =
  BASE_URL_v2 + "module/aeps/two-factor-authentication";
export const AEPS_GET_MINI_STATEMENT =
  BASE_URL_v2 + "module/aeps/mini-statement";
export const AEPS_TWO_FACTOR_REGISTRATION_DETAILS =
  BASE_URL_v2 + "module/aeps/get-two-factor-registration-details";

const REQUEST_TYPE = {
  GET: "get",
  POST: "post",
  DELETE: "delete",
  PUT: "put",
};

export default class WebApi extends React.Component {
  constructor(props) {
    super(props);
  }

  static dmtBranchDetails(params, response, error) {
    axios
      .get(GET_BANK_BY_IFSC + params.ifsc, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        return response(res.data);
      })
      .catch((err) => {
        return error(error);
      });
  }
  static getInsuranceSearchDetails(params, response, error) {
    sendHttpRequest(
      GET_INSURANCE_SEARCH_DETAILS,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }
  static getCreateInsuranceTransaction(params, response, error) {
    sendHttpRequest(
      CREATE_INSURANCE_TRANSACTION,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static getInsuranceBookingDetails(params, response, error) {
    let methodName = GET_INSURANCE_BOOKING_DETAILS;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getVoucherDMT(params, response, error) {
    let methodName = VOUCHER_DMT;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getDmtReport(params, response, error) {
    let methodName = GET_DMT_REPORT;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static dmtRefundTransact(params, response, error) {
    sendHttpRequest(
      DMT_REFUND_TRANSACTION,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static dmtQueryTransact(params, response, error) {
    sendHttpRequest(
      DMT_QUERY_TRANSACTION,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static dmtQueryTransactSerial(params, response, error) {
    sendHttpRequest(
      DMT_QUERY_TRANSACTION_SERIAL,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static dmtTransactList(params, response, error) {
    sendHttpRequest(
      DMT_FETCH_TRANSACTION_LIST,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static dmtTransact(params, response, error) {
    sendHttpRequest(
      DMT_TRANSACTION,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static dmtAccountVerify(params, response, error) {
    sendHttpRequest(
      DMT_ACCOUNT_VERIFY,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static getFlightLeadList(params, response, error) {
    let methodName = GET_FLIGHT_LEAD_LIST;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static dmtBankList(params, response, error) {
    sendHttpRequest(DMT_BANK_LIST, params, response, error, REQUEST_TYPE.POST);
  }

  static sendDMTOtp(params, response, error) {
    sendHttpRequest(DMT_SEND_OTP, params, response, error, REQUEST_TYPE.POST);
  }

  static sendDMTRefundOtp(params, response, error) {
    sendHttpRequest(
      DMT_SEND_REFUND_OTP,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static getDMTSender(params, response, error) {
    sendHttpRequest(
      DMT_FETCH_SENDER,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static addDMTSender(params, response, error) {
    sendHttpRequest(DMT_ADD_SENDER, params, response, error, REQUEST_TYPE.POST);
  }

  static getDMTBeneficiary(params, response, error) {
    sendHttpRequest(
      DMT_FETCH_BENEFICIARY,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static addDMTBeneficiary(params, response, error) {
    sendHttpRequest(
      DMT_ADD_BENEFICIARY,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static deleteDMTBeneficiary(params, response, error) {
    sendHttpRequest(
      DMT_DELETE_BENEFICIARY,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static dmtTrnCallback(params, response, error) {
    sendHttpRequest(
      GET_DMT_CALLBACK,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static getAirports(params, response, error) {
    sendHttpRequest(
      GET_AIRPORT_LIST,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static fetchAirportList(params, response, error) {
    sendHttpRequest(
      FETCH_AIRPORT_LIST,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static getCommissionTypes(params, response, error) {
    sendHttpRequest(
      GET_COMMISSION_TYPE_LIST,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static getAgentInfo(params, response, error) {
    sendHttpRequest(
      GET_AGENT_INFO,
      params,
      response,
      error,
      REQUEST_TYPE.POST,
      true
    );
  }

  static getDistributorDetails(params, response, error) {
    sendHttpRequest(
      GET_DISTRIBUTOR_DETAILS,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static updateDistributorDetails(params, response, error) {
    sendHttpRequest(
      UPDATE_DISTRIBUTOR_DETAILS,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static getAgentDetails(params, response, error) {
    // console.log(params);
    sendHttpRequest(
      GET_AGENT_DETAILS,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static getAgentExtendedDetails(params, response, error) {
    sendHttpRequest(
      GET_AGENT_EXTENDED_DETAILS,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static updateAgentDetails(params, response, error) {
    // console.log(params);
    sendHttpRequest(
      UPDATE_AGENT_DETAILS,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static getAgentDiscount(params, response, error) {
    sendHttpRequest(
      GET_AGENT_DISCOUNT,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static updateAgentDiscount(params, response, error) {
    sendHttpRequest(
      UPDATE_AGENT_DISCOUNT,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static registerDirectAgent(params, response, error) {
    sendHttpRequest(
      REGISTER_DIRECT_AGENT,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static registerCorporateAgent(params, response, error) {
    sendHttpRequest(
      REGISTER_CORPORATE_AGENT,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static registerEnquiry(params, response, error) {
    sendHttpRequest(
      REGISTER_ENQUIRY,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static postLogin(params, response, error) {
    let methodName = POST_LOGIN;
    sendHttpRequest(
      methodName,
      params,
      response,
      error,
      REQUEST_TYPE.POST,
      true
    );
  }
  /**Coupon gateway */
  static getCoupons(params, response, error) {
    let methodName = GET_COUPONS;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static postDSALogin(params, response, error) {
    let methodName = POST_DSA_LOGIN;
    sendHttpRequest(
      methodName,
      params,
      response,
      error,
      REQUEST_TYPE.POST,
      true
    );
  }

  static postAdminLogin(params, response, error) {
    let methodName = POST_ADMIN_LOGIN;
    sendHttpRequest(
      methodName,
      params,
      response,
      error,
      REQUEST_TYPE.POST,
      true
    );
  }

  static postLoginB2C(params, response, error) {
    let methodName = POST_LOGIN_B2C;
    sendHttpRequest(
      methodName,
      params,
      response,
      error,
      REQUEST_TYPE.POST,
      true
    );
  }

  static resendPasswordB2C(params, response, error) {
    let methodName = POST_RESENDPASSWORD_B2C;
    sendHttpRequest(
      methodName,
      params,
      response,
      error,
      REQUEST_TYPE.POST,
      true
    );
  }

  static getLedgerDetails(params, response, error) {
    let methodName = GET_LEDGER;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getAgentListForDropdown(params, response, error) {
    let methodName = GET_AGENTS;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getAgentList(params, response, error) {
    let methodName = SEARCH_AGENTS;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getFlightReport(params, response, error) {
    let methodName = GET_FLIGHT_REPORT;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getBankList(params, response, error) {
    let methodName = GET_PARENT_BANK_LIST;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static addBalanceTransferRequest(params, response, error) {
    let methodName = ADD_BALANCE_TRANSFER_REQUEST;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static addB2bBalanceTransferRequest(params, response, error) {
    let methodName = ADD_B2B_BALANCE_TRANSFER_REQUEST;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static updateAgencyPassword(params, response, error) {
    let methodName = PUT_AGENCY_PASSWORD;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getMasterReceivedRequestList(params, response, error) {
    let methodName = GET_MASTER_RECEIVED_REQUEST_LIST;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getMasterSentRequestList(params, response, error) {
    let methodName = GET_MASTER_SENT_REQUEST_LIST;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getFlightBookingStatusList(params, response, error) {
    let methodName = GET_FLIGHT_BOOKING_STATUS_LIST;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getError(params, response, error) {
    let methodName = BASIC_ERROR;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static doReflect(params, response, error) {
    let methodName = REFLECT;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static doDownload(api, params, response, error) {
    sendHttpRequestBlob(api, params, response, error, REQUEST_TYPE.POST);
  }

  static registerAgent(params, response, error) {
    // let methodName = REGISTER_AGENT;
    // sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST)
  }

  static getTicketData(params, response, error) {
    let methodName = GET_TICKET_DATA;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getFlightMarkupList(params, response, error) {
    let methodName = GET_FLIGHT_MARKUP_LIST;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static doUpdateFlightMarkup(params, response, error) {
    let methodName = UPDATE_FLIGHT_MARKUP;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getBankList(params, response, error) {
    let methodName = GET_OWN_BANK_LIST;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getReceiveBankList(params, response, error) {
    let methodName = RECEIVE_BANK;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static addBank(params, response, error) {
    let methodName = ADD_OWN_BANK;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static updateBank(params, response, error) {
    let methodName = UPDATE_OWN_BANK;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static updateDepositStatus(params, response, error) {
    let methodName = UPDATE_DEPOSIT_REQUEST;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getStateCity(params, response, error) {
    let methodName = GET_STATE_CITY;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getGstDetails(params, response, error) {
    let methodName = GET_GST_DETAILS;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static updateGstDetails(params, response, error) {
    let methodName = UPDATE_GST_DETAILS;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getDashboardData(params, response, error) {
    let methodName = GET_DASHBOARD_DATA;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getDashboardCalendarData(params, response, error) {
    let methodName = GET_DASHBOARD_CALENDAR_DATA;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getVoucherFlight(params, response, error) {
    let methodName = VOUCHER_FLIGHT;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static emailETicket(params, response, error) {
    let methodName = EMAIL_ETICKET;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static doMarkupUpdate(params, response, error) {
    let methodName = UPDATE_FLIGHT_TICKET_MARKUP;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getRechargeBalance(params, response, error) {
    let methodName = RECHARGE_BALANCE;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getRechargeType(params, response, error) {
    let methodName = RECHARGE_TYPE;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getRechargeCommissionCharge(params, response, error) {
    let methodName = RECHARGE_COMMISSION_CHARGE;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static doRechargeTransact(params, response, error) {
    let methodName = RECHARGE_TRANSACT;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getRechargeTransactStatus(params, response, error) {
    let methodName = RECHARGE_STATUS_TRANSACT;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getRechargeTransactionLIst(params, response, error) {
    let methodName = RECHARGE_TRANSACTION_LIST;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getSupplier(params, response, error) {
    let methodName = GET_SUPPLIER;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getDistributorCommissionCharge(params, response, error) {
    let methodName = GET_DISTRIBUTOR_TO_AGENT;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static updateDistributorCommissionCharge(params, response, error) {
    let methodName = UPDATE_DISTRIBUTOR_TO_AGENT;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getRechargeReport(params, response, error) {
    let methodName = RECHARGE_REPORT;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }
  static getVoucherRecharge(params, response, error) {
    let methodName = VOUCHER_RECHARGE;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getBusCity(params, response, error) {
    let methodName = GET_BUS_CITY;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getMatchingBusCity(params, response, error) {
    let methodName = FETCH_MATCHING_BUS_CITY;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getBusSearchResult(params, response, error) {
    let methodName = GET_BUS_SEARCH_RESULT;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getBusSeatLayout(params, response, error) {
    let methodName = GET_BUS_SEAT_LAYOUT;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static doBusSeatBlock(params, response, error) {
    let methodName = GET_BUS_SEAT_BLOCK;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getBusCreateTransaction(params, response, error) {
    let methodName = GET_BUS_TRANSACTION;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static doBusSeatBook(params, response, error) {
    let methodName = GET_BUS_SEAT_BOOK;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getBusSeatBookingDetails(params, response, error) {
    let methodName = GET_BUS_SEAT_BOOKING_DETAILS;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getBusBoardingPointDetails(params, response, error) {
    let methodName = GET_BUS_BOARDING_POINT_DETAILS;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static doBusSeatCancel(params, response, error) {
    let methodName = GET_BUS_SEAT_CANCEL;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }
  static doInsuranceCancel(params, response, error) {
    let methodName = GET_INSURANCE_CANCEL;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getBusReport(params, response, error) {
    let methodName = BUS_REPORT;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getVoucherBus(params, response, error) {
    let methodName = VOUCHER_BUS;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }
  static getVoucherInsurance(params, response, error) {
    let methodName = VOUCHER_INSURANCE;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getVoucherHotel(params, response, error) {
    let methodName = VOUCHER_HOTEL;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static doBusMarkupUpdate(params, response, error) {
    let methodName = UPDATE_BUS_TICKET_MARKUP;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static doHotelMarkupUpdate(params, response, error) {
    let methodName = UPDATE_HOTEL_TICKET_MARKUP;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static doBusTicketRollback(params, response, error) {
    let methodName = GET_BUS_TICKET_ROLLBACK;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getIRCTCPGReport(params, response, error) {
    let methodName = IRCTCPG_REPORT;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static doFileUpload(params, responseCallBack, errorCallback) {
    let methodName = DO_FILE_UPLOAD;
    axios
      .post(methodName, params, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Content-Type":
            "multipart/form-data; charset=utf-8; boundary=" +
            Math.random()
              .toString()
              .substring(2),
        },
      })
      .then((res) => {
        return responseCallBack(res.data);
      })
      .catch((error) => {
        return errorCallback(error);
      });
  }

  static getHotelCountry(params, response, error) {
    let methodName = GET_HOTEL_COUNTRY;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }
  static getHotelCityListForCountry(params, response, error) {
    let methodName = GET_HOTEL_CITY_LIST_COUNTRY;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getHotelCity(params, response, error) {
    let methodName = GET_HOTEL_CITY;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getMatchingHotelCity(params, response, error) {
    let methodName = GET_MATCHING_HOTEL_CITY;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getHotelSearch(params, response, error) {
    let methodName = GET_HOTEL_SEARCH;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getInsuranceReport(params, response, error) {
    let methodName = INSURANCE_REPORT;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getHotelInfo(params, response, error) {
    let methodName = GET_HOTEL_INFO;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getHotelRoom(params, response, error) {
    let methodName = GET_HOTEL_ROOM;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getHotelBlock(params, response, error) {
    let methodName = GET_HOTEL_BLOCK;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getHotelBook(params, response, error) {
    let methodName = GET_HOTEL_BOOK;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getHotelCreateTransaction(params, response, error) {
    let methodName = GET_HOTEL_TRANSACTION;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getHotelBookDetails(params, response, error) {
    let methodName = GET_HOTEL_BOOK_DETAILS;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getHotelStaticData(params, response, error) {
    let methodName = GET_HOTEL_STATIC_DATA;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getHotelReport(params, response, error) {
    let methodName = HOTEL_REPORT;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getFlightCreateTransaction(params, response, error) {
    let methodName = GET_FLIGHT_TRANSACTION;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }
  static getFlightSavedPax(params, response, error) {
    let methodName = GET_FLIGHT_SAVEDPAX;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }
  static getFlightSavedGST(params, response, error) {
    let methodName = GET_FLIGHT_GSTNO;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getRazorPayOrder(params, response, error) {
    let methodName = GET_RAZORPAY_ORDER;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }
  static getWalletTopupTxn(params, response, error) {
    let methodName = CREATE_WALLET_TRANSACTION;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getRazorPayOrderTransact(params, response, error) {
    let methodName = GET_RAZORPAY_ORDER_TRANSACT;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getRazorPaySuccessTransact(params, response, error) {
    let methodName = GET_RAZORPAY_SUCCESS_TRANSACT;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getRazorPaySuccess(params, response, error) {
    let methodName = GET_RAZORPAY_SUCCESS;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getRazorPayFailed(params, response, error) {
    let methodName = GET_RAZORPAY_FAILED;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getSpecialOffers(params, response, error) {
    let methodName = GET_SPECIAL_OFFERS;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getAvailableSectors(params, response, error) {
    let methodName = GET_AVAILABLE_SECTORS;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getSelectedAvailableSectors(params, response, error) {
    let methodName = GET_SELECTED_AVAILABLE_SECTORS;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getSpecialOfferDetails(params, response, error) {
    let methodName = GET_SPECIAL_OFFER_DETAILS;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getFlightDetails(params, response, error) {
    let methodName = GET_FLIGHT_DETAILS;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getFxdDepBookTicket(params, response, error) {
    let methodName = GET_FXD_DEP_FLIGHT_BOOK_TICKET;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getFxdDepAgentMarkup(params, response, error) {
    let methodName = GET_FXD_DEP_AGENT_MARKUP;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static setFxdDepAgentMarkup(params, response, error) {
    let methodName = SET_FXD_DEP_AGENT_MARKUP;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static setFxdDepFlightCancellation(params, response, error) {
    let methodName = FIXED_DEPARTURE_FLIGHT_CANCELLATION;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getB2CFlightList(params, response, error) {
    let methodName = GET_B2C_FLIGHT_LIST;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }
  static getB2CHotelList(params, response, error) {
    let methodName = GET_B2C_HOTEL_LIST;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }
  static getB2CBusList(params, response, error) {
    let methodName = GET_B2C_BUS_LIST;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }
  static getB2CCustomerQuery(params, response, error) {
    let methodName = GET_CUSTOMER_QUERY;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getB2CInsuranceList(params, response, error) {
    let methodName = GET_B2C_INSURANCE_LIST;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getPrintEticket(params, response, error) {
    let methodName = GET_PRINT_ETICKET;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static addConcatus(params, response, error) {
    let methodName = B2C_CONTACTUS;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static addVisaEnquiry(params, response, error) {
    let methodName = B2C_ADDVISAENQUIRY;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static addTourEnquiry(params, response, error) {
    let methodName = B2C_ADDTOURENQUIRY;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static b2CFlightCancellation(params, response, error) {
    let methodName = B2C_FLIGHT_CANCELLATION;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static sendSuccessPNRCancellationRequest(params, response, error) {
    let methodName = SEND_SUCCESS_PNR_CANCELLATION_REQUEST;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static b2BFlightCancellation(params, response, error) {
    let methodName = B2B_FLIGHT_CANCELLATION;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static b2BFlightDetailForCancellation(params, response, error) {
    let methodName = B2B_FLIGHT_DETAIL_FOR_CANCELLATION;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static searchFlight(params, response, error) {
    let methodName = SEARCH_FLIGHT;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getFlightFareRules(params, response, error) {
    let methodName = GET_FARE_RULES;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getFlightRepricing(params, response, error) {
    let methodName = GET_FLIGHT_REPRICING;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }
  static getFlightDobValidation(params, response, error) {
    let methodName = GET_DOB_VALIDATION;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getAvailPriceResponse(params, response, error) {
    let methodName = GET_AVAIL_PRICE_RESPONSE;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getDealSectors(params, response, error) {
    let methodName = GET_DEAL_SECTORS;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getPackageCategory(params, response, error) {
    sendHttpRequest(
      GET_PACKAGE_CATEGORY,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }
  static getPackageCategoryDetails(params, response, error) {
    sendHttpRequest(
      GET_PACKAGE_CATEGORY_DETAILS,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static setPackageRequest(params, response, error) {
    sendHttpRequest(
      SET_PACKAGE_REQUEST,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static getPackageDetails(params, response, error) {
    sendHttpRequest(
      GET_PACKAGE_DETAILS,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static getCompanyPGOptions(params, response, error) {
    sendHttpRequest(
      GET_COMAPANY_PAYAMENT_GATEWAY_OPTIONS,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }
  // static setPackageRequest(params, response, error) {
  //   sendHttpRequest(
  //     SET_PACKAGE_REQUEST,
  //     params,
  //     response,
  //     error,
  //     REQUEST_TYPE.POST
  //   );
  // }
  //b2c user details
  static userDetails(params, response, error) {
    sendHttpRequest(USER_DETAILS, params, response, error, REQUEST_TYPE.POST);
  }

  static b2cRegister(params, response, error) {
    sendHttpRequest(B2C_REGISTER, params, response, error, REQUEST_TYPE.POST);
  }
  static updateUserDetails(params, response, error) {
    sendHttpRequest(
      UPDATE_USER_DETAILS,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }
  static updateb2cGstDetails(params, response, error) {
    sendHttpRequest(
      UPDATE_B2C_GST_DETAILS,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static getAadhaarpayReport(params, response, error) {
    let methodName = AADHAARPAY_REPORT;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getVoucherAadhaarpay(params, response, error) {
    let methodName = VOUCHER_AADHAARPAY;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getHotelBookingCancel(params, response, error) {
    sendHttpRequest(
      GET_HOTEL_BOOKING_CANCEL,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static getFlightCalendar(params, response, error) {
    let methodName = GET_FLIGHT_CALENDAR;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static doResendPassword(params, response, error) {
    let methodName = DO_RESEND_PASSWORD;
    sendHttpRequest(
      methodName,
      params,
      response,
      error,
      REQUEST_TYPE.POST,
      true
    );
  }

  static getServiceConfig(params, response, error) {
    let methodName = SERVICE_CONFIG;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static aadhaarPayBankList(params, response, error) {
    sendHttpRequest(
      AADHAARPAY_BANK_LIST,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static postPayGoalOrderTransact(params, response, error) {
    let methodName = POST_PG_ORDER;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getCoupons(params, response, error) {
    let methodName = GET_COUPONS;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getPopularRoutes(params, response, error) {
    let methodName = GET_POPULAR_ROUTES;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }
  static getExclusiveOffer(params, response, error) {
    let methodName = GET_EXCLUSIVE_OFFER;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getValidatedAuth(params, response, error) {
    sendHttpRequest(VALIDATE_AUTH, params, response, error, REQUEST_TYPE.POST);
  }

  static aadhaarPayTransact(params, response, error) {
    sendHttpRequest(
      AADHAARPAY_TRANSACT,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static aadhaarPayMerchantCode(params, response, error) {
    sendHttpRequest(
      AADHAARPAY_AGENT_MERCHANTCODE,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static aadhaarPayOnBoardAgent(params, response, error) {
    sendHttpRequest(
      AADHAARPAY_AGENT_ONBOARDAGENT,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static aadhaarPayStatus(params, response, error) {
    sendHttpRequest(
      AADHAARPAY_STATUS,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static aadhaarPayOnBoardAgentCallback(params, response, error) {
    sendHttpRequest(
      AADHAARPAY_AGENT_ONBOARDAGENT_CALLBACK,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static nsdlSignRequest(params, response, error) {
    sendHttpRequest(
      NSDL_SIGN_REQUEST,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static nsdlPanRequestSaveDraft(params, response, error) {
    sendHttpRequest(
      NSDL_PAN_REQUEST_SAVE_DRAFT,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static nsdlPanCreateTransaction(params, response, error) {
    sendHttpRequest(
      NSDL_PAN_CREATE_TRANSACTION,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static nsdlPanFetchApplication(params, response, error) {
    sendHttpRequest(
      NSDL_FETCH_APPLICATION_DRAFT,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static nsdlPanGetTransCharges(params, response, error) {
    sendHttpRequest(
      NSDL_GET_TRANSACTION_CHARGES,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static nsdlPanGetTransaction(params, response, error) {
    sendHttpRequest(
      NSDL_GET_APPLICATION_TRANSACTION,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static nsdlGetTransactionReport(params, response, error) {
    sendHttpRequest(
      NSDL_GET_APPLICATION_REPORT,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static nsdlGetPanStatusRequest(params, response, error) {
    sendHttpRequest(
      NSDL_GET_PAN_STATUS_REQUEST,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static aepsBalanceEnquiry(params, response, error) {
    sendHttpRequest(
      AEPS_BALANCE_ENQUIRY,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static odTxnHistoryGet(params, response, error) {
    sendHttpRequest(
      GET_OD_TRANSACTION_HISTORY,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static odHistoryGet(params, response, error) {
    sendHttpRequest(GET_OD_HISTORY, params, response, error, REQUEST_TYPE.POST);
  }

  static odListGet(params, response, error) {
    sendHttpRequest(OD_LIST_GET, params, response, error, REQUEST_TYPE.POST);
  }

  static getPaypalClientToken(params, response, error) {
    sendHttpRequest(
      GET_PAYPAL_CLIENT_TOKEN,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }
  static paypalOrderCheckout(params, response, error) {
    sendHttpRequest(
      PAYPAL_ORDER_CHECKOUT,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static paypalWebOrderCheckout(params, response, error) {
    sendHttpRequest(
      PAYPAL_WEB_CHECKOUT,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static payuCardValidation(params, response, error) {
    sendHttpRequest(
      PAYU_CARD_VALIDATION,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static payuCardCheckout(params, response, error) {
    sendHttpRequest(
      PAYU_CARD_CHECKOUT,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static validateDiscountVoucherUsage(params, response, error) {
    sendHttpFormDataRequest(
      VALIDATE_DISCOUNT_VOUCHER_USAGE,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static getDiscountVoucherSearch(params, response, error) {
    sendHttpRequest(
      GET_DISCOUNT_VOUCHER_SEARCH,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static getPhonepePaymentStatus(params, response, error) {
    sendHttpRequest(
      GET_PHONEPE_PAYMENT_STATUS,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static sendForexQueryRequest(params, response, error) {
    sendHttpRequest(
      FOREX_QUERY_REQUEST,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static getB2cFlightConvenienceFees(params, response, error) {
    sendHttpRequest(
      GET_B2C_FLIGHT_CONVENIENCE_FEES,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static aepsMerchantOnboardingStatus(params, response, error) {
    sendHttpRequest(
      CHECK_AEPS_MERCHANT_ONBOARDING_STATUS,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static aepsTwoFactorAuthentication(params, response, error) {
    sendHttpRequest(
      AEPS_TWO_FACTOR_AUTHENTICATION,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static getAepsMiniStatement(params, response, error) {
    sendHttpRequest(
      AEPS_GET_MINI_STATEMENT,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static getAepsTwoFactorRegistrationDetails(params, response, error) {
    sendHttpRequest(
      AEPS_TWO_FACTOR_REGISTRATION_DETAILS,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static getCancellationRequestList(params, response, error) {
    sendHttpRequest(
      CANCELLATION_LIST,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static getFlightCancellationRemarks(params, response, error) {
    let methodName = GET_FLIGHT_CANCELLATION_REMARKS;
    sendHttpRequest(methodName, params, response, error, REQUEST_TYPE.POST);
  }

  static getNonPNRCancellationRequestList(params, response, error) {
    sendHttpRequest(
      CANCELLATION_LIST_NON_PNR,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static getHotelCancellationRequestList(params, response, error) {
    sendHttpRequest(
      HOTEL_CANCELLATION_LIST,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static getBusCancellationRequestList(params, response, error) {
    sendHttpRequest(
      BUS_CANCELLATION_LIST,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }

  static getFlightTicketHtmlString(params, response, error) {
    sendHttpRequest(
      GET_FLIGHT_TICKET_HTML,
      params,
      response,
      error,
      REQUEST_TYPE.POST
    );
  }
}

async function sendHttpRequestBlob(
  methodName,
  params,
  responseCallBack,
  errorCallBack,
  request
) {
  axios.interceptors.response.use(
    (response) => {
      if (response.status === 401) {
        alert("You are not authorized");
      }
      return response;
    },
    (error) => {
      if (error.response && error.response.data) {
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error.message);
    }
  );

  localforageGetItem("user-id", function(err, value) {
    params.loggedInUserId = value; // @TODO: Move to a separate function

    localforageGetItem("access-key", function(err, accessKey) {
      if (request == REQUEST_TYPE.POST) {
        axios
          .post(methodName, params, {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: "Bearer " + accessKey,
            },
            responseType: "blob",
          })
          .then((res) => {
            return responseCallBack(res);
          })
          .catch((error) => {
            return errorCallBack(error);
          });
      }

      if (request == REQUEST_TYPE.GET) {
        axios
          .get(methodName + "?" + ApiUtils.objectToQueryString(params), {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: "Bearer " + accessKey,
            },
          })
          .then((res) => {
            return responseCallBack(res);
          })
          .catch((error) => {
            return errorCallBack(error);
          });
      }
    });
  });
}

async function sendHttpRequest(
  methodName,
  params,
  responseCallBack,
  errorCallBack,
  request,
  isNotAuthorization
) {
  axios.interceptors.response.use(
    (response) => {
      if (response.status == 401) {
        alert("You are not authorized");
      }
      return response;
    },
    (error) => {
      console.log("error");
      console.log(error);
      if (error.response && error.response.data) {
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error.message);
    }
  );

  localforageGetItem("user-id", function(err, value) {
    params.loggedInUserId = params.loggedInUserId
      ? params.loggedInUserId
      : value;

    localforageGetItem("access-key", function(err, accessKey) {
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      };

      if (!isNotAuthorization) {
        headers.Authorization = "Bearer " + accessKey;
      }

      if (request == REQUEST_TYPE.POST) {
        axios
          .post(methodName, params, {
            headers: headers,
          })
          .then((res) => {
            if (res && res.message != "Unauthorized") {
              return responseCallBack(res.data);
            } else {
              console.log(methodName + " error", res);
              // window.location.href = basename + "/logout";
            }
          })
          .catch((error) => {
            if (
              (error && typeof error == "string" && error != "Unauthorized") ||
              (typeof error == "object" && error.message != "Unauthorized")
            ) {
              return errorCallBack(error);
            } else {
              console.log(methodName + " error", error);
              // window.location.href = basename + "/logout";
            }
          });
      }

      if (request == REQUEST_TYPE.GET) {
        axios
          .get(methodName + "?" + ApiUtils.objectToQueryString(params), {
            headers: headers,
          })
          .then((res) => {
            if (res && res.message != "Unauthorized") {
              return responseCallBack(res);
            } else {
              console.log(methodName + " error", res);
              // window.location.href = basename + "/logout";
            }
          })
          .catch((error) => {
            if (
              (error && typeof error == "string" && error != "Unauthorized") ||
              (typeof error == "object" && error.message != "Unauthorized")
            ) {
              console.log(methodName + " error", error);
              // return errorCallBack(error);
              // window.location.href = basename + "/logout";
            } else {
              async function sendHttpRequest(
                methodName,
                params,
                responseCallBack,
                errorCallBack,
                request,
                isNotAuthorization
              ) {
                axios.interceptors.response.use(
                  (response) => {
                    if (response.status == 401) {
                      alert("You are not authorized");
                    }
                    return response;
                  },
                  (error) => {
                    console.log("error");
                    console.log(error);
                    if (error.response && error.response.data) {
                      return Promise.reject(error.response.data);
                    }
                    return Promise.reject(error.message);
                  }
                );

                localforageGetItem("user-id", function(err, value) {
                  params.loggedInUserId = params.loggedInUserId
                    ? params.loggedInUserId
                    : value;

                  localforageGetItem("access-key", function(err, accessKey) {
                    let headers = {
                      "Content-Type": "application/json",
                      "Access-Control-Allow-Origin": "*",
                    };

                    if (!isNotAuthorization) {
                      headers.Authorization = "Bearer " + accessKey;
                    }

                    if (request == REQUEST_TYPE.POST) {
                      axios
                        .post(methodName, params, {
                          headers: headers,
                        })
                        .then((res) => {
                          if (res && res.message != "Unauthorized") {
                            return responseCallBack(res.data);
                          } else {
                            console.log(methodName + " error", res);
                            // window.location.href = basename + "/logout";
                          }
                        })
                        .catch((error) => {
                          if (
                            (error &&
                              typeof error == "string" &&
                              error != "Unauthorized") ||
                            (typeof error == "object" &&
                              error.message != "Unauthorized")
                          ) {
                            return errorCallBack(error);
                          } else {
                            console.log(methodName + " error", error);
                            // window.location.href = basename + "/logout";
                          }
                        });
                    }

                    if (request == REQUEST_TYPE.GET) {
                      axios
                        .get(
                          methodName +
                            "?" +
                            ApiUtils.objectToQueryString(params),
                          {
                            headers: headers,
                          }
                        )
                        .then((res) => {
                          if (res && res.message != "Unauthorized") {
                            return responseCallBack(res);
                          } else {
                            console.log(methodName + " error", res);
                            // window.location.href = basename + "/logout";
                          }
                        })
                        .catch((error) => {
                          if (
                            (error &&
                              typeof error == "string" &&
                              error != "Unauthorized") ||
                            (typeof error == "object" &&
                              error.message != "Unauthorized")
                          ) {
                            return errorCallBack(error);
                          } else {
                            console.log(methodName + " error", error);
                            // window.location.href = basename + "/logout";
                          }
                        });
                    }
                  });
                });
              }
            }
          });
      }
    });
  });
}

async function sendHttpFormDataRequest(
  methodName,
  params,
  responseCallBack,
  errorCallBack,
  request,
  isNotAuthorization
) {
  axios.interceptors.response.use(
    (response) => {
      if (response.status === 401) {
        alert("You are not authorized");
      }
      return response;
    },
    (error) => {
      if (error && error.response && error.response.data) {
        return Promise.reject(error.response.data);
      }
      if (error?.message) return Promise.reject(error.message);
    }
  );

  localforageGetItem("user-id", function(err, value) {
    params.loggedInUserId = params.loggedInUserId
      ? params.loggedInUserId
      : value;

    const formData = new FormData();
    for (const [key, value] of Object.entries(params)) {
      formData.append(key, value);
    }

    localforageGetItem("access-key", function(err, accessKey) {
      let headers = {
        "Content-Type": "text/html; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      };

      if (!isNotAuthorization) {
        headers.Authorization = "Bearer " + accessKey;
      }

      if (request == REQUEST_TYPE.POST) {
        axios
          .post(methodName, formData, {
            headers: headers,
          })
          .then((res) => {
            // console.log(11);
            // console.log(res);

            if (res && res.message != "Unauthorized") {
              return responseCallBack(res.data);
            } else {
              // window.location.href = basename + "/logout";
            }
          })
          .catch((error) => {
            // console.log(111);
            // console.log(error);
            if (
              (error && typeof error == "string" && error != "Unauthorized") ||
              (typeof error == "object" && error.message != "Unauthorized")
            ) {
              return errorCallBack(error);
            } else {
              // window.location.href = basename + "/logout";
            }
          });
      }

      if (request == REQUEST_TYPE.GET) {
        axios
          .get(methodName + "?" + ApiUtils.objectToQueryString(params), {
            headers: headers,
          })
          .then((res) => {
            if (res.status === 500) {
              // window.location.href = basename + "/logout";
            } else {
              return responseCallBack(res);
            }
          })
          .catch((error) => {
            if (
              (error && typeof error == "string" && error != "Unauthorized") ||
              (typeof error == "object" && error.message != "Unauthorized")
            ) {
              return errorCallBack(error);
            } else {
              // window.location.href = basename + "/logout";
            }
          });
      }
    });
  });
}
