import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { localforageGetItem } from "oautils/oaForageUtils";
import { useSelector, useDispatch } from "react-redux";
import WebApi from "api/ApiConstants";
//import OffersSection from "./OffersSection";
import InsuranceSection from "./forms/InsuranceSection";
import { refreshUserSession } from "oautils/oaAuthUtils";
import OaLoading from "pages/components/OaLoading";
import SearchEngineBg from "assets/TripBouquet/images/home/flights-banner.png";
import SearchEngineBusBg from "assets/TripBouquet/images/home/bus-banner.png";
import SearchEngineHotelBg from "assets/TripBouquet/images/home/hotels-banner.png";
import { AppStateContext } from "layouts/AppStateProvider";
import { Switch, Route } from "react-router-dom";
import cx from "classnames";
import UncommonPlaces from "./forms/UncommonPlaces";
import TopDestinations from "./forms/TopDestinations";
import BestTourPackages from "./forms/BestTourPackages";
import RoadMap from "./forms/RoadMap";
import { Carousel } from "react-responsive-carousel";
import { BASE_URL_IMAGE_VIEW } from "api/ApiConstants";
import FlightSearch from "./search/FlightSearch";
import BusSearch from "./search/BusSearch";
import HotelSearch from "./search/HotelSearch";

const HomePage = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMounted = React.useRef(false);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [usertype, setUsertype] = React.useState(null);
  const { setUserTypeb2c } = React.useContext(AppStateContext);
  const [corporateType, setCorporateType] = React.useState(null);
  const [serviceOptions, setServiceOptions] = React.useState([]);
  const [showServices, setShowServices] = React.useState(
    props.hideServices ? props.hideServices : props.showServices
  );
  const [routesItem, setRoutesItem] = React.useState([]);
  const [offers, setOffers] = React.useState([]);
  const [packageCategory, setPackageCategory] = useState();
  const [LeadListData, SetLeadListData] = useState([]);

  const LoaderView = () => {
    return (
      <div className="loader-view">
        <OaLoading loadertext="..." />
        <div>
          <span className="loader-text"></span>
        </div>
      </div>
    );
  };

  const InsuranceSearch = () => {
    return (
      <div className="section-content">
        <h3>
          {" "}
          <span>{/* <HeaderServiceIcon src={InsuranceIcon} /> */}</span> Secure
          Your Journey & Travel Stress Free
        </h3>
        <div className="search__box">
          <InsuranceSection />
        </div>
      </div>
    );
  };

  const callbackRefreshToken = (flag) => {
    setIsLoading(flag);
  };

  const getUserDetails = (mountStatus) => {
    localforageGetItem("user-details", function (err, value) {
      if (mountStatus && value) {
        if (value.userTypeAbv == "S") {
          setIsLoggedIn(true);
        }

        setShowServices(
          value.userType == "distributor"
            ? false
            : showServices != null
              ? showServices
              : true
        );

        setServiceOptions(value.serviceOption);

        setUsertype(value.userType);
        setUserTypeb2c(value.userType);
        setCorporateType(value.corporateType);
        setIsLoading(false);
      } else {
        console.log("error getting user details", err);
      }
    });
  };

  React.useEffect(() => {
    isMounted.current = true;
    refreshUserSession(dispatch, callbackRefreshToken);
    return () => (isMounted.current = false);
  }, []);

  React.useEffect(() => {
    getUserDetails(isMounted.current);
    if (isMounted.current) {
      WebApi.getExclusiveOffer(
        {
          callFrom: "web",
          offerCategory: "",
        },
        (resp) => {
          if (resp != null && resp.success) {
            // console.log(resp.data, "get pop");
            setOffers(resp.data);
            // setDataIs(true);
          } else {
            console.log("error resp null", resp);
          }
        },
        (error) => console.log("error", error)
      );
    }
  }, [isMounted.current]);

  const width = window.innerWidth;
  const breakpoint = 980;
  const currentURL = window.location.href; // returns the absolute URL of a page
  const currentTab = currentURL.split("/");

  React.useEffect(() => {
    isMounted.current = true;
    let today = new Date();
    WebApi.getPackageCategory(
      { date: today },
      (response) => {
        if (response.data) {
          //console.log(response.data);
          setPackageCategory(response.data);

          // console.log(response.data)
        }
      },
      (error) => console.log("error fetching package category", error)
    );
    return () => (isMounted.current = false);
  }, []);

  let d = new Date();
  const date =
    ("0" + (d.getDate() + 0)).slice(-2) +
    "-" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "-" +
    d.getFullYear();

  useEffect(() => {
    WebApi.getFlightLeadList(
      {
        agentId: 0,
        dateFrom: date,
        dateTo: date,
        status: 1,
      },
      (resp) => {
        console.log("res", resp);
        SetLeadListData(resp.data);
      },
      (error) => console.log("error", error)
    );
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.topBanner}>
        <Carousel
          // breakPoints={breakPoints}
          // enableSwipe
          // enableMouseSwipe
          showIndicators={false}
          infiniteLoop
          showThumbs={false}
          enableAutoPlay
          width={"100%"}
          centerMode
          centerSlidePercentage={25}
        >
          {LeadListData &&
            LeadListData?.map((item) => (
              <div className={classes.carouselItem} key={item.id}>
                <img
                  src={
                    BASE_URL_IMAGE_VIEW +
                    `agency/view-file?fileName=${item.imagePath}&type=PROMO_IMAGES`
                  }
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    // justifyContent: "space-between",
                  }}
                >
                  <div className="bannerTitle">{item.title}</div>
                  <div className="bannerSubtitle">{item.description}</div>
                </div>
              </div>
            ))}
        </Carousel>
      </div>
      {/* <div style={{ minWidth: "500px" }}>
        <marquee scrolldelay="-10" speed="100" direction="left" style={{ display: "flex", alignItems: "center", fontSize: "20px", padding: 10 }}>This site is under development</marquee>
      </div> */}
      <Switch>
        <Route path="/flights">
          <FlightSearch {...props} breakpoint={breakpoint} width={width} />
        </Route>
        <Route path="/buses" >
          <BusSearch {...props} breakpoint={breakpoint} width={width} />
        </Route>
        <Route path="/hotels" component={HotelSearch} >
          <HotelSearch {...props} breakpoint={breakpoint} width={width} />
        </Route>
        {/* <Route path="/insurances" component={InsuranceSearch} /> */}
        <Route>
          <FlightSearch {...props} breakpoint={breakpoint} width={width} />
        </Route>
      </Switch>
      <UncommonPlaces data={packageCategory?.[3]} />
      <TopDestinations data={packageCategory?.[0]} />
      <BestTourPackages data={packageCategory?.[1]} />
      {/* <RoadMap /> */}
      {/* <OffersSection offers={offers} /> */}
      {/* <TopFlightRoutes routes={routesItem} /> */}
    </div>
  );
};

export default HomePage;

const HeaderServiceIcon = (props) => (
  <img
    className={cx("header-service-icon", props.lg ? "icon-lg" : "")}
    src={props.src}
  />
);

const useStyles = makeStyles((theme) => ({
  flightBg: {
    backgroundImage: `url(${SearchEngineBg})`,
  },
  busBg: {
    backgroundImage: `url(${SearchEngineBusBg})`,
  },
  hotelBg: {
    backgroundImage: `url(${SearchEngineHotelBg})`,
  },

  root: {
    padding: 0,
    margin: 0,
    backgroundColor: "#e9e9e9",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& .ad_banner": {
      height: 130,
      width: "100%",
      [theme.breakpoints.down(1350)]: {
        width: "90%",
      },
      padding: 10,
      // borderRadius: 5,
      "& img": {
        width: "100%",
        height: "100%",
        borderRadius: 5,
      },
    },
    "& .temp-container": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& img": {
        width: "100%",
        maxWidth: 1280,
      },
    },
    "& .search__section": {
      width: "100%",
      minHeight: "230px",
      position: "relative",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      paddingTop: 70,
      // backgroundColor: theme.palette.background.lightYellowGreen,
      // backgroundImage: `url(${SearchEngineBg})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      minHeight: 500,
      // backgroundHeight: "100% 100px",
      // [theme.breakpoints.down(980)]: {
      //     display: 'block',
      // },
      [theme.breakpoints.down(1450)]: {
        minHeight: 371,
        backgroundSize: "cover",
      },
      "& .section-content": {
        width: 1200,
        [theme.breakpoints.down(1220)]: {
          width: "98%",
        },
        // padding: "16px 0",
        "& .header-container": {
          display: "flex",
          justifyContent: "center",
        },
        "& .header-hotel-container": {
          display: "flex",
          justifyContent: "center",
        },
        "& .banner-text": {
          fontSize: 50,
          fontWeight: 700,
          color: "#004064",
          textAlign: "center",
          marginBottom: 55,
          fontFamily: "TimesNewRoman",
        },
        "& .bus-banner-text": {
          fontSize: 50,
          fontWeight: 700,
          color: "#004064",
          textAlign: "center",
          marginBottom: 55,
          fontFamily: "TimesNewRoman",
          // textAlign: "right",
          lineHeight: 1,
          // width: 600,
          margin: "0 32px",
        },
        "& .hotel-banner-text": {
          fontSize: 50,
          fontWeight: 700,
          color: "#ffffff",
          textAlign: "center",
          marginBottom: 55,
          fontFamily: "TimesNewRoman",
        },
        "& h3": {
          textAlign: "center",
          //color: "#fff",
          color: "#665757",

          fontWeight: 400,
          // marginTop: "2.3rem",
          marginBottom: 0,
          fontSize: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // color: theme.palette.primary.darkText,
          "& span": {
            display: "inline-block",
            margin: "0 5px 5px",
            "& .MuiSvgIcon-root": {
              fontSize: "30px",
            },
          },
        },
      },
    },
    "& .search__box": {
      // width: '1200px',
      minHeight: "130px",
      padding: "0px 0",
      [theme.breakpoints.down(980)]: {
        width: "100%",
      },
      [theme.breakpoints.down(1026)]: {
        width: "100%",
      },
    },

    "& .login__Div": {
      float: "right",
      position: "relative",
    },
    "& .loader-view": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "fixed",
      flexDirection: "column",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 103,
      background: "rgba(0,0,0,0.5)",
      "& img": {
        height: "7rem",
      },
      "& .loader-text": {
        color: theme.palette.primary.main,
        fontWeight: 600,
        textAlign: "center",
        marginLeft: 10,
      },
    },
    "& .header-service-icon": {
      height: 40,
      marginRight: 10,
      "&.lg": {
        height: 40,
      },
    },
  },

  carouselItem: {
    margin: "4px 0px",
    height: 65,
    display: "flex",
    justifyContent: "flex-start",
    margin: "0 5px",
    // borderRadius: "0.5rem 0.5rem 0 0",
    background: "#fff",
    borderRadius: 5,

    // width: "100%",
    // width: 300,
    "& img": {
      borderRadius: "0",
      marginBottom: 10,
      height: 65,
      marginRight: 20,
      // width: 300,
      [theme.breakpoints.down("sm")]: {
        // width: 270,
      },
    },
    "& p": {
      color: theme.palette.primary.defaultText,
      fontSize: 16,
      fontWeight: 500,
      margin: 5,
    },
    "& .bannerTitle": {
      fontSize: 16,
    },
    "& .bannerSubtitle": {
      fontSize: 14,
      fontWeight: 500,
      marginTop: 5,
    },
    "& .desc": {
      margin: "16px 0",
    },
  },
  topBanner: {
    width: "100%",
    padding: 5,
    borderRadius: 5,
    "& .carousel-root": {
      width: "100%",
    },
    "& img": {
      marginBottom: 10,
      height: 65,
      width: "30% !important",
      borderRadius: "5px 0px 0px 5px",
      [theme.breakpoints.down("sm")]: {
        // width: 270,
      },
    },
  },
}));
