import React from "react";
import { makeStyles, Button, useTheme, useMediaQuery } from "@material-ui/core";
import ContactBanner from "assets/triumphTravel/images/contact-banner.jpg";
import pic1 from "assets/triumphTravel/images/pic1.png";
import pic2 from "assets/triumphTravel/images/pic2.png";
import pic3 from "assets/triumphTravel/images/pic3.png";
import slideImage from "assets/triumphTravel/images/slideImage.png";
import { useHistory } from "react-router-dom";

const AboutUs = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();

  const goToUrlLandingpage = () => {
    history.push("/flights");
  };

  return (
    <div className={classes.root}>
      <div className="section-container">
        <div className="banner-section">
          <div className="section-title-container">
            <div className="section-title">
              <h2>About Us</h2>
            </div>
            <div className="section-bread-crumbs">
              <a onClick={goToUrlLandingpage}>Home</a> | <a href="">About Us</a>
            </div>
          </div>
        </div>
      </div>

      <div className="section-container grid-container justify-center">
        <div className="grid-container md-1280 padding-tb100">
          <div className="content-container ">
            {/* <div className="section-header">
              <h4>ABOUT US</h4>
            </div> */}
            <div className="section-caption">
              <p>
                YOU CAN NOT BUY HAPPINESS BUT CERTAINLY BUY HOLIDAY PACKAGE WITH
                HAPPINESS AT <strong style={{ color: "#DF3049" }}>TripBouquet</strong>
              </p>
            </div>
            <div className="content-text">
              <p>
                {/* <a onClick={goToUrlLandingpage}>Lookmyticket Tours and Travels (LMT) PVT LTD</a> is one of the leading travel websites that lets you find cheap flights, hotels, car rentals, and holiday packages. Our Customer support for an online business is managed by our contact center in Zirakpur, India. LookMyTicket works hard to attract new business and it’s to our team member’s credibility that we have managed to most of our customers. LookMyTicket offers you the newest in travel, along with the most affordable air tickets to India and the rest of the World. We continue to look for various values combined services to guarantee that you as our customer get the best travel option available in the country. */}
                Traveling to different places worldwide gives you a different
                perspective towards life. Trapped in our daily activities, we
                have forgotten to appreciate the wonder that nature is. Clumsy
                footsteps around the beaches, hiking in the mountains to have a
                chit-chat with clouds, tasting authentic cuisines, everything
                can be enjoyed while you choose to travel. A big part of
                traveling is also about finalizing the destination,
                accommodation, sightseeing activities, and whatnot. It can be
                tiresome and confusing. But All the travelers out there. We have
                something covered for you. Let us introduce you to Tripbouquet,
                your traveling assistance to wonderful places. It is a travel
                agency that allows tourists to have the best travel experience
                of their life. Travelling is an experience and we make it a
                memorable one.
              </p>
              <br />
              <p>
                We have a faithful and awarded group of Professionals who
                understand your travel needs and requirements and take pride in
                helping the customers. Our dedication towards understanding our
                customers requires has brought about numerous effective goes of
                customers both in the USA and India.
              </p>
            </div>
            {/* </div>
          <div className="content-container md-50">
            <div className="content-Images">
              <img src={pic1} />
              <img src={pic2} />
              <img src={pic3} />
            </div>
          </div>
        </div>
      </div>

      <div className="section-container grid-container justify-center">
        <div className="grid-container md-1280 padding-tb100">
          <div className="content-container"> */}
            <div className="section-caption">
              <p>Mission and Visions Of TripBouquet</p>
            </div>
            <div className="content-text">
              <p>
                TripBouquet as a company started with a vision of changing the
                customer service and making it more user-centric. For the
                founder, it is always about relentlessly changing the various
                dimensions of customer services and customizing it according to
                the needs of the travel enthusiasts. The company is blessed to
                have an IATA Certified Staff and is catering to travelers both
                in India and outside. Along with this, the promoters have been
                part of the travel industry for more than 15 years now and have
                been on the path of creating customized travel experiences for
                clients. At TripBouquet it is not always about planning trips
                and packages but building beautiful memoirs to be cherished for
                a lifetime. We cover the best places at the best time of the
                year and all the specialities of the area in our travel plans.
                Our packages come within the budget of a middle-class family and
                we provide festive offers as well. We started with an initiative
                to make trips memorable without any haste and promise our
                customers instant ticket bookings, a wide variety of offers, and
                customer service that serves you 24x7. We provide hassle-free
                ticket booking, hotel reservations, and a lot more services and
                ensure that there is no waste of time in your trip on any
                unproductive activity.
              </p>
              <br />
              <p>
                To carry general satisfaction to our travelers, satisfying their
                demands with our dedicated staff.
              </p>
            </div>
            {/* </div>
        </div>
      </div>

      <div className="section-container grid-container justify-center">
        <div className="grid-container md-1280 padding-tb100">
          <div className="content-container md-100"> */}
            <div className="section-caption">
              <p>Services offered</p>
            </div>
            <div className="content-text">
              <p>
                The services offered by TropBouquet are all you need on your
                trip. We offer the following services Itinerary Planning Flight
                ticket booking Hotel Booking The basic requirements you will
                need to consider in an entertainment and adventurous trip would
                be mainly the tickets, travel and accommodation. We can arrange
                all of that in our amazing packages depending on the routes.
                Itinerary planning will be based on your requirements, the total
                number of days, your budget, and so on. We have packages with
                the basic facilities for budget categories. So is the case with
                Flights and Hotel bookings. It is always customizable.
              </p>
              <ul>
                <li>
                  <p>Itinerary Planning</p>
                </li>
                <li>
                  <p>Flight ticket booking</p>
                </li>
                <li>
                  <p>Hotel Booking</p>
                </li>
              </ul>
            </div>
            {/* </div>
        </div>
      </div>
      <div className="section-container grid-container justify-center">
        <div className="grid-container md-1280 padding-tb100">
          <div className="content-container md-100"> */}
            <div className="section-caption">
              <p>Contact Us</p>
            </div>
            <div className="content-text">
              <p>
                Address: <br></br>
                241 Tower B Spazedge building, Sector 47<br></br>
                Gurugram <br></br>
                +91-9518221549, 0124-4240151 <br></br>
                You can mail us @ support@tripbouquet.com <br></br>
              </p>
            </div>
            {/* </div>
        </div>
      </div>
      <div className="section-container grid-container justify-center">
        <div className="grid-container md-1280 padding-tb100">
          <div className="content-container md-100"> */}
            <div className="section-caption">
              <p>New Bookings</p>
            </div>
            <div className="content-text">
              <p>
                You can book tickets or tour packages online from our website.
                If you haven’t subscribed yet, you can subscribe to us with your
                email address. This feature is available on the homepage. You
                can book online and pay for it via the secure payment options
                available online. If you do not have clarity on online booking,
                you can check with the customer care and they will guide you to
                make payments online or you can make the enquiry/booking on
                phone.
              </p>
            </div>
            {/* </div>
        </div>
      </div>
      <div className="section-container grid-container justify-center">
        <div className="grid-container md-1280 padding-tb100">
          <div className="content-container md-100"> */}
            <div className="section-caption">
              <p>Existing Bookings</p>
            </div>
            <div className="content-text">
              <p>
                Existing Bookings For cancellations/ Refund Status and
                E-tickets, you can check the manage my bookings option on the
                website. For knowing your bus stop, coach number for trains,
                etc. you can reach out to customer care and give your ticket
                number or PNR number and they will update you with the current
                status. For further queries regarding this, you can reach us at
                the contact numbers or mail us your concern.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: theme.palette.font.primary,
    "& .section-container": {
      margin: 0,
      padding: 0,
      width: "100%",
    },
    "& .grid-container": {
      display: "flex",
      [theme.breakpoints.down(600)]: {
        flexWrap: "wrap",
      },
    },
    "& .justify-center": {
      justifyContent: "center",
    },
    "& .padding-tb100": {
      padding: "25px 0",
    },
    "& .md-1280": {
      maxWidth: 1280,
      width: "100%",
    },

    "& .section-title-container": {
      padding: "150px 0 0 60px",
      [theme.breakpoints.down(600)]: {
        padding: "90px 0 0 60px",
      },
    },
    "& .section-title": {
      "& h2": {
        fontWeight: 700,
        color: theme.palette.primary.lightText,
        fontSize: 42,
        margin: 0,
      },
    },
    "& .section-bread-crumbs": {
      color: theme.palette.primary.lightText,
      "& a": {
        color: theme.palette.primary.lightText,
        cursor: "pointer",
      },
    },
    "& .banner-section": {
      padding: 20,
      height: 380,
      backgroundImage: `url(${ContactBanner})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "norepeat",
      [theme.breakpoints.down(600)]: {
        height: 250,
      },
    },
    "& .content-container": {
      "& button": {
        background: theme.palette.buttons.tertiary,
        color: theme.palette.buttons.tertiaryContrastText,
        fontSize: 14,
        padding: "10px 20px",
        textTransform: "lowercase",
        "& .MuiSvgIcon-root": {
          marginLeft: 4,
          fontSize: 16,
        },
      },
      "&.md-50": {
        width: "50%",
        [theme.breakpoints.down(960)]: {
          width: "100%",
        },
      },
      "&.md-100": {
        width: "100%",
      },
      [theme.breakpoints.down(1330)]: {
        padding: "0 20px",
      },
      [theme.breakpoints.down(960)]: {
        padding: "0 30px",
      },
      [theme.breakpoints.down(600)]: {
        textAlign: "center",
        marginBottom: 10,
      },
      [theme.breakpoints.down(390)]: {
        padding: "0 15px",
      },
    },

    "& .testimonial-container": {
      margin: "20px 0 0 0",
      "& .content-text-name": {
        fontSize: 18,
        fontWeight: 600,
        fontFamily: theme.palette.font.primary,
        margin: 0,
        lineHeight: 1.5,
      },
      "& .content-text-occupation": {
        fontSize: 12,
        fontWeight: 600,
        fontFamily: theme.palette.font.primary,
        margin: 0,
        lineHeight: 1.5,
      },
      "& .content-image": {
        margin: "0 15px 0 0",
      },
      "& .testimonial-text": {
        width: 342,
        margin: "20px 0 0",
        "& .content-text-caption": {
          fontSize: 14,
          fontWeight: 500,
          fontFamily: theme.palette.font.primary,
        },
      },
      [theme.breakpoints.down(390)]: {
        padding: "0 15px",
      },
    },
    "& .section-header": {
      "& p": {
        color: theme.palette.secondary.main,
        paddingTop: 20,
        size: 14,
        fontWeight: 700,
      },
    },
    "& .section-caption": {
      "& p": {
        fontSize: 22,
        fontWeight: 800,
        lineHeight: 1.2,
        fontFamily: theme.palette.font.primary,
        color: theme.palette.primary.darkText,
        [theme.breakpoints.down(1330)]: {
          fontSize: 32,
        },
      },
      "&.light-text": {
        color: theme.palette.primary.lightText,
        "& p": {
          color: theme.palette.primary.lightText,
          fontFamily: theme.palette.font.primary,
        },
      },
    },
    "& .content-text": {
      margin: "30px 0",
      "& p": {
        fontWeight: 500,
        fontSize: 15,
        lineHeight: 1.3,
        fontFamily: theme.palette.font.primary,
      },
      "& a": {
        cursor: "pointer",
        color: theme.palette.primary.info,
      },
    },
    "& .content-Images": {
      textAlign: "center",
      "& img": {
        margin: 10,
        [theme.breakpoints.down(960)]: {
          width: "100%",
        },
        [theme.breakpoints.down(390)]: {
          width: "100%",
        },
      },
    },
    "& .section-bg": {
      minHeight: 320,
      backgroundImage: `url(${slideImage})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "norepeat",
    },
    "& .inner-container": {
      maxWidth: 1280,
      width: "100%",
      "&.max-1440": {
        maxWidth: 1440,
      },
      "&.padding-tb100": {
        padding: "100px 0",
      },
    },
    "& .header-container": {
      padding: "0 0 0 70px",
      marginBottom: 30,
    },
    "& .section-carousel-wrapper": {
      width: "100%",
    },
    "& .rec-carousel": {
      [theme.breakpoints.down(600)]: {
        position: "relative",
      },
    },
    "& .arrow-crouselPackage": {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down(600)]: {
        position: "absolute",
        top: "35%",
      },
      "& img": {
        cursor: "pointer",
      },
      "&.left": {
        left: 0,
        zIndex: 1,
      },
      "&.right": {
        right: 0,
      },
    },
    "& .service-item": {
      background: theme.palette.background.light,
      width: "100%",
      textAlign: "center",
      padding: "30px 5px",
      color: theme.palette.primary.darkText,
    },
    "& .service-image": {
      width: "100%",
      margin: "0 0 20px",
      "& img": {
        width: 59,
        height: 59,
      },
    },
    "& .service-text-title": {
      fontSize: 16,
      fontWeight: 500,
      padding: "0 45px",
      fontFamily: theme.palette.font.primary,
    },
    "& .service-text": {
      color: theme.palette.primary.darkText,
      fontSize: 13.5,
      marginTop: 15,
    },
    "& .grid-column": {
      "&.grow-1": {
        flexGrow: 1,
      },
      "&.testimonial-grid": {
        padding: "60px 0 30px",
      },
      "&.image-column": {
        position: "relative",
      },
    },
    "& .content-navImage": {
      position: "absolute",
      bottom: -10,
      left: -60,
      "& a": {
        cursor: "pointer",
      },
    },
  },
}));
