import React from "react";
import {
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { AppConstant } from "appConstant";
import { useHistory } from "react-router-dom";

const TermsAndConditions = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const history = useHistory();

  const goToHomePage = () => {
    history.push("/");
  };

  return (
    <div
      className={classes.root}
      style={{
        marginBottom: 60,
        paddingLeft: isMobile ? 10 : "6%",
        paddingRight: isMobile && 10,
      }}
    >
      <Grid
        container
        spacing={isMobile ? 0 : 3}
        style={{ width: "100%" }}
        justifyContent="center"
      >
        {/* <Grid item xs={12} md={10} style={{ paddingTop: "50px" }}>
                    <a onClick={goToHomePage} className={classes.breadcrumbLink}>
                        <i className="fa fa-home"></i> Home
                    </a>
                </Grid> */}

        <Grid item xs={12} md={10}>
          <Grid
            container
            spacing={1}
            className={`${classes.firstCard} ${classes.Grid}`}
          >
            <Grid item md={12}>
              <Typography
                variant="h3"
                className={classes.sectionHeader}
                gutterBottom
              >
                TERMS & CONDITIONS:
              </Typography>
              <hr style={{ width: "100%" }} />
            </Grid>

            <Grid
              item
              md={12}
              className={classes.sectionTextGrid}
              // style={{ textAlign: "center" }}
            >
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Flight Booking
              </Typography>
              Flight Booking TripBouquet acts as a facilitator, the flight
              tickets booked are subject to the terms and conditions of the
              respective airlines. The airlines can reschedule the flight
              timings and route, the flights could also be cancelled without any
              update to TripBouquet.
            </Grid>
            <Grid
              item
              md={12}
              className={classes.sectionTextGrid}
              // style={{ textAlign: "center" }}
            >
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Pricing
              </Typography>
              The price displayed on the website is inclusive of the base fare,
              government taxes & convenience fee. Users have to pay the entire
              amount before booking. Flight ticket prices are dynamic in nature
              and cannot be guaranteed until paid and confirmed.
            </Grid>
            <Grid
              item
              md={12}
              className={classes.sectionTextGrid}
              // style={{ textAlign: "center" }}
            >
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Baggage
              </Typography>
              Baggage allowance is decided by the airlines and TripBouquet has
              no say in this regard.
            </Grid>
            <Grid
              item
              md={12}
              className={classes.sectionTextGrid}
              // style={{ textAlign: "center" }}
            >
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Travel Docs
              </Typography>
              It's the traveller's responsibility to carry all the essential
              documents required to travel. In any circumstances if a traveller
              is unable to produce the required documents and miss flight,
              TripBouquet will not be held responsible. Kindly confirm the check
              in policy with airlines directly to avoid any unnecessary hassle
              at the last minute. In case of any schedule change, TripBouquet
              will update the same via email/SMS 12 hours prior to the flight,
              post that it will be travellers responsibility to confirm with the
              airline. Usually check in time for domestic is 2hrs & 3 hrs for
              international before the scheduled departure of the flight.
            </Grid>
            <Grid
              item
              md={12}
              className={classes.sectionTextGrid}
              // style={{ textAlign: "center" }}
            >
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Refund
              </Typography>
              The refund will be processed by the airlines depending on the fare
              rules of the ticket. The refund made by the airline/Railway to
              TripBouquet will be returned to the customers original Mode of
              payment. FYI: Administration fee charged by the Airline/IRCTC &
              TripBouquet is non-refundable.
            </Grid>
            <Grid
              item
              md={12}
              className={classes.sectionTextGrid}
              // style={{ textAlign: "center" }}
            >
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Hotel
              </Typography>
              TripBouquet is a facilitator between the hotel authority and the
              customer. An online platform to select hotels, homestays or other
              accommodation options. <br />
              The pricing depends on the hotel selected. Usually, the price
              displayed is only for the stay. Some of the hotels cover breakfast
              and meals also within the regular booking. Any extra charges
              depending on the services, for example- laundry, room service,
              extra food, beverages, etc., utilised should be taken care of by
              the customer.
            </Grid>
            <Grid
              item
              md={12}
              className={classes.sectionTextGrid}
              // style={{ textAlign: "center" }}
            >
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Itinerary
              </Typography>
              TripBouquet will provide a proposed itinerary with the final
              price. The confirmed ticket and the vouchers will be sent to the
              user via email/SMS before the departure. <br />
              Any additional expenses occurring due to any changes that are not
              in the control of TripBouquet will be informed to the user.
              TripBouquet will not be responsible for any such changes.
              Amendments can be done before or during the trip as per the
              availability and with the applicable charges.
            </Grid>
            <Grid
              item
              md={12}
              className={classes.sectionTextGrid}
              // style={{ textAlign: "center" }}
            >
              <Typography
                variant="subtitle1"
                className={classes.sectionSubhead}
                gutterBottom
              >
                Corporate Travael
              </Typography>
              This option is available for Corporates, travel managers and
              employees. GST(Goods and services tax) invoice will be provided to
              claim the tax refund. We have special corporate rates with
              additional benefits like free seats etc. and reductions in the
              cancellation fee. <br></br>
              You can pay using the company wallet and avoid unnecessary
              paperwork to claim reimbursement. You can track all the approvals
              for the business travel bookings. You can shortlist the travel
              options available and share them with your employees and they can
              book conveniently. <br></br>
              You can always reach out to our customer care before booking or
              during the travel and even after the trip in case of any concern.
              Customer care is available 24x7.
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default TermsAndConditions;

const Gap10 = () => {
  return <div className="gap-10" style={{ margin: 10, width: "100%" }}></div>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& .highlight": {
      color: theme.palette.primary.darkOrange,
    },
  },
  Grid: {
    background: theme.palette.background.default,
    marginTop: "20px",
    boxShadow: "0px 53px 99px -81px rgba(0,0,0,0.75)",
    padding: 30,
    borderRadius: 20,
  },
  sectionHeader: {
    color: `${theme.palette.primary.darkFadedText} !important`,
    fontWeight: 500,
    margin: "5px 0 30px",
  },
  sectionSubhead: {
    color: `${theme.palette.primary.defaultText} !important`,
    fontWeight: 400,
  },
  sectionText: {
    color: `${theme.palette.primary.davyGray} !important`,
    fontWeight: 400,
    lineHeight: "27px",
  },
  sectionTextGrid: {
    margin: "15px 0",
    flexDirection: "column",
  },
  listText: {
    "& li": {
      color: theme.palette.primary.davyGray,
      fontWeight: 400,
      lineHeight: "27px",
    },
  },
  sectionTextGrid: {
    margin: "15px 0",
    flexDirection: "column",
  },
  listText: {
    "& li": {
      color: theme.palette.primary.darkFadedText,
      fontWeight: 400,
      lineHeight: "27px",
    },
  },
  breadcrumbLink: {
    color: `${theme.palette.primary.active} !important`,
    cursor: "pointer",
    "&:hover": {
      color: `${theme.palette.primary.pomegranate} !important`,
    },
  },
}));
